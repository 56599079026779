<template>
  <div class="list-download">
    <div
      v-if="items.length > 0"
      class="header"
    >
      <div class="checkbox" />
      <div class="status">
        ステータス
      </div>
      <div class="size">
        サイズ
      </div>
      <div class="format">
        フォーマット
      </div>
      <div class="user">
        リクエストユーザー
      </div>
      <div class="date">
        リクエスト日時
      </div>
      <div class="download-btn" />
    </div>
    <ul
      v-if="items.length > 0"
      ref="scrollable"
      class="list"
    >
      <ListItemDownload
        v-for="item of items"
        :id="item.download_id"
        :key="item.download_id"
        v-model:checkedIds="checkedIdsModelValue"
        :download-status-value="item.download_status_value"
        :download-status-display="item.download_status_display"
        :zip-file-size="item.zip_file_size"
        :file-format="item.file_format"
        :user-name="item.user_name"
        :request-datetime="item.request_datetime"
        :is-deletable="!item.is_deletable"
        :is-downloadable="!item.is_downloadable"
        @click="onDownload"
      />
    </ul>
    <p
      v-if="items.length === 0"
      class="not-found"
    >
      表示するデータがありません
    </p>
  </div>
</template>

<script>
import ListItemDownload from '@/components/download/ListItemDownload';

export default {
  components: {
    ListItemDownload,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    checkedIds: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['download', 'update:checkedIds'],
  computed: {
    checkedIdsModelValue: {
      get() {
        return this.checkedIds;
      },
      set(newValue) {
        this.$emit('update:checkedIds', newValue);
      },
    },
  },
  watch: {
    /**
     * propsのitemsが更新されたら実行される
     */
    items() {
      // 一覧のスクロールを最上部に戻す
      if (this.$refs.scrollable) {
        this.$refs.scrollable.scrollTop = 0;
      }
      // チェックボックスの初期化
      this.checkedIdsModelValue = [];
    },
  },
  methods: {
    onDownload(id) {
      this.$emit('download', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-download {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0 24px;
  > .header {
    display: flex;
    background-color: $white100;
    border-bottom: 2px solid $gray400;
    overflow-y: scroll;
    min-height: 31px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb{
      background: none;
    }

    > .status,
    .size,
    .format,
    .user,
    .date {
      color: $black400;
      font: $sans-none-12-bold;
      height: 29px;
      line-height: 29px;
    }

    > .checkbox {
      flex-basis: 4%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .status,
    .size,
    .format,
    .user,
    .date,
    .download-btn {
      flex-basis: 16%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  > .list {
    overflow-y: scroll;
    padding-bottom: 56px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      background-color: none;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: $gray600;
    }
  }

  > .not-found {
    margin: $spacing-sm $spacing-xs 0;
    font: $sans-normal-16;
    color: $black700;
  }

}
</style>
