<template>
  <div
    v-if="isShowModal"
    class="modal"
    @click.self="clickOutside"
  >
    <div
      class="container"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    canCloseByOutside: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    openModal() {
      this.isShowModal = true;
    },
    hideModal() {
      this.isShowModal = false;
      this.$emit('close');
    },
    clickOutside() {
      if (this.canCloseByOutside) {
        this.isShowModal = false;
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(85, 85, 85, 0.8);
  z-index: $z-modal;
  overflow-y: scroll;

  > .container {
    background-color: $white100;
    width: 384px;
    margin: $spacing-xxxlg auto;
    border: 1px solid $gray600;
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
    padding: $spacing-sm;
    border-radius: $radius8;

    > :slotted(.title) {
      font: $sans-none-20-bold;
      color: $black700;
      text-align: center;
      margin-bottom: $spacing-xs;
    }

    > :slotted(.message-bar) {
      margin-bottom: $spacing-xs;
    }

    > :slotted(.code) {
      color: $black700;
      font: $sans-none-28-bold;
      text-align: center;
      margin-top: $spacing-xs;
    }

    > :slotted(.text) {
      color: $black700;
      font: $sans-tight-16;
      overflow-wrap: break-word;
    }

    > :slotted(.button-block) {
      margin-top: $spacing-xs;
    }

    > :slotted(.form-block) {
      margin-top: $spacing-xs;
    }

    > :slotted(.icon-lineup) {
      justify-content: center;
      margin-bottom: $spacing-xs;
    }
  }
}
</style>
