<template>
  <IconBase :size="size">
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4444 8.77778V12.6667C13.4444 13.0944 13.0944 13.4444 12.6667 13.4444H3.33333C2.90556 13.4444 2.55556 13.0944 2.55556 12.6667V8.77778C2.55556 8.35 2.20556 8 1.77778 8C1.35 8 1 8.35 1 8.77778V13.4444C1 14.3 1.7 15 2.55556 15H13.4444C14.3 15 15 14.3 15 13.4444V8.77778C15 8.35 14.65 8 14.2222 8C13.7944 8 13.4444 8.35 13.4444 8.77778ZM8.77778 8.52111L10.24 7.05889C10.5433 6.75556 11.0333 6.75556 11.3367 7.05889C11.64 7.36222 11.64 7.85222 11.3367 8.15556L8.54444 10.9478C8.24111 11.2511 7.75111 11.2511 7.44778 10.9478L4.65556 8.15556C4.35222 7.85222 4.35222 7.36222 4.65556 7.05889C4.95889 6.75556 5.44889 6.75556 5.75222 7.05889L7.22222 8.52111V1.77778C7.22222 1.35 7.57222 1 8 1C8.42778 1 8.77778 1.35 8.77778 1.77778V8.52111Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: 16,
    },
  },
};
</script>
