<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0.75H3C2.45 0.75 2 1.2 2 1.75V8.75H3V1.75H9V0.75ZM10.5 2.75H5C4.45 2.75 4 3.2 4 3.75V10.75C4 11.3 4.45 11.75 5 11.75H10.5C11.05 11.75 11.5 11.3 11.5 10.75V3.75C11.5 3.2 11.05 2.75 10.5 2.75ZM10.5 10.75H5V3.75H10.5V10.75Z"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg{
  fill: $white100;
}
</style>