<template>
  <div class="header">
    <div class="header-item company_name">
      会社名
    </div>
    <div class="header-item display_name">
      表示名
    </div>
    <div class="header-item tel_number">
      電話番号
    </div>
    <div class="header-item create_date">
      登録日
    </div>
  </div>
  <ul class="list-supplier">
    <ListItemSupplier
      v-for="item of items"
      :id="item.supplier_id"
      :key="item.supplier_id"
      :company-name="item.company_name"
      :display-name="item.display_name"
      :tel-number="item.tel_number"
      :create-date="item.create_date"
    />
  </ul>
</template>

<script>
import ListItemSupplier from '@/components/supplier/ListItemSupplier';

export default {
  components: {
    ListItemSupplier,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  background-color: $white100;
  border-bottom: 2px solid $gray400;
  overflow-y: scroll;
  min-height: 31px;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb{
    background: none;
  }

  > .header-item  {
    color: $black400;
    font: $sans-none-12-bold;
    text-align: left;
    height: 29px;
    line-height: 29px;
  }
  > .company_name {
    flex: 1; // 横幅可変
  }
  > .display_name {
    flex: 1; // 横幅可変
  }
  > .tel_number {
    flex: 1; // 横幅可変
  }
  > .create_date {
    width: 116px;
  }
}

.list-supplier {
  overflow-y: scroll;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track{
    background-color: none;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: $gray600;
  }
}
</style>
