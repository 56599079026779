<template>
  <Modal
    ref="modalAutoDeleteSetting"
  >
    <div class="title">
      自動削除期間の変更
    </div>
    <p class="text">
      {{ selectValue }}日以前の記録は毎日自動的に削除されます。<br>自動削除期間を変更しますか？
    </p>
    <p class="note mt-xs">
      ※自動削除設定後の手動削除は可能ですが、削除期間の早い方が優先され削除されます。<br>
    </p>
    <p class="note">
      ※ゴミ箱の記録も自動削除の対象となります。
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="hideModal"
      >
        キャンセル
      </Button>
      <Button
        size="inline"
        type="primary"
        @click="$emit('update')"
      >
        変更する
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    Modal,
  },
  props: {
    selectValue: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update'],
  methods: {
    openModal() {
      this.$refs.modalAutoDeleteSetting.openModal();
    },
    hideModal() {
      this.$refs.modalAutoDeleteSetting.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  font: $sans-tight-14;
  color: $black700;
  padding-left: 1em;
  text-indent: -1em;
}
</style>
