<template>
  <div class="seekbar">
    <input
      v-model="currentTimeModelValue"
      type="range"
      min="0"
      :max="duration"
      step="any"
      class="input"
      @change="change"
      @mousedown.left="mousedown"
      @mouseup="mouseup"
    >
  </div>
</template>

<script>
export default {
  props: {
    currentTime: {
      type: [Number, String],
      default: 0,
    },
    duration: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change', 'update:currentTime', 'mousedown', 'mouseup'],
  computed: {
    currentTimeModelValue: {
      get() {
        return this.currentTime;
      },
      set(value) {
        this.$emit('update:currentTime', value);
      },
    },
  },
  methods: {
    change($event) {
      this.$emit('change', $event.target.value);
    },
    mousedown() {
      this.$emit('mousedown');
    },
    mouseup() {
      this.$emit('mouseup');
    },
  },
};
</script>

<style lang="scss" scoped>
.seekbar {
  > .input {
    width: 558px;
  }
}
</style>
