<template>
  <LayoutMain @refresh="init">
    <MessageBar
      v-if="successMessages.length > 0"
      :messages="successMessages"
      type="success"
      class="mb-xs"
    />
    <PageHeading heading="自動削除" />
    <MessageBar
      type="info"
      :messages="[
        '一定期間で記録を削除したい場合は自動削除期間を設定してください。',
        '自動削除された記録は復元できませんのでご注意ください。',
      ]"
      class="mt-xxs"
    />
    <FormBlock
      label="自動削除期間"
      class="mt-sm"
    >
      <FormRadio
        v-model="selectValue"
        :options="options"
        label-key="label"
        value-key="value"
        :disabled="!hasAuthUpdateSettingAutodeleteAPI"
      />
    </FormBlock>
    <Button
      v-if="hasAuthUpdateSettingAutodeleteAPI"
      size="inline"
      type="primary"
      :disabled="selectValue === currentValue"
      class="mt-md"
      @click="onClick"
    >
      変更を保存
    </Button>
  </LayoutMain>

  <ModalAutoDeleteSetting
    ref="modalAutoDeleteSetting"
    :select-value="selectValue"
    @update="onUpdate"
  />
</template>

<script>
import Button from '@/components/button/Button';
import MessageBar from '@/components/common/MessageBar';
import PageHeading from '@/components/common/PageHeading';
import FormBlock from '@/components/form/FormBlock';
import FormRadio from '@/components/form/FormRadio';
import LayoutMain from '@/components/layout/LayoutMain';
import ModalAutoDeleteSetting from '@/components/modal/ModalAutoDeleteSetting';
import { getAutoDeleteApi, getUserRoleAuthsApi, updateAutoDeleteApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    FormRadio,
    FormBlock,
    Button,
    MessageBar,
    PageHeading,
    ModalAutoDeleteSetting,
  },
  data() {
    return {
      /**
       * 自動削除期間の選択肢
       */
      options: [],

      /**
       * 現在の自動削除期間
       */
      currentValue: undefined,

      /**
       * 選択中の自動削除期間
       */
      selectValue: undefined,

      /**
       * 成功メッセージ
       */
      successMessages: [],

      /**
       * 権限一覧
       */
      authorizations: [],
    };
  },
  computed: {
    /**
     * 自動削除設定更新APIの権限チェック
     *
     * 権限が無い場合
     * ・自動削除期間のラジオを非活性
     * ・変更を保存ボタンを非表示
     */
    hasAuthUpdateSettingAutodeleteAPI() {
      return hasAuthorization(this.authorizations, API_IDS.UPDATE_SETTING_AUTODELETE);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),

    /**
     * 初期表示
     */
    async init() {
      this.startLoading();

      // 成功メッセージをクリア
      this.successMessages = [];

      // 権限取得
      await this.getUserRoleAuths();

      // 自動削除期間を設定
      await this.getAutoDelete();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.UPDATE_SETTING_AUTODELETE,
        ],
      });
      this.authorizations = data.data;
    },

    /**
     * 自動削除設定取得
     */
    async getAutoDelete() {
      const { data } = await getAutoDeleteApi();
      this.options = data.options;
      this.currentValue = data.current_value;
      this.selectValue = data.current_value;
    },

    /**
     * 「変更を保存」ボタンのクリックイベント
     */
    onClick() {
      if (this.selectValue === 'none') {
        // 「自動削除しない」を選択した場合はモーダルを挟まず更新
        this.onUpdate();
      } else {
        // 上記以外の場合はモーダルを表示
        this.$refs.modalAutoDeleteSetting?.openModal();
      }
    },

    /**
     * 自動削除期間の変更
     */
    async onUpdate() {
      this.startLoading();

      // 自動削除設定更新APIを呼び出す
      const { data } = await updateAutoDeleteApi({ value: this.selectValue });

      // 成功メッセージを設定
      this.successMessages = data.detail.map(item => item.msg);
      // 現在の自動削除期限を更新
      this.currentValue = this.selectValue;

      // モーダルを閉じる
      this.$refs.modalAutoDeleteSetting?.hideModal();

      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    padding: $spacing-sm $spacing-md;
    overflow-y: scroll;
  }
}
</style>
