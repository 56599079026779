<template>
  <IconBase :size="size">
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_778_45911)">
        <path
          d="M11.7817 -0.365234C11.5607 -0.365234 11.3483 -0.280902 11.1784 -0.12067L3.36943 7.6295L11.1784 15.3881C11.5097 15.717 12.0451 15.717 12.3765 15.3881C12.7078 15.0592 12.7078 14.5279 12.3765 14.199L5.76564 7.63793L12.3765 1.07685C12.7078 0.747955 12.7078 0.21666 12.3765 -0.112237C12.215 -0.280902 12.0026 -0.365234 11.7817 -0.365234Z"
          fill="#7E7E84"
        />
      </g>
      <defs>
        <clipPath id="clip0_778_45911">
          <rect
            width="16"
            height="16"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: 16,
    },
  },
};
</script>
