<template>
  <LayoutMain @refresh="init">
    <h1 class="heading">
      {{ pageHeading }}
    </h1>
    <p class="mt-xs mb-xxxxs">
      ・料金の目安となる利用量（利用量の見方の詳細については、<a href="/kvv-document.zip">ご利用ガイド</a>をご覧ください）
    </p>
    <div class="usage-tables">
      <TableUsage
        :headers="tableHeaders"
        :usages="earlyUsages"
      />
      <TableUsage
        :headers="tableHeaders"
        :usages="lateUsages"
        class="ml-sm"
      />
    </div>
    <p>当月のご利用量は、表内の月末の値となります。月の途中の値はご利用量の推移を表しており、請求対象ではありません。</p>
    <p>※1 ストレージ(GB)：ストレージに保存中のデータ総量を当月の日数で割った値</p>
    <p>※2 音声テキスト化(時間)：当月１日から新しくテキスト化した記録の総時間</p>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/layout/LayoutMain';
import TableUsage from '@/components/table/TableUsage';
import { getUsageCurrentApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    TableUsage,
  },
  data() {
    return {
      pageHeading: '',
      tableHeaders: ['日付', 'ストレージ(GB) ※1', '音声テキスト化(時間) ※2'],
      earlyUsages: [],
      lateUsages: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 今月の利用状況を取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * 今月の利用状況の取得
     */
    async fetchData() {
      // 今月の利用状況取得APIの呼び出し
      const { data } = await getUsageCurrentApi();
      this.pageHeading = data.date_msg;
      this.earlyUsages = data.usage.slice(0, 15);
      this.lateUsages = data.usage.slice(15, 31);
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    padding: $spacing-xs;
    overflow-y: scroll;
    color: $black700;
    font: $sans-normal-14;
  }
}

.heading {
  font: $sans-snug-20-bold;
}

.usage-tables {
  display: flex;
  align-items: flex-start;
  margin-bottom: $spacing-xs;
}
</style>
