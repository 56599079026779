<template>
  <div>
    <div class="block-row form-tel-row">
      <div class="form-label">
        {{ title }}
      </div>
      <ButtonBlock
        layout="left"
        class="add-button-block"
      >
        <Button
          v-if="!disabled"
          size="inline"
          type="normal"
          :disabled="items.length >= maxNumbers"
          @click="onAdd()"
        >
          {{ button }}
        </Button>
      </ButtonBlock>
    </div>
    <FormBlock id="form-scroll-area">
      <ul class="form-input-list">
        <li
          v-for="(item, index) in items"
          :key="index"
          class="form-input-item"
        >
          <FormInputText
            :model-value="item"
            :disabled="disabled"
            @change="onChange($event.target.value, index)"
          />
          <div
            v-if="items.length > 1 && !disabled"
            class="delete-button"
            @click="onDelete(index)"
          >
            <IconDelete size="16" />削除
          </div>
        </li>
      </ul>
    </FormBlock>
  </div>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import IconDelete from '@/components/icons/IconDelete';

export default {
  components: {
    Button,
    ButtonBlock,
    FormBlock,
    FormInputText,
    IconDelete,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    button: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxNumbers: {
      type: Number,
      default: null,
    },
    watchMessages: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onAdd', 'onChange', 'onDelete'],
  watch: {
    'watchMessages'() {
      // メッセージがある時に再度、#form-scroll-area max-height値の調整
      this.formScrollAreaMH();
    },
    'items'() {
      // itemsが０件の場合、デフォルト用にテキストボックスを一つ追加
      if(!this.items.length) this.onAdd();
    },
  },
  mounted() {
    this.formScrollAreaMH();
    
    // itemsが０件の場合、デフォルト用にテキストボックスを一つ追加
    // ※VDX410用の処理です（isContentsなどでDom生成のタイミング順序に変則がある場合用）
    if(!this.items.length) this.onAdd();
  },
  methods: {
    /**
     * #form-scroll-area max-height値の調整
     */
    formScrollAreaMH() {
      const formScrollAreaDOM = document.getElementById('form-scroll-area');
      if(formScrollAreaDOM) {
        const result = formScrollAreaDOM.getBoundingClientRect();
        formScrollAreaDOM.style.maxHeight = `calc(100vh - ${result.top + 105}px)`;
      }
    },
    onAdd() {
      this.$emit('onAdd');
      document.getElementById('form-scroll-area').scrollTop = 0;
    },
    onChange(textValue, index) {
      this.$emit('onChange', textValue, index);
    },
    onDelete(index) {
      this.$emit('onDelete', index);
    },
  },
};
</script>

<style lang="scss" scoped>
#form-scroll-area {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: $black300;
    border-radius: $radius6;
  }
  > :deep(.form-item) {
    margin-top: 0;
  }
  .form-input-list {
    .form-input-item {
      margin-top: $spacing-xxxs;
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      text-align: left;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      > .form-input-text {
        width: 346px;
      }
      .delete-button {
        margin-left: $spacing-xxxxs;
        font: $sans-none-14;
        color: $black500;
        cursor: pointer;
        svg {
          margin-right: 6px;
          vertical-align: text-bottom;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  &.form-tel-row {
    padding-bottom: 12px;
  }

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
  }
  > .form-block {
    &:last-child {
      margin-left: $spacing-sm;
    }
  }
  > .add-button-block {
    margin-left: $spacing-xs;
  }
  > .form-label {
    font: $sans-none-16-bold;
    color: $black700;
  }
}
</style>
