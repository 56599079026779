<template>
  <button
    class="button-edit"
    @click="onClick"
  >
    <IconEdit class="icon-edit" />編集
  </button>
</template>

<script>
import IconEdit from '@/components/icons/IconEdit';

export default {
  components: {
    IconEdit,
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-edit {
  height: 24px;
  border-radius: 12px;
  padding: 0px $spacing-xxxxs;
  display: flex;
  align-items: center;
  // padding: 0px;
  font: $sans-none-12;
  color: $black400;
  &:hover {
    background-color: $black100;
  }
  .icon-edit {
    height: 12px;
    width: 12px;
    margin-right: 6px;
    // margin-right: $spacing-xxxxs;
  }
}
</style>
