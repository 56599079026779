<template>
  <IconBase :size="size">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14 4C14.55 4 15 3.55 15 3V2V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2V3C13 3.55 13.45 4 14 4ZM17 2H16V3C16 4.1 15.1 5 14 5C12.9 5 12 4.1 12 3V2H8V3C8 4.1 7.1 5 6 5C4.9 5 4 4.1 4 3V2H3C1.35 2 0 3.35 0 5V17C0 18.65 1.35 20 3 20H17C18.65 20 20 18.65 20 17V5C20 3.35 18.65 2 17 2ZM18 17C18 17.55 17.55 18 17 18H3C2.45 18 2 17.55 2 17V6H18V17ZM7 3C7 3.55 6.55 4 6 4C5.45 4 5 3.55 5 3V2V1C5 0.45 5.45 0 6 0C6.55 0 7 0.45 7 1V2V3ZM6.95999 9H4.95999V11H6.95999V9ZM8.95999 9H10.96V11H8.95999V9ZM14.96 9H12.96V11H14.96V9ZM5.04001 12.98H7.04001V14.98H5.04001V12.98ZM11.04 12.98H9.04001V14.98H11.04V12.98ZM13.04 12.98H15.04V14.98H13.04V12.98Z"
        fill="#5C5C5C"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';
export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
