<template>
  <Modal
    id="modal-voice-search"
    ref="modal"
    class="modal-voice-search"
    data-test="modal-search-voice-detail"
  >
    <div class="header">
      <span class="title">詳細検索</span>
      <ButtonClose @click="hideModal" />
    </div>
    <MessageBar
      v-if="errors.length > 0"
      type="error"
      :messages="errors"
      data-test="errors"
    />
    <FormBlock label="グループ">
      <FormSelect
        v-model="baseIdModelValue"
        :options="baseOptions"
      />
    </FormBlock>
    <div class="form-row mt-xs">
      <div class="form">
        <FormBlock label="発信">
          <FormInputText
            v-model="callingNumberModelValue"
            size="width-260"
            data-test="calling-number"
          />
        </FormBlock>
        <p class="text">
          発信番号を指定します
        </p>
      </div>
      <div class="form">
        <FormBlock label="着信">
          <FormInputText
            v-model="incomingNumberModelValue"
            size="width-260"
            data-test="incoming-number"
          />
        </FormBlock>
        <p class="text">
          着信番号を指定します
        </p>
      </div>
    </div>
    <FormBlock label="検索ワード">
      <FormInputText
        v-model="searchWordModelValue"
        size="width-400"
        data-test="search-word"
      />
    </FormBlock>
    <FormRadio
      v-model="isAndModelValue"
      :options="[
        { id: true, text: 'すべて含む' },
        { id: false, text: 'どれか含む' },
      ]"
      data-test="is-and"
    />
    <p class="text">
      記録情報全体を条件に検索されます<br>複数入力したキーワードを「すべて含む」または「どれか含む」の条件検索を指定できます
    </p>
    <FormBlock
      label="検索期間"
      class="form-block-row"
    >
      <FormDatePicker
        v-model="searchPeriodFromModelValue"
        size="width-180"
        data-test="search-period-from"
      />
      <div class="wave">
        ~
      </div>
      <FormDatePicker
        v-model="searchPeriodToModelValue"
        size="width-180"
        data-test="search-period-to"
      />
    </FormBlock>
    <FormBlock
      v-if="isShowLineContract"
      label="回線契約管理番号"
    >
      <FormSelect
        v-model="lineContractModelValue"
        :options="lineContractOptions"
        data-test="line-contract"
      />
    </FormBlock>
    <FormBlock
      class="checkbox"
      label="回線種別"
    >
      <FormCheckbox
        v-model="hasHikariDirectModelValue"
        :options="[
          { id: 'has_hikari_direct', text: '光ダイレクト' },
        ]"
        data-test="has-hikari-direct"
      />
      <FormCheckbox
        v-model="hasAuOnModelValue"
        :options="[
          { id: 'has_au_on', text: 'auオフィスナンバー' },
        ]"
        class="ml-xxxs"
        data-test="has-au-on"
      />
      <FormCheckbox
        v-model="hasWebexCallingModelValue"
        :options="[
          { id: 'has_webex_calling', text: 'Webex Calling' },
        ]"
        class="ml-xxxs"
        data-test="has-webex-calling"
      />
      <FormCheckbox
        v-model="hasCloudCallingModelValue"
        :options="[
          { id: 'has_cloud_calling', text: 'Cloud Calling関連' },
        ]"
        class="mt-xxxs"
        data-test="has-cloud-calling"
      />
      <FormCheckbox
        v-model="hasMobileAuModelValue"
        :options="[
          { id: 'has_mobile_au', text: 'au' },
        ]"
        class="mt-xxxs ml-xxxs"
        data-test="has-mobile-au"
      />
    </FormBlock>
    <ButtonBlock layout="right">
      <Button
        type="normal"
        size="inline"
        data-test="reset-button"
        @click="reset"
      >
        リセット
      </Button>
      <Button
        type="primary"
        size="inline"
        data-test="search-button"
        @click="search"
      >
        検索
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonClose from '@/components/button/ButtonClose';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import FormBlock from '@/components/form/FormBlock';
import FormCheckbox from '@/components/form/FormCheckbox';
import FormDatePicker from '@/components/form/FormDatePicker';
import FormInputText from '@/components/form/FormInputText';
import FormRadio from '@/components/form/FormRadio';
import FormSelect from '@/components/form/FormSelect';

export default {
  components: {
    Button,
    ButtonClose,
    ButtonBlock,
    MessageBar,
    Modal,
    FormBlock,
    FormCheckbox,
    FormDatePicker,
    FormInputText,
    FormRadio,
    FormSelect,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    baseOptions: {
      type: Array,
      default: () => [],
    },
    lineContractOptions: {
      type: Array,
      default: () => [],
    },
    baseId: {
      type: String,
      default: '',
    },
    callingNumber: {
      type: String,
      default: '',
    },
    incomingNumber: {
      type: String,
      default: '',
    },
    searchWord: {
      type: String,
      default: '',
    },
    isAnd: {
      type: Boolean,
      default: true,
    },
    searchPeriodFrom: {
      type: String,
      default: '',
    },
    searchPeriodTo: {
      type: String,
      default: '',
    },
    lineContract: {
      type: String,
      default: '',
    },
    hasHikariDirect: {
      type: Boolean,
      default: false,
    },
    hasAuOn: {
      type: Boolean,
      default: false,
    },
    hasWebexCalling: {
      type: Boolean,
      default: false,
    },
    hasCloudCalling: {
      type: Boolean,
      default: false,
    },
    hasMobileAu: {
      type: Boolean,
      default: false,
    },
    isShowLineContract: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:baseId',
    'update:callingNumber',
    'update:incomingNumber',
    'update:searchWord',
    'update:isAnd',
    'update:searchPeriodFrom',
    'update:searchPeriodTo',
    'update:lineContract',
    'update:hasHikariDirect',
    'update:hasAuOn',
    'update:hasWebexCalling',
    'update:hasCloudCalling',
    'update:hasMobileAu',
    'search',
  ],
  computed: {
    baseIdModelValue: {
      get() {
        return this.baseId;
      },
      set(value) {
        this.$emit('update:baseId', value);
      },
    },
    callingNumberModelValue: {
      get() {
        return this.callingNumber;
      },
      set(value) {
        this.$emit('update:callingNumber', value);
      },
    },
    incomingNumberModelValue: {
      get() {
        return this.incomingNumber;
      },
      set(value) {
        this.$emit('update:incomingNumber', value);
      },
    },
    searchWordModelValue: {
      get() {
        return this.searchWord;
      },
      set(value) {
        this.$emit('update:searchWord', value);
      },
    },
    isAndModelValue: {
      get() {
        return this.isAnd;
      },
      set(value) {
        this.$emit('update:isAnd', value);
      },
    },
    searchPeriodFromModelValue: {
      get() {
        return this.searchPeriodFrom;
      },
      set(value) {
        this.$emit('update:searchPeriodFrom', value);
      },
    },
    searchPeriodToModelValue: {
      get() {
        return this.searchPeriodTo;
      },
      set(value) {
        this.$emit('update:searchPeriodTo', value);
      },
    },
    lineContractModelValue: {
      get() {
        return this.lineContract;
      },
      set(value) {
        this.$emit('update:lineContract', value);
      },
    },
    hasHikariDirectModelValue: {
      get() {
        return this.hasHikariDirect;
      },
      set(value) {
        this.$emit('update:hasHikariDirect', value);
      },
    },
    hasAuOnModelValue: {
      get() {
        return this.hasAuOn;
      },
      set(value) {
        this.$emit('update:hasAuOn', value);
      },
    },
    hasWebexCallingModelValue: {
      get() {
        return this.hasWebexCalling;
      },
      set(value) {
        this.$emit('update:hasWebexCalling', value);
      },
    },
    hasCloudCallingModelValue: {
      get() {
        return this.hasCloudCalling;
      },
      set(value) {
        this.$emit('update:hasCloudCalling', value);
      },
    },
    hasMobileAuModelValue: {
      get() {
        return this.hasMobileAu;
      },
      set(value) {
        this.$emit('update:hasMobileAu', value);
      },
    },
  },
  watch: {
    /**
     * propsのerrorsが更新されたら実行される
     */
    errors() {
      // モーダルのスクロールを最上部に戻す
      const domModalVoiceSerch = document.getElementById('modal-voice-search');
      if (domModalVoiceSerch) {
        domModalVoiceSerch.scrollTop = 0;
      }
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    reset() {
      this.baseIdModelValue = '';
      this.callingNumberModelValue = '';
      this.incomingNumberModelValue = '';
      this.searchWordModelValue = '';
      this.isAndModelValue = true,
      this.searchPeriodFromModelValue = '';
      this.searchPeriodToModelValue = '';
      this.lineContractModelValue = '';
      this.hasHikariDirectModelValue = false;
      this.hasAuOnModelValue = false;
      this.hasWebexCallingModelValue = false;
      this.hasCloudCallingModelValue = false;
      this.hasMobileAuModelValue = false;
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-voice-search {
  > ::v-deep(.container) {
    width: 592px;
    padding: $spacing-xs;

    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $spacing-xs;
      > .title {
        font: $sans-none-20-bold;
        color: $black700;
      }
    }

    > .form-row {
      display: flex;
      justify-content: space-between;
      > .form {
        > .text {
          font: $sans-tight-12;
          color: $black700;
          margin-top: $spacing-xxxxs;
        }
      }
    }

    > .form-block {
      margin-top: $spacing-xs;
      &.checkbox {
        .form-item {
          flex-wrap: wrap;
        }
      }
    }

    > .form-block-row {
      .form-item {
        display: flex;
        .wave {
          display: flex;
          align-items: center;
          color: $black700;
          width: 8px;
          margin-left: 4px;
          margin-right: 4px;
          font: $sans-none-14;
        }
      }
    }

    > .form-radio-block {
      margin-top: $spacing-xxxxs;
    }

    > .text {
      font: $sans-tight-12;
      color: $black700;
      margin-top: $spacing-xxxxs;
    }
  }
}
</style>
