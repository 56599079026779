export const toSnakeCase = (str) => {
  return str.split(/(?=[A-Z])/).join('_').toLowerCase();
};

export const toSnakeCaseObject = (obj) => {
  const result = {};
  Object.keys(obj).forEach(key => {
    if (!(typeof obj[key] === 'string' && !obj[key])) {
      result[toSnakeCase(key)] = obj[key];
    }
  });
  return result;
};

export const hasAuthorization = (authorizations, apiId) => {
  const authorization = authorizations.find(element => element.api_id === apiId);
  if (!authorization) return false;
  return authorization.is_auth;
};
