<template>
  <IconBase :size="size">
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4842 6.15082C13.1267 5.79332 12.5492 5.79332 12.1917 6.15082L7.98419 10.3583C7.62669 10.7158 7.62669 11.2933 7.98419 11.6508L12.1917 15.8583C12.5492 16.2158 13.1267 16.2158 13.4842 15.8583C13.8417 15.5008 13.8417 14.9233 13.4842 14.5658L9.92752 11L13.4842 7.44332C13.8417 7.08582 13.8325 6.49915 13.4842 6.15082Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
