<template>
  <LayoutMain @refresh="init">
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
      class="messages"
    />
    <h1 class="header-title">
      ログ管理
    </h1>
    
    <div class="search-form">
      <div class="search-form__block">
        <FormBlock label="メールアドレス">
          <FormInputText
            v-model="searchForm.email_address"
            size="width-260"
          />
        </FormBlock>
        <FormBlock label="機能名">
          <FormSelect
            v-model="searchForm.func_name"
            :options="funcNameOptions"
            size="width-400"
          />
        </FormBlock>
        <FormBlock label="種別">
          <FormSelect
            v-model="searchForm.type_name"
            :options="typeNameOptions"
            :disabled="!searchForm.func_name"
            size="width-260"
          />
        </FormBlock>
      </div>
      <div class="search-form__block">
        <FormBlock
          label="検索期間"
          class="form-block-row"
        >
          <FormDatePicker
            v-model="searchForm.search_period_from"
            :clearable="true"
            size="width-180"
          />
          <FormDatePicker
            v-model="searchForm.search_period_hm_from"
            :clearable="true"
            type="time"
            size="width-120"
            class="form-time-picker"
          />
          <div class="wave">
            ~
          </div>
          <FormDatePicker
            v-model="searchForm.search_period_to"
            :clearable="true"
            size="width-180"
          />
          <FormDatePicker
            v-model="searchForm.search_period_hm_to"
            :clearable="true"
            type="time"
            size="width-120"
            class="form-time-picker"
          />
        </FormBlock>
        <Button
          size="inline"
          @click="onSearchSubmit"
        >
          検索
        </Button>
      </div>
    </div>
    <TableListLog
      v-if="isAfterSearch"
      :records="result.items"
      :record-section-from="result.recordSectionFrom"
      :record-section-to="result.recordSectionTo"
      :total-record="result.totalRecord"
      :is-asc="searchForm.is_asc"
      :page="searchForm.page"
      @sortAsc="sortAsc"
      @sortDesc="sortDesc"
      @prevPage="prevPage"
      @nextPage="nextPage"
      @csvDownload="onCsvDownload"
    />
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/layout/LayoutMain';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import FormSelect from '@/components/form/FormSelect';
import FormDatePicker from '@/components/form/FormDatePicker';
import Button from '@/components/button/Button.vue';
import TableListLog from '@/components/logtable/TableListLog';
import { saveAs } from 'file-saver';
import { getLogTypeNameApi, postSearchAccessLogApi, postAccessLogCsvDownloadApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';

function initialState() {
  return {

    /**
     * 検索結果
     */
    result: {
      totalRecord: null,
      recordSectionFrom: null,
      recordSectionTo: null,
      items: [],
    },

    /**
     * 検索内容
     */
    searchForm: {
      email_address: '',
      func_name: '',
      type_name: '',
      search_period_from: '',
      search_period_hm_from: '00:00',
      search_period_to: '',
      search_period_hm_to: '23:59',
      is_asc: false,
      page: 1,
    },

    /**
     * 検索内容（csvダウンロード用）
     */
    searchFormSlice: {
      email_address: '',
      func_name: '',
      type_name: '',
      search_period_from: '',
      search_period_hm_from: '00:00',
      search_period_to: '',
      search_period_hm_to: '23:59',
      is_asc: false,
    },

    /**
     * 機能種別一覧
     */
    funcAndTypeData: [],
    funcNameOptions: [{id: '', label: '選択してください'}],
    typeNameOptions: [{id: '', label: '選択してください'}],

    /**
     * 検索後かどうかのフラグ
     */
    isAfterSearch: false,

    /**
     * ログ管理画面に表示するエラーメッセージ
     */
    errorMessages: [],
  };
}

export default {
  components: {
    LayoutMain,
    MessageBar,
    FormBlock,
    FormInputText,
    FormSelect,
    FormDatePicker,
    Button,
    TableListLog,
  },
  data() {
    return initialState();
  },
  watch: {
    'searchForm.func_name'(n) {
      this.searchForm.type_name = '';
      this.typeNameOptions = [{id: '', label: '選択してください'}];
      this.funcAndTypeData.filter(elm => {
        if(elm.func_name === n) {
          this.typeNameOptions.push({id: elm.type_name, label: elm.type_name });
        }
      });
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
    ]),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // dataの初期化
      Object.assign(this.$data, initialState());

      // メッセージクリア
      this.errorMessages = [];

      // 機能種別一覧取得
      await this.getLogTypeName();

      this.stopLoading();
    },

    /**
     * 機能種別一覧取得
     */
    async getLogTypeName() {
      const { data } = await getLogTypeNameApi();
      this.funcAndTypeData = data.data;

      const uniqueData = Array.from(
        new Map(this.funcAndTypeData.map((data) => [data.func_name, data])).values(),
      );
      uniqueData.forEach(elm => {
        this.funcNameOptions.push({id: elm.func_name, label: elm.func_name});
      });
    },

    /**
     * 検索ボタンの処理
     */
    async onSearchSubmit() {
      this.startLoading();
      this.searchForm.is_asc = false;
      this.searchForm.page = 1;
      await this.onSearchAccessLog();
      this.stopLoading();
    },

    /**
     * アクセスログ検索API
     */
    async onSearchAccessLog() {
      // メッセージクリア
      this.errorMessages = [];

      const { status, data } = await postSearchAccessLogApi(this.searchForm);

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.isAfterSearch = true;
      this.result.totalRecord = data.total_record;
      this.result.recordSectionFrom = data.record_section_from;
      this.result.recordSectionTo = data.record_section_to;
      this.result.items = data.data;

      this.searchFormSlice.email_address = this.searchForm.email_address;
      this.searchFormSlice.func_name = this.searchForm.func_name;
      this.searchFormSlice.type_name = this.searchForm.type_name;
      this.searchFormSlice.search_period_from = this.searchForm.search_period_from;
      this.searchFormSlice.search_period_hm_from = this.searchForm.search_period_hm_from;
      this.searchFormSlice.search_period_to = this.searchForm.search_period_to;
      this.searchFormSlice.search_period_hm_to = this.searchForm.search_period_hm_to;
      this.searchFormSlice.is_asc = this.searchForm.is_asc;
    },

    /**
     * 並び順：古い
     */
    async sortAsc() {
      this.startLoading();
      this.searchForm.is_asc = true;
      this.searchForm.page = 1;
      await this.onSearchAccessLog();
      this.stopLoading();
    },

    /**
     * 並び順：新しい
     */
    async sortDesc() {
      this.startLoading();
      this.searchForm.is_asc = false;
      this.searchForm.page = 1;
      await this.onSearchAccessLog();
      this.stopLoading();
    },

    /**
     * 前のページ
     */
    async prevPage() {
      this.startLoading();
      this.searchForm.page = this.searchForm.page - 1;
      await this.onSearchAccessLog();
      this.stopLoading();
    },

    /**
     * 次のページ
     */
    async nextPage() {
      this.startLoading();
      this.searchForm.page = this.searchForm.page + 1;
      await this.onSearchAccessLog();
      this.stopLoading();
    },

    /**
     * CSVダウンロード
     */
    async onCsvDownload() {
      this.startLoading();

      // メッセージクリア
      this.errorMessages = [];

      // CSVダウンロードAPIの呼び出し
      const { status, data, headers } = await postAccessLogCsvDownloadApi(this.searchFormSlice);

      if (status === 400 || status === 422) {
        const text = await data.text();
        this.errorMessages = JSON.parse(text).detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      const content = headers['content-disposition'];
      const regex = content.match(/filename=(.+)/);
      const filename = regex[1];
      saveAs(data, filename);
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
    padding: $spacing-sm $spacing-xs 0;
    box-sizing: border-box;
  }
}

.message-bar {
  margin-bottom: $spacing-xs;
}

.header-title {
  font: $sans-snug-20-bold;
  color: $black700;
  height: 32px;
}

.search-form {
  padding-bottom: $spacing-xs;
  border-bottom: 1px solid $gray500;
  &__block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: $spacing-xs;
    > .form-block {
      margin-right: $spacing-xs;
      &:last-of-type {
        margin-right: 0;
      }
    }
    > .form-block-row {
      :deep(.form-item) {
        display: flex;
        width: 100%;
        .wave {
          display: flex;
          align-items: center;
          color: $black700;
          width: 8px;
          margin-left: 4px;
          margin-right: 4px;
          font: $sans-none-14;
        }
      }
      .form-time-picker {
        margin-left: 4px;
      }
    }
    > .button{
      margin-left: auto;
    }
  }
}

.table-list-log {
  overflow: auto;
}
</style>
