<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 0.758431C10.925 0.433431 10.4 0.433431 10.075 0.758431L5.99998 4.8251L1.92498 0.750098C1.59998 0.425098 1.07498 0.425098 0.749976 0.750098C0.424976 1.0751 0.424976 1.6001 0.749976 1.9251L4.82498 6.0001L0.749976 10.0751C0.424976 10.4001 0.424976 10.9251 0.749976 11.2501C1.07498 11.5751 1.59998 11.5751 1.92498 11.2501L5.99998 7.1751L10.075 11.2501C10.4 11.5751 10.925 11.5751 11.25 11.2501C11.575 10.9251 11.575 10.4001 11.25 10.0751L7.17498 6.0001L11.25 1.9251C11.5666 1.60843 11.5666 1.0751 11.25 0.758431Z"
      fill="black"
      fill-opacity="0.5"
    />
  </svg>
</template>
