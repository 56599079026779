<template>
  <div class="toggle-paragraph">
    <button
      class="button"
      :disabled="!isOriginal"
      @click="showEdited"
    >
      編集済み
    </button>
    <span class="slash"> / </span>
    <button
      class="button"
      :disabled="isOriginal"
      @click="showOriginal"
    >
      原文表示
    </button>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isOriginal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    showEdited() {
      this.isOriginal = false;
    },
    showOriginal() {
      this.isOriginal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-paragraph {
  font: $sans-none-12;
  color: $black700;

  > .button {
    padding: 0px;
    font: $sans-none-12-bold;
    color: $black700;

    &:disabled {
      font: $sans-none-12;
      color: $black700;
      cursor: not-allowed;
    }
  }
}
</style>
