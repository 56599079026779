<template>
  <div class="table-list-voice">
    <div class="header">
      <div class="header-left" />
      <div class="header-right">
        <SortOrder
          v-if="records.length > 0"
          :is-asc="isAsc"
          data-test="sort-order"
          @sortAsc="sortAsc"
          @sortDesc="sortDesc"
        />
        <Pagination
          v-if="records.length > 0"
          :start-count="startCount"
          :end-count="endCount"
          :total-count="totalCount"
          :has-prev="hasPrev"
          :has-next="hasNext"
          data-test="pagination"
          @prev="prevPage"
          @next="nextPage"
        />
        <Pagination
          v-if="records.length === 0 && page >= 2"
          :has-prev="hasPrev"
          @prev="prevPage"
        />
      </div>
    </div>
    <div class="body">
      <ListVoice
        v-if="records.length > 0"
        ref="listVoice"
        v-model:checkedIds="checkedIdsModelValue"
        :items="records"
        :is-show-checkbox="isShowCheckbox"
        @click="toDetailPage"
      />
      <p
        v-else
        class="no-records"
      >
        表示する記録がありません
      </p>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import SortOrder from '@/components/common/SortOrder';
import ListVoice from '@/components/voice/ListVoice.vue';

export default {
  components: {
    SortOrder,
    Pagination,
    ListVoice,
  },
  props: {
    checkedIds: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
    totalRecord: {
      type: String,
      default: '',
    },
    isAsc: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    isShowCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:checkedIds',
    'sortAsc',
    'sortDesc',
    'prevPage',
    'nextPage',
    'toDetailPage',
  ],
  computed: {
    startCount() {
      return ((this.page - 1) * 50) + 1;
    },
    endCount() {
      return ((this.page - 1) * 50) + this.records.length;
    },
    totalCount() {
      const removed = this.totalRecord.replace(/,/g, '');
      return parseInt(removed, 10);
    },
    hasPrev() {
      return this.page > 1;
    },
    hasNext() {
      const removed = this.totalRecord.replace(/,/g, '');
      const parsedInt = parseInt(removed, 10);
      return parsedInt > (((this.page - 1) * 50) + this.records.length);
    },
    checkedIdsModelValue: {
      get() {
        return this.checkedIds;
      },
      set(newValue) {
        this.$emit('update:checkedIds', newValue);
      },
    },
  },
  methods: {
    sortAsc() {
      this.$emit('sortAsc');
    },
    sortDesc() {
      this.$emit('sortDesc');
    },
    prevPage() {
      this.$emit('prevPage');
    },
    nextPage() {
      this.$emit('nextPage');
    },
    toDetailPage(id) {
      this.$emit('toDetailPage', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-voice {
  height: 100%;
  display: flex;
  flex-direction: column;
  > .header {
    height: 55px;
    padding: $spacing-xxxs $spacing-xxs 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-right {
      display: flex;
      .pagination-block {
        margin-left: 30px;
      }
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    .no-records {
      font: $sans-none-16;
      color: $black700;
      padding: $spacing-xlg 0 0 $spacing-xlg;
    }
  }
}
</style>
