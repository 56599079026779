<template>
  <li class="listitem-log">
    <div class="item-wrap">
      <div class="textblock func_name">
        {{ funcName }}
      </div>
      <div class="textblock type_name">
        {{ typeName }}
      </div>
      <div class="textblock email_address">
        {{ emailAddress }}
      </div>
      <div class="textblock user_role_name">
        {{ userRoleName }}
      </div>
      <time class="time">{{ operateDatetime }}</time>
    </div>
  </li>
</template>

<script>
export default {
  components: {
  },
  props: {
    funcName: {
      type: String,
      default: '',
    },
    typeName: {
      type: String,
      default: '',
    },
    emailAddress: {
      type: String,
      default: '',
    },
    userRoleName: {
      type: String,
      default: '',
    },
    operateDatetime: {
      type: String,
      default: '',
    },
  },
};

</script>

<style lang="scss" scoped>
.listitem-log {
  position: relative;
  border-bottom: 1px solid $gray400;
  width: 100%;
  color: $black700;

  .item-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    height: 100%;
    text-align: left;
    font: $sans-none-14;
    > .textblock {
      padding-right: $spacing-xxs;
      line-height: 40px;
    }
    > .func_name {
      width: calc((100% - 520px) * 0.4);
    }
    > .type_name {
      width: 180px;
    }
    > .email_address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc((100% - 520px) * 0.6);
    }
    > .user_role_name {
      width: 190px;
    }
    > time {
      min-width: 150px;
    }
  }
}
</style>
