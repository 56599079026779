<template>
  <div class="volume-controls">
    <button
      v-if="!muted"
      class="button-mute"
      @click="muteOn"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_8340_8764)">
          <path
            d="M7 9V15H11L16 20V4L11 9H7Z"
            fill="#4A4B53"
          />
        </g>
        <defs>
          <clipPath id="clip0_8340_8764">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
    <button
      v-if="muted"
      class="button-mute"
      @click="muteOff"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_8340_8626)">
          <path
            d="M16.5 12C16.5 10.23 15.48 8.71 14 7.97V10.18L16.45 12.63C16.48 12.43 16.5 12.22 16.5 12ZM19 12C19 12.94 18.8 13.82 18.46 14.64L19.97 16.15C20.63 14.91 21 13.5 21 12C21 7.72 18.01 4.14 14 3.23V5.29C16.89 6.15 19 8.83 19 12ZM4.27 3L3 4.27L7.73 9H3V15H7L12 20V13.27L16.25 17.52C15.58 18.04 14.83 18.45 14 18.7V20.76C15.38 20.45 16.63 19.81 17.69 18.95L19.73 21L21 19.73L12 10.73L4.27 3ZM12 4L9.91 6.09L12 8.18V4Z"
            fill="#4A4B53"
          />
        </g>
        <defs>
          <clipPath id="clip0_8340_8626">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
    <div>
      <input
        :value="volume"
        type="range"
        min="0"
        max="1"
        step="any"
        class="volume-bar"
        @input="input"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    muted: {
      type: Boolean,
      default: false,
    },
    volume: {
      type: [Number, String],
      default: 1,
    },
  },
  emits: ['input', 'muteOn', 'muteOff'],
  methods: {
    muteOn() {
      this.$emit('muteOn');
    },
    muteOff() {
      this.$emit('muteOff');
    },
    input($event) {
      this.$emit('input', $event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.volume-controls {
  display: flex;
  align-items: center;

  .volume-bar {
    width: 79px;
  }
}
.button-mute {
  padding: 0;
}
</style>
