<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="actions">
      <ButtonBack @click="backToList" />
    </div>
    <div
      v-if="isContents"
      class="conts-wrap"
    >
      <div class="header">
        <h1 class="title">
          グループ編集
        </h1>
        <Button
          size="inline"
          type="delete"
          @click="$refs.modalDelete?.openModal()"
        >
          グループを削除
        </Button>
      </div>
      <div class="base">
        <div class="block-row">
          <FormBlock label="グループID">
            <FormInputText
              v-model="base_display_id"
              :disabled="true"
            />
          </FormBlock>
          <FormBlock label="グループ名">
            <FormInputText
              v-model="base_name"
            />
          </FormBlock>
        </div>

        <FormInputItems
          title="電話番号"
          button="電話番号を追加"
          class="list-base-tel-add"
          :max-numbers="max_tel_numbers"
          :items="array_tel_number"
          :watch-messages="errorMessages"
          @onAdd="onAddTel"
          @onChange="onChangeTel"
          @onDelete="onDeleteTel"
        />

        <ButtonBlock
          class="edit-button-block"
        >
          <Button
            size="inline"
            type="normal"
            @click="$router.push({ name: 'VDX400' })"
          >
            キャンセル
          </Button>
          <Button
            size="inline"
            type="primary"
            @click="onEditSave"
          >
            変更を保存
          </Button>
        </ButtonBlock>
      </div>
    </div>

    <Modal ref="modalDelete">
      <div class="title">
        グループの削除
      </div>
      <p class="text">
        一度削除したグループは復元できません。本当に削除しますか？
      </p>
      <ButtonBlock
        class="edit-button-block"
      >
        <Button
          type="normal"
          size="inline"
          @click="$refs.modalDelete?.hideModal()"
        >
          キャンセル
        </Button>
        <Button
          type="delete"
          size="inline"
          @click="onDeleteBase"
        >
          削除する
        </Button>
      </ButtonBlock>
    </Modal>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonBack from '@/components/button/ButtonBack';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutMain from '@/components/layout/LayoutMain';
import FormInputItems from '@/components/form/FormInputItems.vue';
import { mapActions } from 'vuex';
import { getBaseApi, editBaseApi, deleteBaseApi } from '@/utils/ApiHelper';

export default {
  components: {
    LayoutMain,
    Button,
    ButtonBack,
    FormBlock,
    FormInputText,
    Modal,
    ButtonBlock,
    MessageBar,
    FormInputItems,
  },
  data() {
    return {
      /**
       * グループ情報
       */
      base_id: '',
      base_display_id: '',
      base_name: '',
      array_tel_number: [],
      max_tel_numbers:  null,

      /**
       * contents 表示・非表示フラグ
       */
      isContents: false,

      /**
       * グループ編集画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setStoreMessages',
    ]),

    /**
     * 一覧に戻る
     */
    backToList() {
      this.$router.push({ name: 'VDX400' });
    },

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // グループ詳細取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * グループ詳細取得
     */
    async fetchData() {
      const { status, data } = await getBaseApi({
        id: this.$route.params.id, 
      });

      if (status == 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.base_id = data.base_id;
      this.base_display_id = data.base_display_id;
      this.base_name = data.base_name;
      this.array_tel_number = data.array_tel_number;
      this.max_tel_numbers = data.max_tel_numbers;
      this.isContents = true;
    },

    /**
     * 電話番号の「電話番号の追加」ボタンのクリックイベント
     */
    onAddTel() {
      this.array_tel_number.unshift('');
    },

    /**
     * 電話番号入力項目のチェンジイベント
     */
    onChangeTel(textValue, index) {
      this.array_tel_number[index] = textValue;
    },

    /**
     * 電話番号の「削除」ボタンのクリックイベント
     */
    onDeleteTel(index) {
      this.array_tel_number.splice(index, 1);
    },

    /**
     * グループ削除確認モーダルの「削除する」ボタンのクリックイベント
     */
    async onDeleteBase() {
      this.startLoading();
      const { status, data } = await deleteBaseApi({ id: this.$route.params.id });

      if (status == 400) {
        this.$refs.modalDelete?.hideModal();
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX400' });
      this.stopLoading();
    },

    /**
     * 「変更を保存」ボタンのクリックイベント
     */
    async onEditSave() {
      this.startLoading();

      // 配列内の空の値を削除
      const result_array_tel_number = this.array_tel_number.filter(item => item);

      const { status, data } = await editBaseApi(
        {
          id: this.$route.params.id, 
        },
        {
          base_name: this.base_name,
          array_tel_number: result_array_tel_number,
        },
      );

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX400' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-wrap{
  padding: $spacing-xxs $spacing-xs;
  border-bottom: 1px solid $gray400;
}

.conts-wrap {
  padding: $spacing-xs $spacing-xlg;
}
.message-bar {
  margin: $spacing-xxs $spacing-xxs 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 47px;
  border-bottom: 1px solid $gray400;
  padding: 0 $spacing-xs;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 42px;
  border-bottom: 2px solid $gray400;

  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}

.form-input-text{
  width: 346px;
}

.list-base-tel-add{
  margin-top: $spacing-sm;
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  align-items: center;
  &.form-tel-row{
    padding-bottom: 12px;
  }

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
  }
  > .form-block {
    &:last-child {
      margin-left: $spacing-sm;
    }
  }
  > .add-button-block{
    margin-left: $spacing-xs;
  }
  > .form-label {
    font: $sans-none-16-bold;
    color: $black700;
  }
}

.edit-button-block {
  margin-top: $spacing-md;
}
</style>
