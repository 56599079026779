<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_12005_73061)">
      <path
        d="M15.8334 7.5L16.875 5.20834L19.1667 4.16667L16.875 3.125L15.8334 0.833336L14.7917 3.125L12.5 4.16667L14.7917 5.20834L15.8334 7.5ZM9.58337 7.91667L7.50004 3.33334L5.41671 7.91667L0.833374 10L5.41671 12.0833L7.50004 16.6667L9.58337 12.0833L14.1667 10L9.58337 7.91667ZM15.8334 12.5L14.7917 14.7917L12.5 15.8333L14.7917 16.875L15.8334 19.1667L16.875 16.875L19.1667 15.8333L16.875 14.7917L15.8334 12.5Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_12005_73061">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>

export default {
  props: {
    size: {
      type: Number,
      default: 32,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  fill: $purple100;
}
</style>