<template>
  <div class="navigation-side">
    <ul
      v-if="$route.name === 'VDX100' || $route.name === 'VDX110' || $route.name === 'VDX200' || $route.name === 'VDX210' || $route.name === 'VDX300'"
      class="list"
    >
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDX100' || $route.name === 'VDX110' }"
        @click="click('VDX100')"
      >
        記録一覧
      </li>
      <li
        v-if="userRole === userRoleType.ADMINISTRATOR"
        class="list-item"
        :class="{ active: $route.name === 'VDX300' }"
        @click="click('VDX300')"
      >
        ダウンロードデータ作成状況
      </li>
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDX200' || $route.name === 'VDX210' }"
        @click="click('VDX200')"
      >
        ゴミ箱
      </li>
    </ul>

    <ul
      v-if="$route.name === 'VDXA00' || $route.name === 'VDXA10'"
      class="list"
    >
      <li
        v-if="isUseStt"
        class="list-item"
        :class="{ active: $route.name === 'VDXA00' || $route.name === 'VDXA10' }"
        @click="click('VDXA00')"
      >
        キーワード通知
      </li>
    </ul>

    <ul
      v-if="$route.name === 'VDX500'
        || $route.name === 'VDX510'
        || $route.name === 'VDX400'
        || $route.name === 'VDX410'
        || $route.name === 'VDX700'
        || $route.name === 'VDX800'
        || $route.name === 'VDX810'
        || $route.name === 'VDX820'
        || $route.name === 'VDX600'
        || $route.name === 'VDX610'
        || $route.name === 'VDXC00'
        || $route.name === 'VDXB00'
        || $route.name === 'VDXD00'
        || $route.name === 'VDXD10'
        || $route.name === 'VDXD20'
      "
      class="list"
    >
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDX500' || $route.name === 'VDX510' }"
        @click="click('VDX500')"
      >
        ユーザー
      </li>
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDXD00' || $route.name === 'VDXD10' || $route.name === 'VDXD20' }"
        @click="click('VDXD00')"
      >
        取引先一覧
      </li>
      <li
        v-if="userRole === userRoleType.ADMINISTRATOR"
        class="list-item"
        :class="{ active: $route.name === 'VDX400' || $route.name === 'VDX410' }"
        @click="click('VDX400')"
      >
        グループ
      </li>
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDX700' }"
        @click="click('VDX700')"
      >
        自動削除
      </li>
      <li
        v-if="isUseStt"
        class="list-item"
        :class="{ active: $route.name === 'VDX800' || $route.name === 'VDX810' || $route.name === 'VDX820' }"
        @click="click('VDX800')"
      >
        キーワード設定
      </li>
      <li
        v-if="userRole === userRoleType.ADMINISTRATOR || userRole === userRoleType.VIEWER"
        class="list-item"
        :class="{ active: $route.name === 'VDX600' }"
        @click="click('VDX600')"
      >
        今月の利用状況
      </li>
      <li
        v-if="userRole === userRoleType.ADMINISTRATOR || userRole === userRoleType.VIEWER"
        class="list-item"
        :class="{ active: $route.name === 'VDX610' }"
        @click="click('VDX610')"
      >
        過去の利用状況
      </li>
      <li
        v-if="userRole === userRoleType.ADMINISTRATOR"
        class="list-item"
        :class="{ active: $route.name === 'VDXC00' }"
        @click="click('VDXC00')"
      >
        ログ管理
      </li>
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDXB00' }"
        @click="click('VDXB00')"
      >
        SSO設定
      </li>
    </ul>
  </div>
</template>

<script>
import { USER_ROLE_TYPE } from '@/utils/Constants';
import { getIdToken } from '@/utils/IdTokenManager';

export default {
  emits: ['refresh'],
  computed: {
    userRole() {
      return getIdToken('userRole');
    },
    userRoleType() {
      return USER_ROLE_TYPE;
    },
    isUseStt() {
      return getIdToken('useStt') === 'true';
    },
  },
  methods: {
    click(routeName) {
      // 同画面の場合はrefreshイベントを発生させる
      if (this.$route.name === routeName) {
        this.$emit('refresh');
        return;
      }
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-side {
  width: 180px;
  min-width: 180px;
  border-right: 1px solid $gray400;

  .list {
    margin-top: $spacing-xxs;

    .list-item {
      width: 164px;
      padding: $spacing-xxxxs $spacing-xxs;
      display: flex;
      align-items: center;
      font: $sans-tight-14;
      color: $black700;
      border-radius: 6px;
      cursor: pointer;
      margin-bottom: $spacing-xxxxs;

      &.active {
        font: $sans-tight-14-bold;
        background: $gray300;
      }
    }
  }
}
</style>
