<template>
  <Modal
    ref="modalAiGeneration"
    class="modalAiGeneration"
  >
    <div
      class="container"
    >
      <div class="title">
        <ButtonClose
          class="button-close"
          @click="hideModal"
        />
        <IconStars class="title-icon" />会話要約とToDo
      </div>
      <MessageBar
        v-if="aiGenerationErrorMessages.length > 0"
        :messages="aiGenerationErrorMessages"
        type="error"
        class="messages"
      />
      <div class="context">
        <template v-if="aiStatus === 'PROCESSING'">
          <div class="processing">
            <p class="processing-text">
              生成中です。<br>
              しばらくお待ちいただく必要があります。完了するまでお待ちください。
            </p>
            <Loading />
          </div>
        </template>
        <template v-if="aiStatus === 'OK'">
          <div class="wclomn">
            <p class="date">
              {{ aiExecDatetime }}
            </p>
            <Button
              v-if="isRegeneration"
              size="small"
              :disabled="!isRegenerationActive"
              @click="onReGeneration"
            >
              再生成
            </Button>
          </div>
          <div class="summary">
            <p class="read">
              会話要約
            </p>
            <div class="text">
              {{ aiGeneratedSummary }}
            </div>
          </div>
          <div class="todo">
            <p class="read">
              ToDo
            </p>
            <div class="text">
              {{ aiGeneratedTask }}
            </div>
          </div>
        </template>
        <template v-if="aiStatus === 'ERROR' || aiStatus === 'FILTER_BLOCKED' || aiStatus === 'TIMEOUT_ERROR'">
          <div class="error">
            <div class="clomn">
              <Button
                v-if="isRegeneration"
                size="small"
                :disabled="!isRegenerationActive"
                @click="onReGeneration"
              >
                再生成
              </Button>
            </div>
            <p class="error-text">
              {{ errorText[aiStatus] }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import MessageBar from '@/components/common/MessageBar';
import IconStars from '@/components/icons/IconStars';
import Button from '@/components/button/Button';
import ButtonClose from '@/components/button/ButtonClose';
import Loading from '@/components/common/Loading';
import Modal from '@/components/common/Modal';
import { getAiGenerationApi, postAiGenerationApi } from '@/utils/ApiHelper';

export default {
  components: {
    MessageBar,
    IconStars,
    Button,
    ButtonClose,
    Loading,
    Modal,
  },
  props: {
    canCloseByOutside: {
      type: Boolean,
      default: true,
    },
    summaryId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      /**
       * 「会話要約とToDo」の内容・状態で使用
       */
      aiGeneratedSummary: '',
      aiGeneratedTask: '',
      aiExecDatetime: '',
      aiStatus: '',
      aiGenerationErrorMessages: [],
      isRegeneration: false,
      isRegenerationActive: false,
      errorText: {
        ERROR: '生成に失敗しました。',
        FILTER_BLOCKED: '不適切な文言が確認されため、生成されません。',
        TIMEOUT_ERROR: 'リクエストがタイムアウトしました。時間を置いて再生成してください。',
      },

      /**
       * setInterval イベントの重複処理を防ぐためのフラグ
       */
      isIntervalFlag: true,
    };
  },
  methods: {
    async openModal() {
      this.aiGenerationErrorMessages = [];
      this.$refs.modalAiGeneration.openModal();
      // AI生成テキスト取得API
      await this.getAiGeneration();

      // aiStatusが "PROCESSING" かつ、またはモーダルが開いている場合
      // 設定ミリ秒ごとに、AI生成テキスト取得APIを呼ぶ
      this.onIntervalAiGeneration();
    },
    hideModal() {
      this.$refs.modalAiGeneration.hideModal();
    },

    /**
     * AI生成テキスト取得APIの処理
     */
    async getAiGeneration() {
      this.aiStatus = 'PROCESSING';
      const { status, data } = await getAiGenerationApi(
        { summary_id: this.summaryId },
      );

      // 業務エラーの場合
      // モーダルにエラーメッセージを表示する
      if (status === 400 || status === 422) {
        this.aiGenerationErrorMessages = data.detail.map(item => item.msg);
        this.aiStatus = '';
        return;
      }

      this.aiGeneratedSummary = data.ai_generated_summary;
      this.aiGeneratedTask = data.ai_generated_task;
      this.aiExecDatetime = data.ai_exec_datetime;
      this.aiStatus = data.ai_status;
      this.isRegeneration = data.is_regeneration;
      this.isRegenerationActive = data.is_regeneration_active;

      if(this.aiStatus !== 'PROCESSING') this.isIntervalFlag = true;
    },

    /**
     * AIテキスト生成APIの処理
     */
    async onReGeneration() {
      this.aiStatus = 'PROCESSING';

      const { status, data } = await postAiGenerationApi(
        { summary_id: this.summaryId },
      );

      // 業務エラーの場合
      // モーダルにエラーメッセージを表示する
      if (status === 400 || status === 422) {
        this.aiGenerationErrorMessages = data.detail.map(item => item.msg);
        this.aiStatus = 'OK';
        return;
      }
      
      // aiStatusが "PROCESSING" かつ、またはモーダルが開いている場合
      // 設定ミリ秒ごとに、AI生成テキスト取得APIを呼ぶ
      this.onIntervalAiGeneration();
    },
    onIntervalAiGeneration() {
      const intervalTime = 3000;
      if(this.isIntervalFlag) {
        const intervalAiGeneration = setInterval(() => {
          if(this.aiStatus === 'PROCESSING' && this.$refs.modalAiGeneration?.isShowModal){
            // AI生成テキスト取得APIを呼ぶ
            this.getAiGeneration();
            // false にすることで "const intervalAiGeneration = " イベントが重複発火しないようにする。
            this.isIntervalFlag = false;
          }  else {
            // setIntervalの処理をクリアする
            clearInterval(intervalAiGeneration);
            // true にすることで "const intervalAiGeneration = " イベントを再発火できるようにする。
            this.isIntervalFlag = true;
          }
        }, intervalTime);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modalAiGeneration {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(85, 85, 85, 0.8);
  z-index: $z-modal;
  overflow-y: scroll;
  :deep(> .container) {
    background-color: $white100;
    width: 592px;
    min-height: 600px;
    margin: $spacing-xxxlg auto;
    border: 1px solid $gray600;
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
    padding: $spacing-sm;
    border-radius: $radius8;
    .title {
      font: $sans-none-20-bold;
      color: $black700;
      margin-bottom: $spacing-xs;
      padding: 0 $spacing-xs;
      position: relative;
      color: $purple100;
      display: flex;
      align-items: center;
      justify-content: center;
      .title-icon{
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      .button-close{
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
    .messages {
      margin-bottom: $spacing-xs;
    }
    .context {
      margin-bottom: 0;
      font: $sans-tight-14;
      font-weight: 300;
      color: $black600;
      text-align: left;
      .processing,
      .error {
        min-height: 492px;
        position: relative;
        .processing-text,
        .error-text {
          font: $sans-none-14;
          font-weight: 300;
          line-height: 25px;
        }
      }
      .clomn,
      .wclomn {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $spacing-xxs;
        .date{
          font: $sans-none-12;
          font-weight: 300;
          color: $black400;
        }
        .button{
          max-width: 56px;
          min-width: 56px;
          font-weight: 300;
          height: 28px;
          border: none;
          background: $purple100;
          border-radius: $radius8;
          &.is-disabled {
            background-color: $gray500;
            border-color: $gray500;
            color: $black400;
          }
        }
      }
      .clomn{
        justify-content: flex-end;
      }
      .summary,
      .todo {
        border-bottom: 1px solid $gray600;
        padding-bottom: $spacing-xxs;
        margin-bottom: $spacing-xxs;
        .read {
          font: $sans-none-16-bold;
          margin-bottom: $spacing-xxxs;
          color: $black700;
        }
        .text {
          font: $sans-tight-14;
          font-weight: 300;
          color: $black600;
          height: 218px;
          overflow-y: auto;
          white-space: pre-wrap;
          &::-webkit-scrollbar{
            width: 6px;
          }
          &::-webkit-scrollbar-track{
            background-color: none;
          }
          &::-webkit-scrollbar-thumb{
            border-radius: 20px;
            background-color: #9D9D9D;
          }
        }
      }
      .todo{
        margin-bottom: 0;
        .text{
          height: 126px;
        }
      }
    }
  }
  :deep(.loading) {
    position: absolute;
    height: 100%;
    background: none;
  }
}
</style>
