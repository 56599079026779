<template>
  <IconBase :size="size">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.07981 8.98339C2.81556 8.98339 2.58621 8.88728 2.39176 8.69506C2.19732 8.50284 2.1001 8.27176 2.1001 8.00182C2.1001 7.72287 2.19661 7.48895 2.38965 7.30006C2.58268 7.11117 2.81473 7.01672 3.0858 7.01672C3.34644 7.01672 3.57788 7.11058 3.7801 7.29829C3.98232 7.486 4.08343 7.71656 4.08343 7.98996C4.08343 8.26336 3.98223 8.49728 3.77981 8.69172C3.5774 8.88617 3.34407 8.98339 3.07981 8.98339ZM8.0102 8.98339C7.7368 8.98339 7.50288 8.88728 7.30843 8.69506C7.11399 8.50284 7.01676 8.27176 7.01676 8.00182C7.01676 7.72287 7.11287 7.48895 7.3051 7.30006C7.49732 7.11117 7.7284 7.01672 7.99833 7.01672C8.27729 7.01672 8.51121 7.11058 8.7001 7.29829C8.88899 7.486 8.98343 7.71656 8.98343 7.98996C8.98343 8.26336 8.88958 8.49728 8.70186 8.69172C8.51415 8.88617 8.2836 8.98339 8.0102 8.98339ZM12.915 8.98339C12.6392 8.98339 12.4039 8.88728 12.209 8.69506C12.0142 8.50284 11.9168 8.27176 11.9168 8.00182C11.9168 7.72287 12.015 7.48895 12.2114 7.30006C12.4078 7.11117 12.6439 7.01672 12.9197 7.01672C13.1955 7.01672 13.4307 7.11058 13.6251 7.29829C13.8195 7.486 13.9168 7.71656 13.9168 7.98996C13.9168 8.26336 13.8188 8.49728 13.6227 8.69172C13.4267 8.88617 13.1908 8.98339 12.915 8.98339Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';
export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
