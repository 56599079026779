<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="actions">
      <ButtonBack @click="backToList" />
    </div>
    <div class="conts-wrap">
      <div class="header">
        <h1 class="title">
          取引先登録
        </h1>
      </div>
      <div class="base">
        <div class="block-row">
          <FormBlock label="会社名">
            <FormInputText
              v-model="creatForm.company_name"
            />
          </FormBlock>
        </div>
        <div class="block-row">
          <FormBlock label="電話番号">
            <FormInputText
              v-model="creatForm.tel_number"
            />
          </FormBlock>
          <FormBlock
            label="表示名"
            info-text="電話番号の代わりに表示させる表示名になります。識別性が向上します。"
          >
            <FormInputText
              v-model="creatForm.display_name"
            />
          </FormBlock>
        </div>

        <ButtonBlock
          class="create-button-block"
        >
          <Button
            size="inline"
            type="normal"
            @click="$router.push({ name: 'VDXD00' })"
          >
            キャンセル
          </Button>
          <Button
            size="inline"
            type="primary"
            :disabled="!isDisabled"
            @click="onCreate"
          >
            新規登録
          </Button>
        </ButtonBlock>
      </div>
    </div>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonBack from '@/components/button/ButtonBack';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutMain from '@/components/layout/LayoutMain';
import { mapActions } from 'vuex';
import { postCreatSupplierApi } from '@/utils/ApiHelper';

export default {
  components: {
    LayoutMain,
    Button,
    ButtonBack,
    FormBlock,
    FormInputText,
    ButtonBlock,
    MessageBar,
  },
  data() {
    return {
      /**
       * creatForm
       */
      creatForm: {
        company_name: '',
        display_name: '',
        tel_number: '',
      },

      /**
       * 取引先登録画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  computed: {
    /**
     * 「新規登録」ボタンのdisabled制御
     */
    isDisabled() {
      return this.creatForm.company_name.length && this.creatForm.display_name.length && this.creatForm.tel_number.length;
    },
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setStoreMessages',
    ]),

    /**
     * 取引先一覧に戻る
     */
    backToList() {
      this.$router.push({ name: 'VDXD00' });
    },

    /**
     * 「新規登録」ボタンのクリックイベント
     */
    async onCreate() {
      this.startLoading();

      const { status, data } = await postCreatSupplierApi(this.creatForm);

      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDXD00' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.conts-wrap {
  padding: $spacing-xs $spacing-xlg;
}
.message-bar {
  margin: $spacing-xxs $spacing-xxs 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 47px;
  border-bottom: 1px solid $gray400;
  padding: 0 $spacing-xs;
}

.header {
  height: 42px;
  border-bottom: 2px solid $gray400;
  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}

.form-input-text{
  width: 346px;
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  align-items: center;

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
  }
  > .form-block {
    margin-right: $spacing-md;
    &:last-child {
      margin-right: 0;
    }
  }
  > .form-label {
    font: $sans-none-16-bold;
    color: $black700;
  }
}

.create-button-block {
  margin-top: $spacing-sm;
}
</style>
