<template>
  <div class="login-user-profile">
    <div class="block">
      <IconUser
        height="40px"
        width="40px"
      />
      <div class="info">
        <div
          v-if="baseName"
          class="base-name"
        >
          {{ baseName }}
        </div>
        <div class="user-name">
          {{ userName }}
        </div>
        <div class="email">
          {{ email }}
        </div>
      </div>
    </div>
    <ButtonBlock
      layout="right"
      class="mt-xs"
    >
      <Button
        size="small"
        type="normal"
        @click="logout"
      >
        ログアウト
      </Button>
    </ButtonBlock>
  </div>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import IconUser from '@/components/icons/IconUser.vue';
import { getIdToken, removeIdToken } from '@/utils/IdTokenManager';
import { Auth } from 'aws-amplify';
import { mapActions } from 'vuex';

export default {
  components: {
    IconUser,
    ButtonBlock,
    Button,
  },
  emits: ['close'],
  computed: {
    email() {
      return getIdToken('email');
    },
    userName() {
      return getIdToken('userFamilyName') + ' ' + getIdToken('userFirstName');
    },
    baseName() {
      return getIdToken('baseName');
    },
  },
  mounted() {
    window.addEventListener('click', this.closeDropDown);
  },
  unmounted() {
    window.removeEventListener('click', this.closeDropDown);
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),

    /**
     * このコンポーネント以外がクリックされたらcloseイベントを発火させる
     */
    closeDropDown(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.$emit('close');
      }
    },

    /**
     * ログアウトする
     */
    async logout() {
      this.startLoading();
      // ローカルストレージのIDトークンを削除
      removeIdToken();
      // Amplifyライブラリを使用してログアウト
      // SSOログインした場合はログイン画面にリダイレクトされる
      // リダイレクト先は環境変数「VUE_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT」で設定
      await Auth.signOut();
      // ログイン画面に遷移
      this.$router.push({ name: 'VDX050' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.login-user-profile {
  width: 320px;
  padding: $spacing-xxs;
  border-radius: $radius8;
  background: $white100;
  box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
  .block {
    display: flex;
    .info {
      margin-left: $spacing-xxs;
      text-align: left;
      width: calc(100% - 56px);
      overflow-wrap: break-word;
      .base-name {
        font: $sans-none-12;
        color: $black700;
        margin-bottom: $spacing-xxxxs;
      }
      .user-name {
        font: $sans-none-14;
        color: $black700;
        margin-bottom: $spacing-xxxxs;
      }
      .email {
        font: $sans-none-12;
        color: $black300;
      }
    }
  }
}
</style>
