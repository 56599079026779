<template>
  <button
    class="button-search-keyword"
    @click="$emit('click')"
  >
    <IconSearchKeyword />
    <span class="label">キーワード検索</span>
  </button>
</template>

<script>
import IconSearchKeyword from '@/components/icons/IconSearchKeyword';

export default {
  components: {
    IconSearchKeyword,
  },
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
.button-search-keyword {
  padding: 0 16px;
  margin: 0;
  height: 36px;
  background-color: $white100;
  border: 1px solid $black700;
  color: $black700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius6;

  &:hover {
    background-color: $gray100;
  }

  > .label {
    display: inline-block;
    margin-left: 8px;
    font: $sans-none-14;
    color: $black700;
  }
}
</style>
