<template>
  <textarea
    v-model="value"
    class="form-textarea"
    :class="{'is-focus': isFocus}"
    rows="3"
    @focus="inputFocus"
    @blur="inputBlur"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    inputFocus() {
      this.isFocus = true;
    },
    inputBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-textarea {
  box-sizing: border-box;
  width: 100%;
  padding: $spacing-xxxs;
  outline: none;
  border: 1px solid $gray600;
  border-radius: $radius4;
  font: $sans-normal-14;
  color: $black700;
  &.is-focus {
    border-color: $black400;
  }
}
</style>
