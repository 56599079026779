<template>
  <div
    class="chatlist-item"
    :class="['is-' + callingIncomingType]"
  >
    <div class="icon-area">
      <IconVoiceAreaBlack
        v-if="callingIncomingType === 'calling'"
        class="icon-originator"
        :size="40"
      />
    </div>
    <div class="inner-area">
      <div class="phone-number">
        {{ speechPhoneName?.length ? speechPhoneName : speechPhoneNumber }}
      </div>
      <div class="text-area">
        <div
          v-if="isEditing"
          class="editing"
        >
          <FormTextarea v-model="editText" />
          <div class="actions">
            <ButtonCancel @click="onCancel" />
            <ButtonSave @click="onSave" />
          </div>
        </div>
        <p
          v-if="!isEditing"
          class="text"
        >
          <template v-if="isOriginal">
            {{ originalText }}
          </template>
          <template v-if="!isOriginal">
            {{ voiceText }}
          </template>
        </p>
      </div>
      <div
        v-if="isEditable && (editLog || isShowEditButton) && !isEditing"
        class="actions"
      >
        <span
          v-if="editLog"
          class="edit-log"
        >{{ editLog }}</span>
        <ToggleParagraph
          v-if="editLog"
          v-model="isOriginal"
        />
        <ButtonEdit
          v-if="isShowEditButton"
          @click="onEdit"
        />
      </div>
    </div>
    <div class="icon-area">
      <IconVoiceAreaWhite
        v-if="callingIncomingType === 'incoming'"
        class="icon-recipient"
        :size="40"
      />
    </div>
  </div>
</template>

<script>
import ButtonCancel from '@/components/button/ButtonCancel';
import ButtonEdit from '@/components/button/ButtonEdit';
import ButtonSave from '@/components/button/ButtonSave';
import ToggleParagraph from '@/components/common/ToggleParagraph';
import FormTextarea from '@/components/form/FormTextarea';
import IconVoiceAreaBlack from '@/components/icons/IconVoiceAreaBlack';
import IconVoiceAreaWhite from '@/components/icons/IconVoiceAreaWhite';

export default {
  components: {
    IconVoiceAreaBlack,
    IconVoiceAreaWhite,
    ToggleParagraph,
    ButtonEdit,
    ButtonSave,
    ButtonCancel,
    FormTextarea,
  },
  props: {
    speechOrder: {
      type: Number,
      default: 0,
    },
    speechPhoneNumber: {
      type: String,
      default: '',
    },
    speechPhoneName: {
      type: String,
      default: '',
    },
    callingIncomingType: {
      type: String,
      default: '',
    },
    voiceText: {
      type: String,
      default: '',
    },
    originalText: {
      type: String,
      default: '',
    },
    editLog: {
      type: String,
      default: '',
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isShowEditButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'cancel', 'save'],
  data() {
    return {
      editText: '',
      isOriginal: false,
    };
  },
  watch: {
    isEditing(value) {
      if (value) {
        this.editText = this.voiceText;
      } else {
        this.editText = '';
      }
    },
  },
  methods: {
    onEdit() {
      // 同時に編集できるのは１箇所のみ
      // 編集箇所を親で管理するため、speechOrderをemitする
      this.$emit('edit', this.speechOrder);
    },
    onCancel() {
      this.isOriginal = false;
      this.$emit('cancel');
    },
    onSave() {
      this.isOriginal = false;
      // 比較して変更なしならイベント発生しない
      if (this.voiceText === this.editText) {
        this.$emit('cancel');
        return;
      }
      this.$emit('save', this.speechOrder, this.editText);
    },
    onShowEdited() {
      this.isOriginal = false;
    },
    onShowOriginal() {
      this.isOriginal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.chatlist-item {
  display: flex;

  > .icon-area {
    min-width: 40px;
  }

  > .inner-area {
    padding-left: $spacing-xxxxs;
    padding-right: $spacing-xxxxs;
    width: calc(100% - (40px * 2));

    > .phone-number {
      text-align: left;
      font: $sans-none-12-bold;
      color: $black400;
      padding-bottom: 4px;
      border-bottom: 1px solid $gray400;
      margin-bottom: $spacing-xxxxs;
    }

    > .text-area {
      > .editing {
        > .actions {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: $spacing-xxxxs;
          > .button-cancel {
            margin-right: $spacing-xxxxs;
          }
        }
      }
      > .text {
        text-align: left;
        font: $sans-normal-14;
        color: $black700;
        overflow-wrap: break-word;
      }
    }

    > .actions {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // margin-top: $spacing-xxxxs;
      margin-top: 4px;
      white-space: nowrap;
      > .edit-log {
        font: $sans-tight-12;
        color: $black400;
        margin-right: $spacing-xs;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      > .toggle-paragraph {
        margin-right: $spacing-xxs;
      }
    }
  }

  &.is-incoming {
    > .inner-area {
      > .phone-number {
        text-align: right;
      }
    }
  }
}
</style>
