<template>
  <LayoutMain @refresh="init">
    <MessageBar
      v-if="flashMessages.messages.length > 0"
      :type="flashMessages.type"
      :messages="flashMessages.messages"
      class="message-area"
    />
    <div class="header">
      <h1 class="header-title">
        取引先一覧
      </h1>
      <Button
        v-if="hasAuthCreateSupplierAPI"
        size="inline"
        @click="toCreatPage"
      >
        ＋取引先を追加
      </Button>
    </div>
    
    <TableListSupplier :records="items" />
  </LayoutMain>
</template>

<script>
import MessageBar from '@/components/common/MessageBar';
import LayoutMain from '@/components/layout/LayoutMain';
import Button from '@/components/button/Button.vue';
import TableListSupplier from '@/components/supplier/TableListSupplier';
import { getSupplierListApi, getUserRoleAuthsApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    MessageBar,
    LayoutMain,
    Button,
    TableListSupplier,
  },
  data() {
    return {
      items: [],
      /**
       * 取引先一覧画面に表示するフラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },
      /**
       * 権限一覧
       */
      authorizations: [],
    };
  },
  computed: {
    ...mapGetters([
      'storeMessages',
    ]),
    /**
     * 権限が無い場合
     * ・取引先を追加ボタンを非表示
     */
    hasAuthCreateSupplierAPI() {
      return hasAuthorization(this.authorizations, API_IDS.CREATE_SUPPLIER);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'clearStoreMessages',
    ]),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 権限取得
      await this.getUserRoleAuths();

      // 取引先一覧取得API
      await this.getSupplierList();

      // 取引先登録・詳細画面で格納したストアメッセージがあれば表示する
      this.flashMessages = this.storeMessages;
      this.clearStoreMessages();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.CREATE_SUPPLIER,
        ],
      });
      this.authorizations = data.data;
    },

    /**
     * 取引先一覧取得API
     */
    async getSupplierList() {
      const { data } = await getSupplierListApi();
      this.items = data.data;
    },

    /**
     * 取引先登録画面に遷移
     */
    toCreatPage() {
      this.$router.push({ name: 'VDXD10' });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
    padding: $spacing-sm $spacing-xs 0;
  }
}

.message-area {
  margin-bottom: $spacing-xs;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacing-xs;
  .header-title {
    font: $sans-snug-20-bold;
    color: $black700;
    height: 32px;
  }
}

.table-list-supplier {
  overflow: auto;
}
</style>
