<template>
  <div class="list-user">
    <div class="header">
      <div class="name">
        名前
      </div>
      <div class="address">
        メールアドレス
      </div>
      <div class="status">
        状態
      </div>
      <div class="authority">
        権限
      </div>
      <div class="date">
        登録日
      </div>
    </div>
    <ul
      v-if="items.length > 0"
      class="list"
    >
      <ListItemUser
        v-for="item of items"
        :key="item.user_id"
        :user-id="item.user_id"
        :user-family-name="item.user_family_name"
        :user-first-name="item.user_first_name"
        :user-email-address="item.user_email_address"
        :user-status-key="item.user_status_key"
        :user-status-value="item.user_status_value"
        :user-role-type-key="item.user_role_type_key"
        :user-role-type-value="item.user_role_type_value"
        :create-datetime="item.create_datetime"
        @click="onClick"
      />
    </ul>
    <p
      v-if="items.length === 0"
      class="not-found"
    >
      ユーザー登録はありません
    </p>
  </div>
</template>

<script>
import ListItemUser from '@/components/user/ListItemUser';

export default {
  components: {
    ListItemUser,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click'],
  methods: {
    onClick(userId) {
      this.$emit('click', userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-user {
  margin-top: $spacing-sm;

  > .header {
    display: flex;
    background-color: $white100;
    border-bottom: 2px solid $gray400;
    overflow-y: scroll;
    min-height: 31px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb{
      background: none;
    }

    > .name,
    .address,
    .status,
    .authority,
    .date {
      color: $black400;
      font: $sans-none-12-bold;
      height: 29px;
      line-height: 29px;
    }

    > .name {
      flex-basis: 21.25%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .address {
      flex-basis: 30.67%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .status,
    .authority {
      flex-basis: 18.35%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .date {
      flex-basis: 11.35%;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  > .list {
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      background-color: none;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: $gray600;
    }
  }

  > .not-found {
    margin: $spacing-xlg $spacing-md 0;
    font: $sans-normal-16;
    color: $black700;
  }

}
</style>
