<template>
  <IconBase :size="size">
    <svg
      :width="size"
      :height="size"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3346 10.9997C19.3346 6.3973 15.6037 2.66634 11.0013 2.66634C6.39893 2.66634 2.66797 6.3973 2.66797 10.9997C2.66797 15.602 6.39893 19.333 11.0013 19.333C15.6037 19.333 19.3346 15.602 19.3346 10.9997Z"
        fill="#CACACA"
        stroke="#CACACA"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0625 11.3125C11.3051 11.3125 12.3125 10.3051 12.3125 9.0625C12.3125 7.81986 11.3051 6.8125 10.0625 6.8125C8.81986 6.8125 7.8125 7.81986 7.8125 9.0625C7.8125 10.3051 8.81986 11.3125 10.0625 11.3125Z"
        fill="white"
      />
      <path
        d="M10.0625 12.4375C8.56063 12.4375 5.5625 13.1912 5.5625 14.6875V15.25C5.5625 15.5593 5.81562 15.8125 6.125 15.8125H14C14.3094 15.8125 14.5625 15.5593 14.5625 15.25V14.6875C14.5625 13.1912 11.5644 12.4375 10.0625 12.4375ZM13.7019 8.37059C13.8819 8.81497 13.8819 9.30997 13.7019 9.75434C13.595 10.0187 13.64 10.3168 13.8425 10.5193L13.8594 10.5362C14.1856 10.8625 14.7425 10.795 14.9563 10.3843C15.3837 9.56872 15.3837 8.61247 14.945 7.76309C14.7312 7.34684 14.1687 7.26809 13.8369 7.59997L13.8313 7.60559C13.64 7.80247 13.595 8.10622 13.7019 8.37059ZM15.7887 5.62559C15.5638 5.85059 15.53 6.19934 15.7156 6.45809C16.8237 7.99934 16.8181 10.0637 15.6988 11.6612C15.5188 11.9143 15.5581 12.2631 15.7775 12.4825L15.7944 12.4993C16.07 12.775 16.5369 12.7525 16.7731 12.4431C18.32 10.4518 18.3256 7.73497 16.7731 5.68184C16.5369 5.37247 16.0644 5.34997 15.7887 5.62559Z"
        fill="white"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: 22,
    },
  },
};
</script>
