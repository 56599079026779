<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="actions">
      <ButtonBack @click="backToList" />
    </div>
    <div class="conts-wrap">
      <div class="header">
        <h1 class="title">
          キーワード編集
        </h1>
        <Button
          v-if="hasAuthDeleteKeywordAPI"
          size="inline"
          type="delete"
          @click="$refs.modalDelete?.openModal()"
        >
          キーワードを削除
        </Button>
      </div>
      <div class="keyword">
        <div class="block-row">
          <FormBlock label="キーワード">
            <FormInputText
              v-model="keyword"
              :disabled="!hasAuthEditKeywordAPI"
            />
          </FormBlock>
        </div>

        <FormInputItems
          title="類似表現"
          button="類似表現を追加"
          class="list-keyword-add"
          :max-numbers="max_numbers"
          :items="similar_keywords"
          :watch-messages="errorMessages"
          :disabled="!hasAuthEditKeywordAPI"
          @onAdd="onAddKeyword"
          @onChange="onChangeKeyword"
          @onDelete="onDeleteKeyword"
        />

        <ButtonBlock
          v-if="hasAuthEditKeywordAPI"
          class="edit-button-block mt-sm"
        >
          <Button
            size="inline"
            type="normal"
            @click="$router.push({ name: 'VDX800' })"
          >
            キャンセル
          </Button>
          <Button
            size="inline"
            type="primary"
            @click="onEditSave"
          >
            変更を保存
          </Button>
        </ButtonBlock>
      </div>
    </div>
    <Modal ref="modalDelete">
      <div class="title">
        キーワードを削除
      </div>
      <p class="text">
        一度削除したキーワードは復元できません。本当に削除しますか？
      </p>
      <ButtonBlock
        class="edit-button-block"
      >
        <Button
          type="normal"
          size="inline"
          @click="$refs.modalDelete?.hideModal()"
        >
          キャンセル
        </Button>
        <Button
          type="delete"
          size="inline"
          @click="onDelete"
        >
          削除する
        </Button>
      </ButtonBlock>
    </Modal>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonBack from '@/components/button/ButtonBack';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutMain from '@/components/layout/LayoutMain';
import FormInputItems from '@/components/form/FormInputItems.vue';
import { getUserRoleAuthsApi, getKeywordApi, editKeywordApi, deletKeywordApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { mapActions } from 'vuex';
export default {
  components: {
    LayoutMain,
    Button,
    ButtonBack,
    FormBlock,
    FormInputText,
    Modal,
    ButtonBlock,
    MessageBar,
    FormInputItems,
  },
  data() {
    return {
      /**
       * キーワード編集情報
       */
      keyword: '',
      similar_keywords: [''],
      max_numbers: 30,

      /**
       * 権限一覧
       */
      authorizations: [],

      /**
       * キーワード編集画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  computed: {
    /**
     * キーワード削除APIの権限チェック
     *
     * 「キーワードを削除」の非表示
     */
    hasAuthDeleteKeywordAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DELETE_KEYWORD);
    },
    /**
     * キーワード編集APIの権限チェック
     *
     * 権限が無い場合各種非表示
     */
    hasAuthEditKeywordAPI() {
      return hasAuthorization(this.authorizations, API_IDS.EDIT_KEYWORD);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setUserRoleAuths',
      'setStoreMessages',
    ]),

    /**
     * 一覧に戻る
     */
    backToList() {
      this.$router.push({ name: 'VDX800' });
    },

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 権限取得
      await this.getUserRoleAuths();
      // キーワード詳細取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.DELETE_KEYWORD,
          API_IDS.EDIT_KEYWORD,
        ],
      });
      this.authorizations = data.data;

      // 権限をストアに保存
      this.setUserRoleAuths({
        userRoleAuths: this.authorizations,
      });
    },

    /**
     * キーワード詳細取得
     */
    async fetchData() {
      const { status, data } = await getKeywordApi({ id: this.$route.params.id });

      if (status == 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.keyword = data.keyword;
      this.similar_keywords = data.array_similar_keyword;
    },

    /**
     * 類似表現の「類似表現の追加」ボタンのクリックイベント
     */
    onAddKeyword() {
      this.similar_keywords.unshift('');
    },

    /**
     * 類似表現入力項目のチェンジイベント
     */
    onChangeKeyword(textValue, index) {
      this.similar_keywords[index] = textValue;
    },

    /**
     * 類似表現の「削除」ボタンのクリックイベント
     */
    onDeleteKeyword(index) {
      this.similar_keywords.splice(index, 1);
    },

    /**
     * 「変更を保存」ボタンのクリックイベント
     */
    async onEditSave() {
      this.startLoading();

      // 配列内の空の値を削除
      const result_similar_keywords = this.similar_keywords.filter(item => item);

      const { status, data } = await editKeywordApi(
        {
          id: this.$route.params.id, 
        },
        {
          keyword: this.keyword,
          similar_keywords: result_similar_keywords,
        },
      );

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX800' });
      this.stopLoading();
    },

    /**
     * 「削除する」ボタンのクリックイベント
     */
    async onDelete() {
      this.startLoading();
      const { status, data } = await deletKeywordApi({ id: this.$route.params.id });

      if (status == 400) {
        this.$refs.modalDelete?.hideModal();
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX800' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>

.conts-wrap {
  padding: $spacing-sm $spacing-xs;
}
.message-bar {
  margin: $spacing-xxs $spacing-xxs 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 47px;
  border-bottom: 1px solid $gray400;
  padding: 0 $spacing-xs;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 42px;
  border-bottom: 2px solid $gray400;

  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}
.list-keyword-add{
  margin-top: $spacing-sm;
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  align-items: center;

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
  }
  > .add-button-block{
    margin-left: $spacing-xs;
  }
  > .form-label {
    font: $sans-none-16-bold;
    color: $black700;
  }
  .form-input-text{
  width: 346px;
  }
}
</style>
