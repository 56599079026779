<template>
  <label
    class="form-select-label"
    :class="[
      {
        'is-focus': isFocus,
      },
    ]"
  >
    <select
      v-model="value"
      class="form-select"
      @focus="inputFocus"
      @blur="inputBlur"
      @click="inputFocus"
    >
      <option
        v-for="(option, i) in options"
        :key="i"
        :value="option.isAsc"
      >
        {{ option.label }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  props: {
    isAsc: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change', 'sortAsc', 'sortDesc'],
  data() {
    return {
      isFocus: false,
      options:[
        { isAsc: false, label: '新しい順' },
        { isAsc: true, label: '古い順' },
      ],
    };
  },
  computed: {
    value: {
      get() {
        return this.isAsc;
      },
      set(value) {
        this.$emit('change', value);
        if (value) {
          this.$emit('sortAsc', value);
        } else {
          this.$emit('sortDesc', value);
        }
        this.inputBlur();
      },
    },
  },
  methods: {
    inputFocus() {
      this.isFocus = true;
    },
    inputBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-select-label {
  display: inline-block;
  position: relative;
  width: 88px;

  &.is-focus {
    .form-select {
      border-color: $black400;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $spacing-xxxxs;
    width: 16px;
    height: 16px;
    background: url(../../assets/images/icon-arrow-select.svg) 0 0 no-repeat;
    pointer-events: none;
  }
}

.form-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  font: $sans-none-12;
  height: 28px;
  line-height: 100%;
  padding: 0 $spacing-xxxxs;
  background: $white100;
  box-sizing: border-box;
  border: 1px solid $gray600;
  outline: none;
  border-radius: $radius8;
  color: $black700;
  appearance: none;
  -webkit-appearance: none;
}
</style>
