<template>
  <div class="activate-code">
    <div class="container">
      <input
        ref="form1"
        v-model="value1"
        v-focus
        type="text"
        class="input"
        :class="{ 'is-focus': isFocus1 }"
        maxlength="1"
        @focus="inputFocus1"
        @blur="inputBlur1"
      >
      <input
        ref="form2"
        v-model="value2"
        type="text"
        class="input"
        :class="{ 'is-focus': isFocus2 }"
        maxlength="1"
        @focus="inputFocus2"
        @blur="inputBlur2"
      >
      <input
        ref="form3"
        v-model="value3"
        type="text"
        class="input"
        :class="{ 'is-focus': isFocus3 }"
        maxlength="1"
        @focus="inputFocus3"
        @blur="inputBlur3"
      >
    </div>
    <div class="container">
      <input
        ref="form4"
        v-model="value4"
        type="text"
        class="input"
        :class="{ 'is-focus': isFocus4 }"
        maxlength="1"
        @focus="inputFocus4"
        @blur="inputBlur4"
      >
      <input
        ref="form5"
        v-model="value5"
        type="text"
        class="input"
        :class="{ 'is-focus': isFocus5 }"
        maxlength="1"
        @focus="inputFocus5"
        @blur="inputBlur5"
        @keyup="validation5"
      >
      <input
        ref="form6"
        v-model="value6"
        type="text"
        class="input"
        :class="{ 'is-focus': isFocus6 }"
        maxlength="1"
        @focus="inputFocus6"
        @blur="inputBlur6"
      >
    </div>
  </div>
</template>

<script>
export default {
  directives: {
    focus: {
      mounted(el) {
        el.focus();
      },
    },
  },
  props: {
    modelValue1: {
      type: String,
      default: '',
    },
    modelValue2: {
      type: String,
      default: '',
    },
    modelValue3: {
      type: String,
      default: '',
    },
    modelValue4: {
      type: String,
      default: '',
    },
    modelValue5: {
      type: String,
      default: '',
    },
    modelValue6: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue1',
    'update:modelValue2',
    'update:modelValue3',
    'update:modelValue4',
    'update:modelValue5',
    'update:modelValue6',
  ],
  data() {
    return {
      isFocus1: false,
      isFocus2: false,
      isFocus3: false,
      isFocus4: false,
      isFocus5: false,
      isFocus6: false,
    };
  },
  computed: {
    value1: {
      get() {
        return this.modelValue1;
      },
      set(value) {
        // value = value.replace(/[^0-9a-z]/gi, '');
        this.$emit('update:modelValue1', value);
      },
    },
    value2: {
      get() {
        return this.modelValue2;
      },
      set(value) {
        // value = value.replace(/[^0-9a-z]/gi, '');
        this.$emit('update:modelValue2', value);
      },
    },
    value3: {
      get() {
        return this.modelValue3;
      },
      set(value) {
        // value = value.replace(/[^0-あ9a-z]/gi, '');
        this.$emit('update:modelValue3', value);
      },
    },
    value4: {
      get() {
        return this.modelValue4;
      },
      set(value) {
        // value = value.replace(/[^0-9a-z]/gi, '');
        this.$emit('update:modelValue4', value);
      },
    },
    value5: {
      get() {
        return this.modelValue5;
      },
      set(value) {
        // value = value.replace(/[^0-9a-z]/gi, '');
        this.$emit('update:modelValue5', value);
      },
    },
    value6: {
      get() {
        return this.modelValue6;
      },
      set(value) {
        // value = value.replace(/[^0-9a-z]/gi, '');
        this.$emit('update:modelValue6', value);
      },
    },
  },
  watch: {
    modelValue1: function(v) {
      if (v.length > 0) {
        this.$refs.form2.focus();
      }
    },
    modelValue2: function(v) {
      if (v.length > 0) {
        this.$refs.form3.focus();
      } else if (v.length === 0) {
        this.$refs.form1.focus();
      }
    },
    modelValue3: function(v) {
      if (v.length > 0) {
        this.$refs.form4.focus();
      } else if (v.length === 0) {
        this.$refs.form2.focus();
      }
    },
    modelValue4: function(v) {
      if (v.length > 0) {
        this.$refs.form5.focus();
      } else if (v.length === 0) {
        this.$refs.form3.focus();
      }
    },
    modelValue5: function(v) {
      if (v.length > 0) {
        this.$refs.form6.focus();
      } else if (v.length === 0) {
        this.$refs.form4.focus();
      }
    },
    modelValue6: function(v) {
      if (v.length === 0) {
        this.$refs.form5.focus();
      }
    },
  },
  methods: {
    inputFocus1() {
      this.isFocus1 = true;
    },
    inputBlur1() {
      this.isFocus1 = false;
    },
    inputFocus2() {
      this.isFocus2 = true;
    },
    inputBlur2() {
      this.isFocus2 = false;
    },
    inputFocus3() {
      this.isFocus3 = true;
    },
    inputBlur3() {
      this.isFocus3 = false;
    },
    inputFocus4() {
      this.isFocus4 = true;
    },
    inputBlur4() {
      this.isFocus4 = false;
    },
    inputFocus5() {
      this.isFocus5 = true;
    },
    inputBlur5() {
      this.isFocus5 = false;
    },
    inputFocus6() {
      this.isFocus6 = true;
    },
    inputBlur6() {
      this.isFocus6 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.activate-code {
  display: flex;
  justify-content: space-between;

  > .container {
    &:nth-child(2) {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 24px;
        height: 2px;
        background-color: $black700;
        top: 50%;
        right: 100%;
        transform: translate(-50%);
      }
    }

    > .input {
      width: 40px;
      height: 64px;
      padding: 0;
      box-sizing: border-box;
      font: $sans-none-28-bold;
      text-align: center;
      border-radius: $radius4;
      border: 1px solid $gray600;
      outline: none;
      color: $black700;

      &:not(:first-child) {
        margin-left: $spacing-xxs;
      }

      &.is-focus {
        border-color: $black400;
      }
    }
  }
}
</style>
