<template>
  <Modal
    ref="modalVoiceDelete"
    data-test="modal-voice-delete"
  >
    <MessageBar
      v-if="errors.length > 0"
      type="error"
      :messages="errors"
    />
    <div class="title">
      ゴミ箱に入れる
    </div>
    <p class="text">
      ゴミ箱に入れた記録は30日後に削除されます。
    </p>
    <p class="note">
      ※分割ファイルの場合はすべてのパートが削除されます
    </p>
    <ButtonBlock>
      <Button
        type="primary"
        data-test="ok-button"
        @click="$emit('delete')"
      >
        OK
      </Button>
    </ButtonBlock>
    <ButtonBlock>
      <Button
        type="normal"
        data-test="cancel-button"
        @click="hideModal"
      >
        キャンセル
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    MessageBar,
    Modal,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['delete'],
  methods: {
    openModal() {
      this.$refs.modalVoiceDelete.openModal();
    },
    hideModal() {
      this.$refs.modalVoiceDelete.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  font: $sans-tight-14;
  color: $black700;
  margin-top: $spacing-xxs;
  padding-left: 1em;
  text-indent: -1em;
}
</style>
