<template>
  <Modal
    ref="modalDownloadDataDelete"
  >
    <div class="title">
      ダウンロードデータの削除
    </div>
    <p class="text">
      一度削除したデータは復元できません。本当に削除しますか？
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="hideModal"
      >
        キャンセル
      </Button>
      <Button
        size="inline"
        type="delete"
        @click="$emit('delete')"
      >
        削除する
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    Modal,
  },
  emits: ['delete'],
  methods: {
    openModal() {
      this.$refs.modalDownloadDataDelete.openModal();
    },
    hideModal() {
      this.$refs.modalDownloadDataDelete.hideModal();
    },
  },
};
</script>
