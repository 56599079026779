<template>
  <Modal
    ref="modalDownloadConfirmCreation"
  >
    <div class="title">
      ダウンロードデータ作成
    </div>
    <p class="text">
      {{ msg }}
    </p>
    <p class="note mt-xs">
      ※一覧からダウンロードする場合は音声データのみになります。<br>
    </p>
    <p class="note">
      ※ダウンロードデータ作成には時間がかかる場合があります。
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="hideModal"
      >
        キャンセル
      </Button>
      <Button
        size="inline"
        type="primary"
        @click="onCreate"
      >
        作成する
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    Modal,
  },
  props: {
    msg: {
      type: String,
      default: '',
    },
  },
  emits: ['create'],
  methods: {
    openModal() {
      this.$refs.modalDownloadConfirmCreation.openModal();
    },
    hideModal() {
      this.$refs.modalDownloadConfirmCreation.hideModal();
    },
    onCreate() {
      this.$emit('create');
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  font: $sans-tight-14;
  color: $black700;
  padding-left: 1em;
  text-indent: -1em;
}
</style>
