<template>
  <div class="voice-checkbox">
    <input
      :id="label"
      v-model="inputValue"
      type="checkbox"
      :value="value"
      class="checkbox"
      :class="{ hasHoverAction }"
      :disabled="disabled"
      data-test="voice-checkbox-input"
    >
    <label
      v-if="label"
      :for="label"
      class="label"
    >{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Boolean, Array],
      default: undefined,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * チェックボックスのホバーアクションの有無
     */
    hasHoverAction: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.voice-checkbox {
  height: 18px;
  width: 18px;
  position: relative;
  // top: 15px;
  // left: 16px;

  // チェックボックスのリセットCSS
  > .checkbox {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  > .checkbox {
    cursor: pointer;
    width: 36px; // チェックボックスの横幅 ×2
    height: 36px; // チェックボックスの縦幅 ×2
    border-radius: 50%;
    position: absolute;
    top: -9px;
    left: -9px;
    &:disabled {
      cursor: not-allowed;
    }
    &.hasHoverAction {
      &:not(:disabled):hover {
        background-color: $gray400;
      }
    }
  }
  > .checkbox::before,
  > .checkbox::after {
    content: "";
    display: block;
    position: absolute;
  }
  > .checkbox::before {
    box-sizing: border-box;
    background-color: $white100;
    border: 1px solid $gray600;
    border-radius: $radius4;
    width: 18px; // チェックボックスの横幅
    height: 18px; // チェックボックスの縦幅
    transform: translateY(-50%);
    top: 50%;
    left: 9px;
  }
  > .checkbox:checked::before {
    background-image: url(../../assets/images/icon-checkbox-checked.svg);
    border: none;
  }
  > .checkbox:disabled::before {
    background-color: $gray500;
    border-color: $gray500;
  }

  > .label {
    padding-left: 18px + 8px; // チェックボックスの横幅 + 余白8px
    cursor: pointer;
    font: $sans-none-14;
    color: $black700;
  }
}
</style>
