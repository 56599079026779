<template>
  <LayoutEntry>
    <template #textBar>
      <p class="text-bar">
        新機能が追加されました。詳しくは<a href="/kvv-document.zip">使い方</a>内リリース情報をご覧ください。
      </p>
    </template>
    <MessageBar
      v-if="flashMessages.messages.length > 0"
      :type="flashMessages.type"
      :messages="flashMessages.messages"
    />
    <MessageBar
      v-if="errorMessages.length > 0"
      type="error"
      :messages="errorMessages"
    />
    <div class="title">
      ログイン
    </div>

    <template v-if="!isNextStep">
      <FormBlock label="メールアドレス">
        <FormInputText
          v-model="emailAddress"
          size="width-320"
        />
      </FormBlock>
      <ButtonBlock>
        <Button
          :disabled="!emailAddress"
          @click="nextStep"
        >
          次へ
        </Button>
      </ButtonBlock>
    </template>

    <template v-if="isNextStep">
      <FormBlock label="メールアドレス">
        <FormInputText
          v-model="emailAddress"
          size="width-320"
          :disabled="true"
        />
      </FormBlock>
      <FormBlock label="パスワード">
        <FormInputText
          v-model="password"
          size="width-320"
          type="password"
        />
      </FormBlock>
      <p class="text form-text">
        英数字と大文字小文字組み合わせ８桁以上です
      </p>
      <p class="text">
        パスワードを忘れた方は<a @click="toVDX060">こちら</a>
      </p>
      <ButtonBlock>
        <Button
          :disabled="!password"
          @click="login"
        >
          ログイン
        </Button>
      </ButtonBlock>
      <ButtonBlock class="back-button">
        <Button
          type="text"
          @click="backStep"
        >
          戻る
        </Button>
      </ButtonBlock>
    </template>
  </LayoutEntry>
</template>

<script>
import Button from '@/components/button/Button.vue';
import ButtonBlock from '@/components/button/ButtonBlock.vue';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutEntry from '@/components/layout/LayoutEntry';
import { loginSettingAuthApi } from '@/utils/ApiHelper';
import { COGNITO_CONFIG, ERROR_MESSAGES } from '@/utils/Constants';
import { hasIdToken, setIdToken } from '@/utils/IdTokenManager';
import { Amplify, Auth } from 'aws-amplify';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LayoutEntry,
    MessageBar,
    FormBlock,
    FormInputText,
    ButtonBlock,
    Button,
  },
  data() {
    return {
      /**
       * ストアメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },

      /**
       * エラーメッセージ
       */
      errorMessages: [],

      /**
       * メールアドレス
       */
      emailAddress: '',

      /**
       * パスワード
       */
      password: '',

      /**
       * パスワードを入力する段階どうか
       *
       * 表示内容の切り替えに使用
       */
      isNextStep: false,
    };
  },
  computed: {
    ...mapGetters(['storeMessages']),
  },
  mounted() {
    // ログイン済みの場合はホーム画面にリダイレクト
    if (hasIdToken()) {
      this.$router.push({ name: 'VDX100' });
      return;
    }

    // ストアメッセージを表示する
    this.flashMessages = this.storeMessages;
    this.clearStoreMessages();

    // IDトークンのエラーメッセージが保存されている場合
    // フラッシュメッセージに格納してローカルストレージから削除する
    if (localStorage.getItem('idTokenErrorMessage')) {
      this.flashMessages = {
        type: 'error',
        messages: [localStorage.getItem('idTokenErrorMessage')],
      };
      localStorage.removeItem('idTokenErrorMessage');
    }

    // Cognito接続設定
    Amplify.configure(COGNITO_CONFIG);
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'clearStoreMessages']),

    /**
     * 次へボタン押下時に実行される処理
     */
    async nextStep() {
      this.startLoading();

      // メッセージクリア
      this.clearMessages();

      // ログイン認証設定取得APIを呼び出す
      const { status, data } = await loginSettingAuthApi(
        { email: this.emailAddress },
      );

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // IdP種別
      const idpType = data.idp_type;
      // IDプロバイダー名
      const samlIdpName = data.saml_idp_name;

      if (idpType === 'KVV') {
        // IdP種別がKVVの場合はパスワード入力へ進む
        this.password = '';
        this.isNextStep = true;
        this.stopLoading();
      } else {
        // その他の場合は
        // Amplifyライブラリを使用してSAMLログイン試行
        // IDプロバイダーへリダイレクト
        Auth.federatedSignIn({ provider: samlIdpName });
      }
    },

    /**
     *  "パスワードを忘れた方はこちら" テキストリンク処理
     */
    toVDX060() {
      this.$router.push({ name: 'VDX060' });
    },

    /**
     * ログインボタン押下時に実行される処理
     */
    async login() {
      this.startLoading();

      // メッセージクリア
      this.clearMessages();

      try {
        // Amplifyライブラリを使用してログイン
        const data = await Auth.signIn(this.emailAddress, this.password);

        // IDトークンをローカルストレージに保存
        const idToken = data.signInUserSession.idToken;
        setIdToken({
          jwtToken: idToken.jwtToken,
          email: idToken.payload['email'],
          voiceDxCode: idToken.payload['custom:voice-dx-code'],
          userFamilyName: idToken.payload['custom:user-family-name'],
          userFirstName: idToken.payload['custom:user-first-name'],
          userRole: idToken.payload['custom:user-role'],
          userRoleName: idToken.payload['custom:user-role-name'],
          baseName: idToken.payload['custom:base-name'],
          useStt: idToken.payload['custom:use-stt'],
          useArchive: idToken.payload['custom:use-archive'],
        });

        // 記録一覧画面に遷移
        this.$router.push({ name: 'VDX100' });

      } catch (error) {
        // ログインに失敗した場合
        if (error.code === 'NotAuthorizedException'
            || error.code === 'InvalidParameterException') {
          this.errorMessages = [ERROR_MESSAGES.UNAUTHORIZED_LOGIN];
        } else if (error.code === 'UserLambdaValidationException') {
          const result = error.message.match(/\[BusinessException:(.+)\]/);
          if (result) {
            const message = result[1];
            this.errorMessages = [message];
          } else {
            this.$router.push({ name: 'VDX910' });
          }
        } else {
          this.$router.push({ name: 'VDX910' });
        }
      }

      this.stopLoading();
    },

    /**
     * 戻るボタン押下時に実行される処理
     */
    backStep() {
      // メッセージクリア
      this.clearMessages();
      // メールアドレス入力に戻る
      this.isNextStep = false;
    },

    clearMessages() {
      this.flashMessages = {
        type: '',
        messages: [],
      };
      this.errorMessages = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {

  :deep(.text-bar) {
    text-align: center;
    background: $primary300;
    font: $sans-none-14;
    color: $black700;
    line-height: 38px;
    > a {
      text-decoration: underline;
      color: $black700;
    }
  }
  > :deep(.container) {
    width: 386px;
    > .button-block {
      &.back-button {
        margin-top: $spacing-xxs;
      }
    }
    > .text a {
      color: $primary500;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
