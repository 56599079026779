<template>
  <div class="list-keyword">
    <div
      v-if="items.length > 0"
      class="header"
    >
      <div class="keyword">
        キーワード
      </div>
      <div class="similar-keywords">
        類似表現
      </div>
      <div class="create-date">
        登録日
      </div>
    </div>
    <ul
      v-if="items.length > 0"
      id="list"
      ref="scrollable"
      class="list"
    >
      <ListItemKeyword
        v-for="item of items"
        :key="item.keyword_id"
        :keyword-id="item.keyword_id"
        :keyword="item.keyword"
        :similar-keywords="item.similar_keywords"
        :create-date="item.create_date"
        @click="onClick"
      />
    </ul>
    <p
      v-if="items.length === 0"
      class="not-found"
    >
      キーワードはありません
    </p>
  </div>
</template>

<script>
import ListItemKeyword from '@/components/keyword/ListItemKeyword';

export default {
  components: {
    ListItemKeyword,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click'],
  watch: {
    /**
     * propsのitemsが更新されたら実行される
     */
    'items' () {
      // リストのスクロールを最上部に戻す
      const domList = document.getElementById('list');
      if(domList) {
        domList.scrollTop = 0;
      }
    },
  },
  methods: {
    onClick(keywordId) {
      this.$emit('click', keywordId);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-keyword {
  display: flex;
  flex-direction: column;
  overflow: auto;

  > .header {
    display: flex;
    background-color: $white100;
    border-bottom: 2px solid $gray400;
    overflow-y: scroll;
    min-height: 31px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb{
      background: none;
    }
    > .keyword,
    .similar-keywords,
    .create-date {
      color: $black400;
      font: $sans-none-12-bold;
      height: 29px;
      line-height: 29px;
    }

    > .keyword,
    .similar-keywords,
    .create-date {
      flex-basis: 16%;
      flex-grow: 0;
      flex-shrink: 0;
    }
    > .keyword{
        flex-basis: 306px;
    }
    > .similar-keywords{
        flex-basis: calc(100% - 408px);
    }
    > .create-date {
        flex-basis: 102px;
    }
  }

  > .list {
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      background-color: none;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: $gray600;
    }
  }

  > .not-found {
    margin: 0 $spacing-xs;
    font: $sans-normal-16;
    color: $black700;
  }
}
</style>
