<template>
  <div class="navigation-top">
    <ul class="list">
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDX100'
          || $route.name === 'VDX110'
          || $route.name === 'VDX300'
          || $route.name === 'VDX200'
          || $route.name === 'VDX210'
        }"
        @click="click('VDX100')"
      >
        記録一覧
      </li>
      <li
        v-if="isUseStt"
        class="list-item"
        :class="{ active: $route.name === 'VDXA00' || $route.name === 'VDXA10' }"
        @click="click('VDXA00')"
      >
        通知
      </li>
      <li
        class="list-item"
        :class="{ active: $route.name === 'VDX500'
          || $route.name === 'VDX510'
          || $route.name === 'VDX400'
          || $route.name === 'VDX410'
          || $route.name === 'VDX700'
          || $route.name === 'VDX800'
          || $route.name === 'VDX810'
          || $route.name === 'VDX820'
          || $route.name === 'VDX600'
          || $route.name === 'VDX610'
          || $route.name === 'VDXC00'
          || $route.name === 'VDXB00'
          || $route.name === 'VDXD00'
          || $route.name === 'VDXD10'
          || $route.name === 'VDXD20'
        }"
        @click="click('VDX500')"
      >
        設定管理
      </li>
    </ul>
    <div class="number">
      {{ `サービス契約管理番号 : ${voiceDxCode}` }}
    </div>
  </div>
</template>

<script>
import { getIdToken } from '@/utils/IdTokenManager';

export default {
  emits: ['refresh'],
  computed: {
    voiceDxCode() {
      return getIdToken('voiceDxCode');
    },
    isUseStt() {
      return getIdToken('useStt') === 'true';
    },
  },
  methods: {
    click(routeName) {
      // 同画面の場合はrefreshイベントを発生させる
      if (this.$route.name === routeName) {
        this.$emit('refresh');
        return;
      }
      this.$router.push({ name: routeName });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-top {
  height: 57px;
  width: 100%;
  min-width: 1280px;
  border-bottom: 1px solid $gray500;
  background-color: $gray300;
  padding: 0 $spacing-sm;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .list {
    display: flex;

    .list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      width: 124px;
      font: $sans-none-14;
      color: $black700;
      cursor: pointer;
      position: relative;

      &.active {
        font: $sans-none-14-bold;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 3px;
          width: 108px;
          background: $primary500;
          border-radius: 5px 5px 0px 0px;
        }
      }
    }
  }

  .number {
    font: $sans-none-12;
    color: $black600;
  }
}
</style>
