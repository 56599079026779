<template>
  <LayoutMain @refresh="init">
    <h1 class="heading">
      過去の利用状況 (各月の利用量の確定情報)
    </h1>
    <p class="mt-xs mb-xxxxs">
      ・料金の目安となる利用量（利用量の見方の詳細については、<a href="/kvv-document.zip">ご利用ガイド</a>をご覧ください）
    </p>
    <TableUsage
      :headers="headers"
      :usages="usages"
      class="mb-xs"
    />
    <p>過去のご契約月の月末のご利用量を表内に示しており、本利用量が請求対象となります。</p>
    <p>※3 ストレージ(GB)：ストレージに保存中の月末時点のデータ総量を月の日数で割った値</p>
    <p>※4 音声テキスト化(時間)：毎月１日から新しくテキスト化した記録の月末時点の総時間</p>
  </LayoutMain>
</template>

<script>
import LayoutMain from '@/components/layout/LayoutMain';
import TableUsage from '@/components/table/TableUsage';
import { getUsagePastApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    TableUsage,
  },
  data() {
    return {
      headers: ['年月', 'ストレージ(GB) ※3', '音声テキスト化(時間) ※4'],
      usages: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 過去の利用状況を取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * 過去の利用状況の取得
     */
    async fetchData() {
      // 過去の利用状況取得APIの呼び出し
      const { data } = await getUsagePastApi();
      this.usages = data.usage;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    padding: $spacing-xs;
    overflow-y: scroll;
    color: $black700;
    font: $sans-normal-14;
  }
}
.heading {
  font: $sans-snug-20-bold;
}
</style>
