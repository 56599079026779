<template>
  <div class="subject-area">
    <div class="datetime">
      {{ callStartDatetime }}&nbsp;-&nbsp;{{ callEndDatetime }}
    </div>
    <div :class="['subject-area-inner', {'is-ai-generation': isAiGeneration}]">
      <div class="record-details">
        <IconVoiceAreaBlack />
        <span class="supplement">from:</span>
        <div class="number">
          <span v-if="callingName?.length">{{ callingName }}</span>
          <span>{{ callingNumber }}</span>
        </div>
        <IconVoiceAreaWhite />
        <span class="supplement">to:</span>
        <div class="number">
          <span v-if="incomingName?.length">{{ incomingName }}</span>
          <span>{{ incomingNumber }}</span>
        </div>
        <div class="time">
          {{ callingTime }}
        </div>
      </div>
      <div
        v-if="isAiGeneration"
        class="ai-generation"
      >
        <button
          class="generation"
          @click="onAiGeneration"
        >
          <IconStars :size="12" />会話要約とToDo
        </button>
        <button
          class="information"
          @click="onAiInformation"
        >
          <IconInformation />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconStars from '@/components/icons/IconStars';
import IconInformation from '@/components/icons/IconInformation';
import IconVoiceAreaBlack from '@/components/icons/IconVoiceAreaBlack';
import IconVoiceAreaWhite from '@/components/icons/IconVoiceAreaWhite';

export default {
  components: {
    IconStars,
    IconInformation,
    IconVoiceAreaBlack,
    IconVoiceAreaWhite,
  },
  props: {
    callStartDatetime: {
      type: String,
      default: '',
    },
    callEndDatetime: {
      type: String,
      default: '',
    },
    callingNumber: {
      type: String,
      default: '',
    },
    incomingNumber: {
      type: String,
      default: '',
    },
    callingName: {
      type: String,
      default: '',
    },
    incomingName: {
      type: String,
      default: '',
    },
    callingTime: {
      type: String,
      default: '',
    },
    isAiGeneration: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['onAiGeneration', 'onAiInformation'],
  methods: {
    onAiGeneration() {
      this.$emit('onAiGeneration');
    },
    onAiInformation() {
      this.$emit('onAiInformation');
    },
  },
};
</script>

<style lang="scss" scoped>
.subject-area {
  > .datetime {
    font: $sans-none-12;
    color: $black700;
    text-align: right;
    width: 100%;
  }
  > .subject-area-inner{
    padding-bottom: $spacing-xxs;
    border-bottom: 2px solid $gray400;
    margin-top: $spacing-xxs;
    height: 48px;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &.is-ai-generation > .record-details {
      width: calc(100% - 217px);
    }
    > .record-details {
      display: flex;
      align-items: center;
      position: relative;

      > :nth-child(4) {
        margin-left: $spacing-xxs;
      }

      > .supplement {
        margin-left: 4px;
        font: $sans-none-12;
        color: $black700;
      }

      > .number {
        margin-left: 4px;
        font: $sans-none-14-bold;
        color: $black700;
        display: flex;
        align-items: center;
        > span {
          display: block;
          &:nth-child(2) {
            font: $sans-none-10;
            color: $black200;
            margin-left: 4px;
          }
        }
      }

      > .time {
        font: $sans-none-14;
        color: $black700;
        margin-left: $spacing-xxs;
      }
    }
    > .ai-generation {
      width: 217px;
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      .generation {
        display: block;
        border-radius: $radius8;
        font: $sans-none-12-bold;
        color: $white100;
        background: $purple100;
        border-color: $purple100;
        padding: $spacing-xxxxs 10px;
        svg{
          display: inline-block;
          margin-right: 6px;
          fill: $white100;
        }
      }
      .information{
        display: block;
        cursor: pointer;
        margin-left: $spacing-xxxxs;
        padding: 0;
      }
    }
  }
}

</style>
