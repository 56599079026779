<template>
  <div class="subject-area">
    <div class="datetime">
      {{ callStartDatetime }}&nbsp;-&nbsp;{{ callEndDatetime }}
    </div>
    <div class="record-details">
      <IconCalling />
      <span class="supplement">発信:</span>
      <div class="number">
        <span v-if="callingName?.length">{{ callingName }}</span>
        <span>{{ callingNumber }}</span>
      </div>
      <IconIncoming />
      <span class="supplement">着信:</span>
      <div class="number">
        <span v-if="incomingName?.length">{{ incomingName }}</span>
        <span>{{ incomingNumber }}</span>
      </div>
      <div class="time">
        {{ callingTime }}
      </div>
    </div>
  </div>
</template>

<script>
import IconCalling from '@/components/icons/IconCalling';
import IconIncoming from '@/components/icons/IconIncoming';

export default {
  components: {
    IconCalling,
    IconIncoming,
  },
  props: {
    callStartDatetime: {
      type: String,
      default: '',
    },
    callEndDatetime: {
      type: String,
      default: '',
    },
    callingNumber: {
      type: String,
      default: '',
    },
    incomingNumber: {
      type: String,
      default: '',
    },
    callingName: {
      type: String,
      default: '',
    },
    incomingName: {
      type: String,
      default: '',
    },
    callingTime: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.subject-area {
  > .datetime {
    font: $sans-none-12;
    color: $black700;
    text-align: right;
  }

  > .record-details {
    height: 48px;
    display: flex;
    align-items: center;
    margin-top: $spacing-xxs;
    padding-bottom: $spacing-xxs;
    border-bottom: 2px solid $gray400;
    position: relative;

    > :nth-child(4) {
      margin-left: $spacing-xxs;
    }

    > .supplement {
      margin-left: 4px;
      font: $sans-none-12;
      color: $black700;
    }

    > .number {
      margin-left: 4px;
      font: $sans-none-14-bold;
      color: $black700;
      display: flex;
      align-items: center;
      > span {
        display: block;
        &:nth-child(2) {
          font: $sans-none-10;
          color: $black200;
          margin-left: 4px;
        }
      }
    }

    > .time {
      font: $sans-none-14;
      color: $black700;
      margin-left: $spacing-xxs;
    }
  }
}

</style>
