<template>
  <LayoutMain @refresh="init">
    <div class="body">
      <MessageBar
        v-if="flashMessages.messages.length > 0"
        :type="flashMessages.type"
        :messages="flashMessages.messages"
        class="mb-sm"
      />
      <div class="header">
        <h1 class="title">
          キーワード一覧
        </h1>
        <Button
          v-if="hasAuthCreateKeywordAPI"
          size="inline"
          @click="$router.push({ name: 'VDX810' })"
        >
          ＋キーワードを追加
        </Button>
      </div>
      <MessageBar
        type="info"
        :messages="['登録したキーワードの通知を受けることができます。']"
        class="mt-xs mb-xs"
      />
      <ListKeyword
        :items="keywordList"
        @click="(id) => $router.push({ name: 'VDX820', params: { id } })"
      />
    </div>
  </LayoutMain>
</template>

<script>
import MessageBar from '@/components/common/MessageBar';
import Button from '@/components/button/Button';
import ListKeyword from '@/components/keyword/ListKeyword.vue';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import { getUserRoleAuthsApi, getKeywordListApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    LayoutMain,
    MessageBar,
    ListKeyword,
  },
  data() {
    return {

      /**
       * キーワード一覧
       */
      keywordList: [],

      /**
       * 権限一覧
       */
      authorizations: [],

      /**
       * フラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },
    };
  },
  computed: {
    ...mapGetters(['storeMessages']),
    
    /**
     * キーワード登録APIの権限チェック
     *
     * 権限が無い場合
     * ・「＋キーワードを追加」ボタンを非表示
     */
    hasAuthCreateKeywordAPI() {
      return hasAuthorization(this.authorizations, API_IDS.CREATE_KEYWORD);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'setUserRoleAuths', 'clearStoreMessages']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 権限取得
      await this.getUserRoleAuths();
      // キーワード一覧取得
      await this.fetchData();

      // キーワード登録画面またはキーワード編集画面で格納したストアメッセージがあれば表示する
      this.flashMessages = this.storeMessages;
      this.clearStoreMessages();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.CREATE_KEYWORD,
        ],
      });
      this.authorizations = data.data;

      // 権限をストアに保存
      this.setUserRoleAuths({
        userRoleAuths: this.authorizations,
      });
    },

    /**
     * キーワード一覧取得
     */
    async fetchData() {
      const { data } = await getKeywordListApi();
      this.keywordList = data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-sm $spacing-xs 0;

  > .header {
    display: flex;
    justify-content: space-between;
    min-height: 36px;
    > .title {
      font: $sans-snug-20-bold;
      color: $black700;
      height: 32px;
    }
  }
}
</style>
