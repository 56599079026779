<template>
  <button
    class="button-delete"
    :disabled="disabled"
    data-test="delete-button"
    @click="click"
  >
    <IconDelete />
    <span class="label">
      ゴミ箱に入れる
    </span>
  </button>
</template>

<script>
import IconDelete from '@/components/icons/IconDelete';

export default {
  components: {
    IconDelete,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-delete {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  > .label {
    font: $sans-none-14;
    color: $black500;
    margin-left: $spacing-xxxxs;
  }
}
</style>
