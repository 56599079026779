<template>
  <div class="form-block">
    <FormLabel
      :required="required"
    >
      {{ label }}
      <span
        v-if="miniLabel?.length"
        class="mini-label"
      >
        {{ miniLabel }}
      </span>
      <div
        v-if="infoText?.length"
        class="info"
        @mouseenter="onMouse(true)"
        @mouseleave="onMouse(false)"
      >
        <IconInfo />
        <p
          v-if="isInfoHid"
          class="info-text"
        >
          {{ infoText }}
        </p>
      </div>
    </FormLabel>
    <div
      class="form-item"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import FormLabel from '@/components/form/FormLabel';
import IconInfo from '@/components/icons/IconInfo';

export default {
  components: {
    FormLabel,
    IconInfo,
  },
  props: {
    label: {
      type: [String, Array],
      default: '',
    },
    miniLabel: {
      type: [String, Array],
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isInfoHid: false,
    };
  },
  methods: {
    onMouse(flug) {
      this.isInfoHid = flug;
    },
  },
};
</script>

<style scoped lang="scss">
.form-block {
  font-size: 0;

  .form-item {
    margin-top: $spacing-xxxs;

    :slotted(p) {
      margin: $spacing-xxxxs 0 0 0;
      color: $black900;
      font: $sans-tight-14;
    }
  }
  .form-label {
    display: flex;
    align-items: center;
    .mini-label {
      font: $sans-none-14;
    }
    .info {
      position: relative;
      display: flex;
      z-index: 0;
      margin-left: 4px;
      svg {
        cursor: pointer;
      }
      .info-text {
        background: #000;
        text-align: left;
        color: $white100;
        font: $sans-tight-12;
        position: absolute;
        padding: $spacing-xxxs;
        border-radius: $radius8;
        position: absolute;
        bottom: calc(100% + 16px);
        left: 50%;
        transform: translateX(-50%);
        max-width: 220px;
        width: 220px;
        z-index: 1;
        &::after {
          content: "";
          background: #000;
          width: 18px;
          height: 18px;
          border-radius: $radius4;
          position: absolute;
          right: 0;
          left: 0;
          bottom: -6px;
          margin: auto;
          transform: rotate(45deg);
          z-index: -1;
        }
      }
    }
  }
}
</style>
