<template>
  <LayoutEntry>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="title">
      ご利用に必要な情報
    </div>
    <p class="text">
      ご利用を始めるにあたり、以下の情報をご入力ください
    </p>
    <div class="form-row">
      <div class="label">
        お名前
      </div>
      <FormBlock label="姓 (Family Name)">
        <FormInputText
          v-model="inputUserFamilyName"
          size="width-180"
          :disabled="isUserFamilyName"
        />
      </FormBlock>
      <FormBlock label="名 (First Name)">
        <FormInputText
          v-model="inputUserFirstName"
          size="width-180"
          :disabled="isUserFirstName"
        />
      </FormBlock>
    </div>
    <FormBlock label="メールアドレス">
      <FormInputText
        v-model="userEmailAddress"
        size="width-400"
        :disabled="true"
        type="email"
      />
    </FormBlock>
    <FormBlock label="パスワード">
      <FormInputText
        v-model="password"
        size="width-400"
        type="password"
      />
    </FormBlock>
    <p class="form-text">
      英数字と大文字小文字の組み合わせ８桁以上です
    </p>
    <FormBlock label="パスワード(確認用)">
      <FormInputText
        v-model="passwordConfirmation"
        size="width-400"
        type="password"
      />
    </FormBlock>
    <ButtonBlock>
      <Button
        :disabled="isDisabled"
        @click="register"
      >
        登録
      </Button>
    </ButtonBlock>
  </LayoutEntry>
</template>

<script>
import Button from '@/components/button/Button.vue';
import ButtonBlock from '@/components/button/ButtonBlock.vue';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock.vue';
import FormInputText from '@/components/form/FormInputText.vue';
import LayoutEntry from '@/components/layout/LayoutEntry';
import { signupApi, verifyActivateStateApi } from '@/utils/ApiHelper';
import { hasIdToken } from '@/utils/IdTokenManager';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LayoutEntry,
    FormBlock,
    FormInputText,
    ButtonBlock,
    Button,
    MessageBar,
  },
  data() {
    return {
      errorMessages: [],
      password: '',
      passwordConfirmation: '',
      inputUserFamilyName: '',
      inputUserFirstName: '',
    };
  },
  computed: {
    ...mapGetters(['userEmailAddress', 'userFamilyName', 'userFirstName', 'activateQuery']),
    isDisabled() {
      return this.password.length === 0 || this.passwordConfirmation.length === 0 || this.inputUserFamilyName.length === 0 || this.inputUserFirstName.length === 0;
    },
    /**
     * 姓が設定されている場合は非活性
     */
    isUserFamilyName() {
      return this.userFamilyName != null && this.userFamilyName.length !== 0;
    },
    /**
     * 名が設定されている場合は非活性
     */
    isUserFirstName() {
      return this.userFirstName != null && this.userFirstName.length !== 0;
    },
  },
  mounted() {
    // ログイン済みの場合はホーム画面にリダイレクト
    if (hasIdToken()) {
      this.$router.push({ name: 'VDX100' });
      return;
    }
    this.fetchData();
  },
  methods: {
    ...mapActions(['startLoading','stopLoading', 'setStoreMessages']),
    async fetchData() {
      this.startLoading();
      const { status, data } = await verifyActivateStateApi(
        { q: this.activateQuery },
      );

      if (status == 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.inputUserFamilyName = this.userFamilyName;
      this.inputUserFirstName = this.userFirstName;

      this.stopLoading();
    },
    async register() {
      this.startLoading();
      const { status, data } = await signupApi(
        {
          query: encodeURIComponent(this.activateQuery),
          user_password: this.password,
          user_password_confirm: this.passwordConfirmation,
          user_family_name: this.inputUserFamilyName,
          user_first_name: this.inputUserFirstName,
        },
      );

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // メッセージをストアに保存
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX050' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  > :deep(.container) {
    width: 466px;
  }
}
</style>
