<template>
  <div class="tab-part">
    <ul class="list">
      <li
        v-for="(item, index) of items"
        :key="index"
        class="item"
      >
        <button
          class="button"
          :class="{ 'is-active': item === localValue }"
          @click="onClick(item)"
        >
          Part.{{ item }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  methods: {
    onClick(item) {
      this.localValue = item;
      this.$emit('change', item);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-part {
  > .list {
    display: flex;

    > .item {
      &:not(:first-child) {
        margin-left: $spacing-xs;
      }

      > .button {
        color: $primary500;
        font: $sans-none-16-bold;
        padding: 0;
        width: 64px;
        height: 24px;
        text-align: center;

        &.is-active {
          color: $black700;
          border-bottom: 2px solid $primary500;
          box-sizing: content-box;
        }
      }
    }
  }
}
</style>
