<template>
  <label
    class="form-switch"
    :class="[
      {
        'is-active': modelValue,
        'is-disabled': disabled,
      },
    ]"
  >
    <input
      v-model="value"
      type="checkbox"
      class="form-switch__button"
      :disabled="disabled"
      @click="click"
    >
    <span class="form-switch__toggle" />
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * クリックイベントを停止する
     */
    prevent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'click', 'change'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
        this.$emit('change', newValue);
      },
    },
  },
  methods: {
    click(e) {
      if (this.prevent) {
        e.preventDefault();
      }
      this.$emit('click');
    },
  },
};
</script>

<style scoped lang="scss">
.form-switch {
  display: inline-block;
  user-select: none;
  cursor: pointer;

  &__button {
    display: none;
  }

  &__toggle {
    display: inline-block;
    width: 44px;
    height: 24px;
    border-radius: 100px;
    box-sizing: border-box;
    background: $black500;
    position: relative;
    transition: all 0.25s;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      box-sizing: border-box;
      left: 3px;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.25s cubic-bezier(0.5, -0.6, 0.5, 1.6);
    }

    &::before {
      background: $white100;
      opacity: 0;
    }

    &::after {
      background: $white100;
    }
  }

  &.is-active {
    .form-switch__toggle {
      background: $primary600;

      &::after {
        left: calc(100% - 21px);
      }
    }
  }

  &.is-disabled {
    cursor: not-allowed;

    .form-switch__toggle {
      background: $gray500;

      &::after {
        background: $gray600;
      }
    }

    &.is-active {
      .form-switch__toggle {
        background: $primary400;

        &::after {
          background: $white100;
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
