<template>
  <div
    class="talk-list-item"
    :class="[callingIncomingType]"
  >
    <div class="icon-area">
      <IconCalling
        v-if="callingIncomingType === 'calling'"
        width="40px"
        height="40px"
      />
    </div>
    <div class="inner-area">
      <div class="phone-number">
        {{ speechPhoneName?.length ? speechPhoneName : speechPhoneNumber }}
      </div>
      <div class="text-area">
        <p class="text">
          <Highlighter
            highlight-class-name="highlight"
            :search-words="keywords"
            :text-to-highlight="voiceText ?? ''"
            :auto-escape="true"
          />
        </p>
      </div>
    </div>
    <div class="icon-area">
      <IconIncoming
        v-if="callingIncomingType === 'incoming'"
        width="40px"
        height="40px"
      />
    </div>
  </div>
</template>

<script>
import IconCalling from '@/components/icons/IconCalling';
import IconIncoming from '@/components/icons/IconIncoming';
import Highlighter from 'vue-highlight-words';

export default {
  components: {
    IconCalling,
    IconIncoming,
    Highlighter,
  },
  props: {
    callingIncomingType: {
      type: String,
      default: '',
    },
    speechPhoneNumber: {
      type: String,
      default: '',
    },
    speechPhoneName: {
      type: String,
      default: '',
    },
    voiceText: {
      type: String,
      default: '',
    },
    keywords: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.talk-list-item {
  display: flex;
  > .icon-area {
    min-width: 40px;
  }
  > .inner-area {
    padding-left: $spacing-xxxxs;
    padding-right: $spacing-xxxxs;
    width: calc(100% - (40px * 2));
    > .phone-number {
      font: $sans-none-12-bold;
      color: $black400;
      margin-bottom: 6px;
    }
    > .text-area {
      padding: $spacing-xxxs;
      > .text {
        font: $sans-normal-14;
        color: $black700;
        overflow-wrap: break-word;

        :deep(.highlight) {
          background-color: $red200;
          color: $black700;
          padding: 3px 0px;
        }
      }
    }
  }

  &.calling {
    > .inner-area {
      > .phone-number {
        text-align: left;
      }
      > .text-area {
        background-color: $primary300;
        border-radius: 16px 16px 16px 3px;
      }
    }
  }

  &.incoming {
    > .inner-area {
      > .phone-number {
        text-align: right;
      }
      > .text-area {
        background-color: $gray200;
        border-radius: 16px 16px 3px 16px;
      }
    }
  }
}
</style>
