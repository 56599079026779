<template>
  <div class="page-heading">
    <h1 class="title">
      {{ heading }}
    </h1>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.page-heading {
  height: 42px;
  border-bottom: 2px solid $gray400;

  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}
</style>
