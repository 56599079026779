<template>
  <LayoutEntry>
    <MessageBar
      v-if="errorMessages.length > 0"
      type="error"
      :messages="errorMessages"
    />
    <div class="title">
      パスワード再設定
    </div>
    <FormBlock label="パスワード">
      <FormInputText
        v-model="userPassword"
        size="width-320"
        type="password"
      />
    </FormBlock>
    <p class="text form-text">
      英数字と大文字小文字組み合わせ８桁以上です
    </p>
    <FormBlock label="パスワード(確認用)">
      <FormInputText
        v-model="userPasswordConfirm"
        size="width-320"
        type="password"
      />
    </FormBlock>
    <ButtonBlock>
      <Button
        :disabled="isDisabled"
        @click="resetPassword"
      >
        再設定
      </Button>
    </ButtonBlock>
  </LayoutEntry>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutEntry from '@/components/layout/LayoutEntry';
import { resetPasswordApi, verifyResetPwStateApi } from '@/utils/ApiHelper';
import { hasIdToken } from '@/utils/IdTokenManager';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutEntry,
    MessageBar,
    FormBlock,
    FormInputText,
    ButtonBlock,
    Button,
  },
  data() {
    return {
      errorMessages: [],
      userPassword: '',
      userPasswordConfirm: '',
    };
  },
  computed: {
    isDisabled() {
      return this.userPassword.length === 0 || this.userPasswordConfirm.length === 0;
    },
  },
  mounted() {
    // ログイン済みの場合はホーム画面にリダイレクト
    if (hasIdToken()) {
      this.$router.push({ name: 'VDX100' });
      return;
    }
    this.fetchData();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'setStoreMessages']),
    async fetchData() {
      this.startLoading();
      const { status, data } = await verifyResetPwStateApi(
        { q: this.$route.query.q },
      );

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.stopLoading();
    },
    async resetPassword() {
      this.startLoading();
      const { status, data } = await resetPasswordApi(
        {
          query: encodeURIComponent(this.$route.query.q),
          user_password: this.userPassword,
          user_password_confirm: this.userPasswordConfirm,
        },
      );
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });
      this.$router.push({ name: 'VDX050' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  > :deep(.container) {
    width: 386px;
  }
}
</style>
