<template>
  <button
    class="button-back"
    @click="$emit('click')"
  >
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8812_102252)">
        <path
          d="M14.9873 6.25V1.25L8.7373 7.5L14.9873 13.75V8.75C19.1248 8.75 22.4873 12.1125 22.4873 16.25C22.4873 20.3875 19.1248 23.75 14.9873 23.75C10.8498 23.75 7.4873 20.3875 7.4873 16.25H4.9873C4.9873 21.775 9.4623 26.25 14.9873 26.25C20.5123 26.25 24.9873 21.775 24.9873 16.25C24.9873 10.725 20.5123 6.25 14.9873 6.25Z"
          fill="#4A4B53"
        />
        <path
          d="M13.6121 20H12.5496V15.925L11.2871 16.3125V15.45L13.4996 14.6625H13.6121V20Z"
          fill="#4A4B53"
        />
        <path
          d="M18.9621 17.8C18.9621 18.2 18.9246 18.55 18.8371 18.825C18.7496 19.1 18.6246 19.35 18.4746 19.5375C18.3246 19.725 18.1246 19.8625 17.9121 19.95C17.6996 20.0375 17.4496 20.075 17.1746 20.075C16.8996 20.075 16.6621 20.0375 16.4371 19.95C16.2121 19.8625 16.0246 19.725 15.8621 19.5375C15.6996 19.35 15.5746 19.1125 15.4871 18.825C15.3996 18.5375 15.3496 18.2 15.3496 17.8V16.875C15.3496 16.475 15.3871 16.125 15.4746 15.85C15.5621 15.575 15.6871 15.325 15.8371 15.1375C15.9871 14.95 16.1871 14.8125 16.3996 14.725C16.6121 14.6375 16.8621 14.6 17.1371 14.6C17.4121 14.6 17.6496 14.6375 17.8746 14.725C18.0996 14.8125 18.2871 14.95 18.4496 15.1375C18.6121 15.325 18.7371 15.5625 18.8246 15.85C18.9121 16.1375 18.9621 16.475 18.9621 16.875V17.8ZM17.8996 16.725C17.8996 16.4875 17.8871 16.2875 17.8496 16.125C17.8121 15.9625 17.7621 15.8375 17.6996 15.7375C17.6371 15.6375 17.5621 15.5625 17.4621 15.525C17.3621 15.4875 17.2621 15.4625 17.1496 15.4625C17.0371 15.4625 16.9246 15.4875 16.8371 15.525C16.7496 15.5625 16.6621 15.6375 16.5996 15.7375C16.5371 15.8375 16.4871 15.9625 16.4496 16.125C16.4121 16.2875 16.3996 16.4875 16.3996 16.725V17.9375C16.3996 18.175 16.4121 18.375 16.4496 18.5375C16.4871 18.7 16.5371 18.8375 16.5996 18.9375C16.6621 19.0375 16.7371 19.1125 16.8371 19.15C16.9371 19.1875 17.0371 19.2125 17.1496 19.2125C17.2621 19.2125 17.3746 19.1875 17.4621 19.15C17.5496 19.1125 17.6371 19.0375 17.6996 18.9375C17.7621 18.8375 17.8121 18.7 17.8371 18.5375C17.8621 18.375 17.8871 18.175 17.8871 17.9375V16.725H17.8996Z"
          fill="#4A4B53"
        />
      </g>
      <defs>
        <clipPath id="clip0_8812_102252">
          <rect
            width="30"
            height="30"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<script>
export default {
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
.button-back {
  margin: 0;
  padding: 0;
}
</style>
