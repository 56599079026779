<template>
  <li class="listitem-download">
    <div class="item">
      <VoiceCheckbox
        v-model="checkedIdsModelValue"
        :value="id"
        :disabled="isDeletable"
        class="checkbox"
      />
      <div 
        class="status"
        :class="downloadStatusValue"
      >
        {{ downloadStatusDisplay }}
      </div>
      <div class="size">
        {{ zipFileSize }}
      </div>
      <div class="format">
        {{ fileFormat }}
      </div>
      <div class="user">
        {{ userName }}
      </div>
      <div class="date">
        {{ requestDatetime }}
      </div>
      <div
        class="downlod-btn"
      >
        <ButtonDownloadZip
          :disabled="isDownloadable"
          @click="onDownload"
        />
      </div>
    </div>
  </li>
</template>

<script>
import ButtonDownloadZip from '@/components/button/ButtonDownloadZip';
import VoiceCheckbox from '@/components/voice/VoiceCheckbox';

export default {
  components: {
    VoiceCheckbox,
    ButtonDownloadZip,
  },
  props: {
    checkedIds: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    downloadStatusValue: {
      type: String,
      default: '',
      validator: (value) => {
        return ['downloadable', 'prepare', 'failed'].includes(value);
      },
    },
    downloadStatusDisplay: {
      type: String,
      default: '',
    },
    zipFileSize: {
      type: String,
      default: '',
    },
    fileFormat: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
    requestDatetime: {
      type: String,
      default: '',
    },
    isDeletable: {
      type: Boolean,
      default: false,
    },
    isDownloadable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'update:checkedIds'],
  computed: {
    checkedIdsModelValue: {
      get() {
        return this.checkedIds;
      },
      set(newValue) {
        this.$emit('update:checkedIds', newValue);
      },
    },
  },
  methods: {
    onDownload() {
      this.$emit('click', this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.listitem-download {
  border-bottom: 1px solid $gray400;
  background-color: $white100;
  position: relative;

  > .voice-checkbox {
    position: absolute;
    top: 15px;
    left: 16px;
  }

  .item {
    display: flex;
    text-decoration: none;
    align-items: center;

    > .status,
    .size,
    .format,
    .user,
    .date {
      font: $sans-none-14;
      color: $black700;
      height: 40px;
      line-height: 40px;
    }

    > .status.failed {
      color: $red300;
    }

    > .user {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .checkbox {
      flex-basis: 4%;
      flex-grow: 0;
      flex-shrink: 0;

      > :deep(.checkbox) {
        transform: translateX(10px);
      }
    }

    > .status,
    .size,
    .format,
    .user,
    .date,
    .download-btn {
      flex-basis: 16%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .user {
      padding-right: $spacing-xxs;
    }

    > .downlod-btn {
      margin: 0 $spacing-sm;
    }
  }
}
</style>
