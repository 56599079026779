<template>
  <div class="message-keyword">
    <p class="message">
      {{ keywords.length }}個のキーワードが見つかりました
    </p>
    <div class="keywords">
      <div
        v-for="(keyword, index) of keywords"
        :key="index"
        class="keyword"
      >
        {{ keyword }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keywords: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.message-keyword {
  padding: $spacing-xxxs;
  border-radius: 4px;
  background-color: $green100;

  > .message {
    font: $sans-snug-14-bold;
    text-align: left;
    color: $green500;
    margin-bottom: $spacing-xxxxs;
  }

  > .keywords {
    margin: -4px -6px;
    > .keyword {
      display: inline-block;
      background-color: $white100;
      padding: 6px $spacing-xxxs;
      font: $sans-tight-12;
      color: $black500;
      border-radius: 4px;
      margin: 4px 6px;
      overflow-wrap: break-word;
      word-break:break-all;
    }
  }
}
</style>
