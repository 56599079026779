<template>
  <header class="header">
    <div class="inner">
      <IconLogo />
      <nav class="navigation">
        <ul class="nav-list">
          <li class="nav-list-item">
            <a
              href="/kvv-document.zip"
              class="flex manual-link"
            >
              <IconNote />
              <span class="text">使い方</span>
            </a>
          </li>
          <li
            v-if="isLogin"
            class="nav-list-item"
            @click.stop="toggleProfile"
          >
            <div class="flex">
              <IconUser />
              <span class="text">{{ userRoleName }}</span>
            </div>
          </li>
        </ul>
      </nav>
    </div>
    <LoginUserProfile
      v-if="isOpenProfile"
      @close="closeProfile"
    />
  </header>
</template>

<script>
import LoginUserProfile from '@/components/common/LoginUserProfile';
import IconLogo from '@/components/icons/IconLogo.vue';
import IconNote from '@/components/icons/IconNote';
import IconUser from '@/components/icons/IconUser.vue';
import { getIdToken, hasIdToken } from '@/utils/IdTokenManager';

export default {
  components: {
    IconLogo,
    IconUser,
    LoginUserProfile,
    IconNote,
  },
  data() {
    return {
      isOpenProfile: false,
    };
  },
  computed: {
    isLogin() {
      return hasIdToken();
    },
    userRoleName() {
      return getIdToken('userRoleName');
    },
  },
  methods: {
    /**
     * LoginUserProfileコンポーネントで定義している
     * クリックイベントが発火してしまうため
     * @click.stop を使用してクリックイベントの伝搬を停止する
     */
    toggleProfile() {
      this.isOpenProfile = !this.isOpenProfile;
    },
    closeProfile() {
      this.isOpenProfile = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 64px;
  width: 100%;
  min-width: 1280px;
  background-color: $navy100;
  position: relative;

  .inner {
    height: 100%;
    width: 100%;
    padding: 0 $spacing-sm;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .navigation {
      .nav-list {
        display: flex;
        align-items: center;
        .nav-list-item {
          cursor: pointer;
          &:not(:first-child) {
            margin-left: $spacing-xxxs;
          }
          .flex {
            display: flex;
            align-items: center;
          }
          .manual-link {
            text-decoration: none;
          }
          .text {
            font: $sans-none-14;
            color: $white100;
            margin-left: $spacing-xxxxs;
          }
        }
      }
    }
  }

  .login-user-profile {
    position: absolute;
    top: 52px;
    right: $spacing-xs;
    z-index: $z-dropdown;
  }
}
</style>
