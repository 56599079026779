import { ERROR_MESSAGES } from '@/utils/Constants';
import { getIdToken, removeIdToken } from '@/utils/IdTokenManager';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import router from '../router/index';
import store from '../store/index';

const execute = ({ url, method, params, data, responseType, ContentType }) => {
  return new Promise((resolve) => {
    axios({
      url,
      method,
      baseURL: process.env.VUE_APP_API_DOMAIN,
      headers: {
        Authorization: `Bearer ${getIdToken('jwtToken')}`,
        accept: 'application/json',
        'Content-Type': ContentType,
      },
      params,
      data,
      responseType,
      validateStatus(status) {
        // 業務エラー または バリデーションエラー の場合も resolve を返す
        return (status >= 200 && status < 300) || status === 400 || status === 422;
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.response) {
          const status = err.response.status;

          // ログイン画面へリダイレクト
          if (status === 300) {
            router.push({ name: 'VDX050' });
            store.dispatch('stopLoading');
            return;
          }

          // トークンエラー
          if (status === 401) {
            // SSOログアウトする場合
            // ログイン画面にリダイレクトされてしまうためローカルストレージにエラーメッセージを保存
            localStorage.setItem('idTokenErrorMessage', ERROR_MESSAGES.UNAUTHORIZED_API);
            // ローカルストレージのIDトークンを削除
            removeIdToken();
            // Amplifyライブラリを使用してログアウト
            // SSOログインした場合はログイン画面にリダイレクトされる
            // リダイレクト先は環境変数「VUE_APP_COGNITO_OAUTH_REDIRECT_SIGNOUT」で設定
            Auth.signOut().then(() => {
              router.push({ name: 'VDX050' });
              store.dispatch('stopLoading');
            });
            return;
          }

          // 認可エラー
          if (status === 403) {
            router.push({ name: 'VDX930' });
            store.dispatch('stopLoading');
            return;
          }

          // Not Found
          if (status === 404) {
            router.push({ name: 'VDX900' });
            store.dispatch('stopLoading');
            return;
          }

          // システムエラー
          if (status === 500) {
            router.push({ name: 'VDX910' });
            store.dispatch('stopLoading');
            return;
          }

          // メンテナンス中
          if (status === 503) {
            location.reload();
            return;
          }

          // タイムアウトエラー
          if (status === 504) {
            router.push({ name: 'VDX920' });
            store.dispatch('stopLoading');
            return;
          }
        }

        // その他のエラー
        router.push({ name: 'VDX910' });
        store.dispatch('stopLoading');
      });
  });
};

// アクティベート状態取得API
export const verifyActivateStateApi = (params) => {
  return execute({ url: '/activate', method: 'GET', params });
};

// アクティベートコード検証API
export const verifyActivateCodeApi = (data) => {
  return execute({ url: '/activate', method: 'POST', data });
};

// 初期登録API
export const signupApi = (data) => {
  return execute({ url: '/signup', method: 'POST', data });
};

// パスワード再設定状態取得API
export const verifyResetPwStateApi = (params) => {
  return execute({ url: '/settings/password', method: 'GET', params });
};

// パスワード再設定API
export const resetPasswordApi = (data) => {
  return execute({ url: '/settings/password', method: 'PUT', data });
};

// 記録一覧取得API
export const getRecordsApi = (data) => {
  return execute({ url: '/records', method: 'POST', data });
};

// 回線契約管理番号取得API
export const getLineContractsApi = () => {
  return execute({ url: '/record/linecontracts', method: 'GET' });
};

// 記録詳細取得API
export const getRecordApi = (params, data) => {
  return execute({ url: `/records/${params.id}`, method: 'POST', data });
};

// CSVダウンロードAPI
export const downloadCsvApi = (params) => {
  return execute({ url: `/records/${params.id}/${params.part}/csvdownload`, method: 'GET', responseType: 'blob' });
};

// ユーザ一覧取得API
export const getUsersApi = () => {
  return execute({ url: '/users', method: 'GET' });
};

// 招待メール送信API
export const sendInviteMailApi = (data) => {
  return execute({ url: '/users', method: 'POST', data });
};

// ユーザ詳細取得API
export const getUserApi = (params) => {
  return execute({ url: `/users/${params.id}`, method: 'GET' });
};

// パスワード再設定メール送信API
export const sendResetPwMailApi = (params) => {
  return execute({ url: `/users/${params.id}/password`, method: 'PUT' });
};

// ユーザ削除API
export const deleteUserApi = (params) => {
  return execute({ url: `/users/${params.id}`, method: 'DELETE' });
};

// 音声ダウンロードURL取得API
export const getWavDownloadUrlApi = (pathParams, queryParams) => {
  return execute({ url: `/records/${pathParams.summary_id}/${pathParams.part}/wavdownload`, method: 'GET', params: queryParams });
};

// 記録削除API
export const deleteRecordsApi = (data) => {
  return execute({ url: '/records', method: 'DELETE', data });
};

// 記録復元API
export const restoreRecordsApi = (data) => {
  return execute({ url: '/trash/restore', method: 'POST', data });
};

// ユーザ編集API
export const editUserApi = (params, data) => {
  return execute({ url: `/users/${params.id}`, method: 'POST', data });
};

// ユーザ一覧：ユーザ一括出力API
export const getUserCSVDownloadApi = () => {
  return execute({ url: '/users/csv/download', method: 'GET', responseType: 'blob' });
};

// ユーザ一覧：ユーザ一括登録API
export const postUserCSVImportApi = (data) => {
  return execute({ url: '/users/csv/upload', method: 'POST', ContentType: 'multipart/form-data', data });
};

// 今月の利用状況取得API
export const getUsageCurrentApi = () => {
  return execute({ url: '/usage/current', method: 'GET' });
};

// 過去の利用状況取得API
export const getUsagePastApi = () => {
  return execute({ url: '/usage/past', method: 'GET' });
};

// 文字起こしテキスト編集API
export const editRecordTextApi = (params, data) => {
  return execute({ url: `/records/${params.summary_id}/${params.part}/edit`, method: 'POST', data });
};

// ロール名称取得API
export const getUserRolesApi = () => {
  return execute({ url: '/user-roles', method: 'GET' });
};

// ロール権限取得API
export const getUserRoleAuthsApi = (data) => {
  return execute({ url: '/user-role-auths', method: 'POST', data });
};

// 自動削除設定取得API
export const getAutoDeleteApi = () => {
  return execute({ url: '/auto-delete', method: 'GET' });
};

// 自動削除設定更新API
export const updateAutoDeleteApi = (data) => {
  return execute({ url: '/auto-delete', method: 'POST', data });
};

// ダウンロードデータ作成状況取得API
export const getDownloadDataApi = () => {
  return execute({ url: '/download', method: 'GET' });
};

// ダウンロードデータ削除API
export const deleteDownloadDataApi = (data) => {
  return execute({ url: '/download', method: 'DELETE', data });
};

// ダウンロードURL取得API
export const getDownloadUrlApi = (params) => {
  return execute({ url: `/download/${params.download_id}/zipdownload`, method: 'GET'});
};

// 選択ダウンロード上限・業務チェックAPI
export const checkSelectDownloadApi = (data) => {
  return execute({ url: '/record/check-select-download', method: 'POST', data });
};

// 検索ダウンロード上限・業務チェックAPI
export const checkSearchDownloadApi = (data) => {
  return execute({ url: '/record/check-search-download', method: 'POST', data });
};

// 選択ダウンロード作成API
export const createSelectDownloadApi = (data) => {
  return execute({ url: '/record/create-select-download', method: 'POST', data });
};

// 検索ダウンロード作成API
export const createSearchDownloadApi = (data) => {
  return execute({ url: '/record/create-search-download', method: 'POST', data });
};

// グループ一覧取得API
export const getBasesApi = () => {
  return execute({ url: '/bases', method: 'GET' });
};

// グループ名一覧取得API
export const getBasesNameApi = () => {
  return execute({ url: '/bases_name', method: 'GET' });
};

// グループ一覧：グループCSV出力API
export const getCSVDownloadApi = () => {
  return execute({ url: '/bases/csv/download', method: 'GET', responseType: 'blob' });
};

// グループ一覧：グループCSV追加API
export const postCSVImportApi = (data) => {
  return execute({ url: '/bases/csv/upload', method: 'POST', ContentType: 'multipart/form-data', data });
};

// グループ詳細取得API
export const getBaseApi = (params) => {
  return execute({ url: `/bases/${params.id}`, method: 'GET' });
};

// グループ編集API
export const editBaseApi = (params, data) => {
  return execute({ url: `/bases/${params.id}/edit`, method: 'POST', data });
};

// グループ削除API
export const deleteBaseApi = (params) => {
  return execute({ url: `/bases/${params.id}`, method: 'DELETE' });
};

// キーワード通知詳細取得API
export const getNoticeKeywordApi = (params, data) => {
  return execute({ url: `/notice/keyword/${params.id}`, method: 'POST', data });
};

// キーワード一覧取得API
export const getKeywordListApi = () => {
  return execute({ url: '/keywords', method: 'GET' });
};

// キーワード登録API
export const createKeywordApi = (data) => {
  return execute({ url: '/keywords/create', method: 'POST', data });
};

// キーワード通知一覧取得API
export const getNoticeKeywordsApi = (data) => {
  return execute({ url: '/notice/keyword', method: 'POST', data });
};

// キーワード名一覧取得API
export const getKeywordsNameApi = () => {
  return execute({ url: '/keywords_name', method: 'GET' });
};

// キーワード編集API
export const editKeywordApi = (params, data) => {
  return execute({ url: `/keywords/${params.id}/edit`, method: 'POST', data });
};

// キーワード削除API
export const deletKeywordApi = (params) => {
  return execute({ url: `/keywords/${params.id}/delete`, method: 'DELETE' });
};

// キーワード詳細取得API
export const getKeywordApi = (params) => {
  return execute({ url: `/keywords/${params.id}`, method: 'GET' });
};

// ログイン認証設定取得API
export const loginSettingAuthApi = (data) => {
  return execute({ url: '/login_setting_auth', method: 'POST', data });
};

// IdP設定取得API
export const getIdpSettingApi = () => {
  return execute({ url: '/sso/idp_setting', method: 'GET' });
};

// IdP設定更新API
export const editIdPSettingApi = (data) => {
  return execute({ url: '/sso/idp_setting/edit', method: 'POST', data });
};

// IdPメタデータ登録API
export const postIdpMetadataApi = (data) => {
  return execute({ url: '/sso/edit_idp_metadata', method: 'POST', ContentType: 'multipart/form-data', data });
};

// AI生成テキスト取得API
export const getAiGenerationApi = (params) => {
  return execute({ url: `/ai/${params.summary_id}`, method: 'GET' });
};

// AIテキスト生成API
export const postAiGenerationApi = (params) => {
  return execute({ url: `/ai/${params.summary_id}`, method: 'POST' });
};

// パスワード再設定メール送信API
export const requestPasswordReset = (data) => {
  return execute({ url: '/request_password_reset', method: 'POST', data });
};

// 機能種別一覧取得API
export const getLogTypeNameApi = (data) => {
  return execute({ url: '/log/func_type', method: 'GET', data });
};

// アクセスログ検索API
export const postSearchAccessLogApi = (data) => {
  return execute({ url: '/log', method: 'POST', data });
};

// アクセスログCSVダウンロードAPI
export const postAccessLogCsvDownloadApi = (data) => {
  return execute({ url: '/log/csv_download', method: 'POST', data, responseType: 'blob' });
};

// 取引先一覧取得API
export const getSupplierListApi = () => {
  return execute({ url: '/suppliers', method: 'GET' });
};

// 取引先登録API
export const postCreatSupplierApi = (data) => {
  return execute({ url: '/suppliers/create', method: 'POST', data });
};

// 取引先詳細取得API
export const getSupplierApi = (params) => {
  return execute({ url: `/suppliers/${params.supplier_id}`, method: 'GET' });
};

// 取引先削除API
export const deleteSupplierApi = (params) => {
  return execute({ url: `/suppliers/${params.supplier_id}`, method: 'DELETE' });
};

// 取引先編集API
export const editSupplierApi = (params, data) => {
  return execute({ url: `/suppliers/${params.supplier_id}/edit`, method: 'POST', data });
};
