<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
      class="messages"
    />
    <MessageBar
      v-if="successMessages.length > 0"
      :messages="successMessages"
      type="success"
      class="messages"
    />
    <div class="menu">
      <button
        class="button"
        data-test="back-to-list-button"
        @click="backToList"
      >
        <IconBack />
      </button>
      <div class="right">
        <ButtonDownload
          v-if="((hasAuthDownloadRecordWavAPI && canVoiceDl) || (hasAuthDownloadRecordCsvAPI && canTextDl)) && currentVoiceFileSummaryId"
          :disabled="!canVoiceDl && !canTextDl"
          :can-voice-dl="hasAuthDownloadRecordWavAPI && canVoiceDl"
          :can-text-dl="hasAuthDownloadRecordCsvAPI && canTextDl"
          @voice="downloadVoice"
          @text="downloadText"
        />
        <ButtonDelete
          v-if="hasAuthDeleteRecordListAPI && currentVoiceFileSummaryId"
          :disabled="!isDeletable"
          @click="$refs.modalVoiceDelete.openModal()"
        />
        <Pagination
          v-if="currentVoiceFileSummaryId"
          :has-prev="!!prevVoiceFileSummaryId"
          :has-next="!!nextVoiceFileSummaryId"
          @prev="prevRecord"
          @next="nextRecord"
        />
      </div>
    </div>
    <div
      v-if="currentVoiceFileSummaryId"
      id="scrollable"
      class="body"
    >
      <SubjectArea
        :call-start-datetime="callStartDatetime"
        :call-end-datetime="callEndDatetime"
        :calling-number="callingNumber"
        :incoming-number="incomingNumber"
        :calling-name="callingName"
        :incoming-name="incomingName"
        :calling-time="callingTime"
        :is-ai-generation="isAiGeneration"
        @onAiGeneration="onAiGeneration"
        @onAiInformation="onAiInformation"
      />
      <p
        v-if="isPartition"
        class="text"
      >
        通話が1時間を超えるため、記録が分割されます
      </p>
      <p
        v-if="isReceiving"
        class="text-green"
      >
        音声データを受信中...
      </p>
      <TabPart
        v-if="isPartition"
        v-model="currentPart"
        :items="part"
        @change="changePart"
      />
      <ChatList
        v-if="status === 'normal'"
        ref="chatList"
        :items="data"
        :is-editable="true"
        :is-show-edit-button="hasAuthUpdateRecordTextAPI"
        @edit="onEditText"
        @cancel="onCancelText"
        @save="onSaveText"
      />
      <p
        v-if="status === 'stt_nothing'"
        class="text-normal"
      >
        音声テキスト化プランが必要です
      </p>
      <p
        v-if="status === 'text_nothing'"
        class="text-normal"
      >
        テキストデータがありません<br>
        （処理中の場合、しばらくたつとテキストに変換されます。時間をおいて再度アクセスしてください）
      </p>
      <p
        v-if="status === 'stt_error'"
        class="text-normal"
      >
        こちらの記録は、文字起こしに失敗しました
      </p>
    </div>
  </LayoutMain>

  <ModalVoiceDelete
    ref="modalVoiceDelete"
    @delete="deleteRecord"
  />

  <AudioPlayer
    v-if="currentVoiceFileSummaryId"
    ref="audioPlayer"
    :summary-id="$route.params.id"
    :part="currentPart"
    @error="(value) => errorMessages = value"
  />
  
  <ModalAiGeneration
    ref="modalAiGeneration"
    :summary-id="$route.params.id"
  />
  <ModalAiInformation ref="modalAiInformation" />
</template>

<script>
import ButtonDelete from '@/components/button/ButtonDelete';
import ButtonDownload from '@/components/button/ButtonDownload';
import MessageBar from '@/components/common/MessageBar';
import Pagination from '@/components/common/Pagination';
import IconBack from '@/components/icons/IconBack';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import SubjectArea from '@/components/layout/SubjectArea';
import ModalAiGeneration from '@/components/modal/ModalAiGeneration';
import ModalAiInformation from '@/components/modal/ModalAiInformation';
import ModalVoiceDelete from '@/components/modal/ModalVoiceDelete';
import AudioPlayer from '@/components/player/AudioPlayer';
import ChatList from '@/components/voice/ChatList';
import TabPart from '@/components/voice/TabPart';
import { deleteRecordsApi, downloadCsvApi, editRecordTextApi, getRecordApi, getUserRoleAuthsApi, getWavDownloadUrlApi } from '@/utils/ApiHelper';
import { hasAuthorization, toSnakeCaseObject } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { saveAs } from 'file-saver';
import { mapActions, mapGetters } from 'vuex';

function initialState() {
  return {
    currentVoiceFileSummaryId: '',
    currentPart: 1,
    prevVoiceFileSummaryId: '',
    nextVoiceFileSummaryId: '',
    canVoiceDl: false,
    canTextDl: false,
    callingNumber: '',
    incomingNumber: '',
    callingName: '',
    incomingName: '',
    callingTime: '',
    callStartDatetime: '',
    callEndDatetime: '',
    isPartition: false,
    isReceiving: false,
    isDeletable: false,
    status: '',
    part: [],
    data: [],

    /**
     * 記録詳細画面に表示する成功メッセージ
     *
     * 文字起こしテキスト編集APIの成功メッセージで使用
     */
    successMessages: [],

    /**
     * 記録詳細画面に表示するエラーメッセージ
     */
    errorMessages: [],

    /**
     * 「会話要約とToDoをAI生成」と「情報アイコン」ボタンの表示制御で使用
     */
    isAiGeneration: false,
  };
}

export default {
  components: {
    LayoutMain,
    IconBack,
    ButtonDownload,
    ButtonDelete,
    Pagination,
    ChatList,
    SubjectArea,
    TabPart,
    MessageBar,
    ModalVoiceDelete,
    AudioPlayer,
    ModalAiGeneration,
    ModalAiInformation,
  },
  data() {
    return {
      ...initialState(),
      ...{
        /**
         * 権限一覧
         */
        authorizations: [],
      },
    };
  },
  computed: {
    ...mapGetters(['query', 'currentIndex']),

    /**
     * 音声ダウンロードURL取得APIの権限チェック
     *
     * 権限が無い場合
     * ・ダウンロードの音声を非表示
     * ・CSVダウンロードAPIと、音声ダウンロードURL取得APIの権限がどちらも無い場合は、ダウンロードボタンを非表示
     */
    hasAuthDownloadRecordWavAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DOWNLOAD_RECORD_WAV);
    },

    /**
     * CSVダウンロードAPIの権限チェック
     *
     * 権限が無い場合
     * ・ダウンロードのテキストを非表示
     * ・CSVダウンロードAPIと、音声ダウンロードURL取得APIの権限がどちらも無い場合は、ダウンロードボタンを非表示
     */
    hasAuthDownloadRecordCsvAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DOWNLOAD_RECORD_CSV);
    },

    /**
     * 記録削除APIの権限チェック
     *
     * 権限が無い場合
     * ・削除ボタンを非表示
     */
    hasAuthDeleteRecordListAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DELETE_RECORD_LIST);
    },

    /**
     * 文字起こしテキスト編集APIの権限チェック
     *
     * 権限が無い場合
     * ・各パラグラフの編集ボタンを非表示
     */
    hasAuthUpdateRecordTextAPI() {
      return hasAuthorization(this.authorizations, API_IDS.UPDATE_RECORD_TEXT);
    },
  },
  watch: {
    async $route(to, from) {
      if (to.name === from.name) {
        this.startLoading();
        // dataの初期化
        Object.assign(this.$data, initialState());
        // 記録詳細取得
        await this.fetchRecord();
        // 音声再生プレイヤーを初期化する
        this.$refs.audioPlayer?.initialize();
        this.stopLoading();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setQuery',
      'setRestoreFlagOn',
      'setStoreMessages',
      'prevIndex',
      'nextIndex',
      'setUserRoleAuths',
    ]),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();
      // 権限取得
      await this.getUserRoleAuths();
      // 記録詳細取得
      await this.fetchRecord();
      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.DOWNLOAD_RECORD_WAV,
          API_IDS.DOWNLOAD_RECORD_CSV,
          API_IDS.DELETE_RECORD_LIST,
          API_IDS.UPDATE_RECORD_TEXT,
          API_IDS.GET_DOWNLOAD_DATA,
        ],
      });
      this.authorizations = data.data;

      // 権限をストアに保存
      this.setUserRoleAuths({
        userRoleAuths: this.authorizations,
      });
    },

    /**
     * 記録詳細を取得する
     */
    async fetchRecord(part = 1) {
      // 記録詳細取得APIを呼び出す
      const { status, data } = await getRecordApi(
        { id: this.$route.params.id },
        {
          part,
          is_deleted: false,
          query: toSnakeCaseObject({
            ...this.query,
            ...{
              page: Math.ceil(this.currentIndex / 50),
            },
          }),
        },
      );

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された記録詳細を設定する
      this.currentVoiceFileSummaryId = data.current_voice_file_summary_id;
      this.currentPart = data.current_part;
      this.prevVoiceFileSummaryId = data.prev_voice_file_summary_id;
      this.nextVoiceFileSummaryId = data.next_voice_file_summary_id;
      this.canVoiceDl = data.can_voice_dl;
      this.canTextDl = data.can_text_dl;
      this.callingNumber = data.calling_number;
      this.incomingNumber = data.incoming_number;
      this.callingName = data.calling_name;
      this.incomingName = data.incoming_name;
      this.callingTime = data.calling_time;
      this.callStartDatetime = data.call_start_datetime;
      this.callEndDatetime = data.call_end_datetime;
      this.isPartition = data.is_partition;
      this.isReceiving = data.is_receiving;
      this.isDeletable = data.is_deletable;
      this.status = data.status;
      this.part = data.part;
      this.isAiGeneration = data.is_ai_generation;
      this.data = data.data;
    },

    /**
     * 音声ダウンロード
     */
    async downloadVoice() {
      this.startLoading();

      // メッセージクリア
      this.errorMessages = [];
      this.successMessages = [];
      // 編集モードを終了する
      this.$refs.chatList?.endEdit();

      // 音声ダウンロードURL取得APIの呼び出し
      const { status, data } = await getWavDownloadUrlApi(
        {
          summary_id: this.$route.params.id,
          part: this.currentPart,
        },
      );

      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      let link = document.createElement('a');
      link.href = data.download_url;
      link.click();
      this.stopLoading();
    },

    /**
     * CSVダウンロード
     */
    async downloadText() {
      this.startLoading();

      // メッセージクリア
      this.errorMessages = [];
      this.successMessages = [];
      // 編集モードを終了する
      this.$refs.chatList?.endEdit();

      // CSVダウンロードAPIの呼び出し
      const { status, data, headers } = await downloadCsvApi(
        {
          id: this.$route.params.id,
          part: this.currentPart,
        },
      );

      if (status === 400 || status === 422) {
        const text = await data.text();
        this.errorMessages = JSON.parse(text).detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      const content = headers['content-disposition'];
      const regex = content.match(/filename=(.+)/);
      const filename = regex[1];
      saveAs(data, filename);
      this.stopLoading();
    },

    /**
     * 記録を削除する
     */
    async deleteRecord() {
      this.startLoading();

      // メッセージクリア
      this.errorMessages = [];
      this.successMessages = [];
      // 編集モードを終了する
      this.$refs.chatList?.endEdit();

      // 記録削除APIを呼び出す
      const { status, data } = await deleteRecordsApi({
        summary_ids: [this.currentVoiceFileSummaryId],
      });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを表示
      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        // 削除確認モーダルを閉じる
        this.$refs.modalVoiceDelete.hideModal();
        this.stopLoading();
        return;
      }

      // 成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      // 記録一覧画面で検索条件を復元する（記録を削除した場合は１ページ目を表示）
      this.setQuery({
        query: {
          ...this.query,
          ...{
            page: 1,
          },
        },
      });
      this.setRestoreFlagOn();

      // 記録一覧画面に遷移する
      this.$router.push({ name: 'VDX100' });
      this.stopLoading();
    },

    onEditText() {
      this.errorMessages = [];
      this.successMessages = [];
    },
    onCancelText() {
      this.errorMessages = [];
      this.successMessages = [];
    },

    /**
     * 文字起こしテキスト編集
     *
     * @param {*} speechOrder パラグラフの順番
     * @param {*} editText 編集したテキスト内容
     */
    async onSaveText(speechOrder, editText) {
      this.startLoading();

      // メッセージクリア
      this.errorMessages = [];
      this.successMessages = [];

      // 文字起こしテキスト編集APIを呼び出す
      const { status, data } = await editRecordTextApi(
        {
          summary_id: this.currentVoiceFileSummaryId,
          part: this.currentPart,
        },
        {
          data: [
            {
              speech_order: speechOrder,
              voice_text: editText,
            },
          ],
        },
      );

      // 業務エラーまたはバリデーションエラーの場合
      // エラーメッセージを表示して処理終了
      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // スクロール位置を取得
      const scrollTop = document.getElementById('scrollable').scrollTop;
      // 現在表示しているPartを取得
      const part = this.currentPart;
      // dataの初期化
      Object.assign(this.$data, initialState());
      // 記録詳細を再取得
      await this.fetchRecord(part);
      // 成功メッセージを表示する
      this.successMessages = data.detail.map(item => item.msg);
      // スクロールを元の位置に設定する
      this.$nextTick(() => {
        document.getElementById('scrollable').scrollTop = scrollTop;
      });
      this.stopLoading();
    },

    /**
     * 一覧に戻る
     */
    backToList() {
      // 記録一覧画面で検索条件を復元する
      this.setRestoreFlagOn();
      this.$router.push({ name: 'VDX100' });
    },

    /**
     * 前の記録を表示
     */
    prevRecord() {
      this.prevIndex();
      this.$router.push({
        name: 'VDX110',
        params: { id: this.prevVoiceFileSummaryId },
      });
    },

    /**
     * 次の記録を表示
     */
    nextRecord() {
      this.nextIndex();
      this.$router.push({
        name: 'VDX110',
        params: { id: this.nextVoiceFileSummaryId },
      });
    },

    /**
     * 表示Partを変更する
     */
    async changePart(part) {
      this.startLoading();
      // dataの初期化
      Object.assign(this.$data, initialState());
      // 記録詳細取得
      await this.fetchRecord(part);
      // 音声再生プレイヤーを初期化する
      this.$refs.audioPlayer?.initialize();
      this.stopLoading();
    },

    /**
     * 「会話要約とToDoをAI生成」のモーダル処理
     */
    onAiGeneration() {
      this.$refs.modalAiGeneration?.openModal();
    },

    /**
     * 「情報アイコン」のモーダル処理
     */
    onAiInformation() {
      this.$refs.modalAiInformation?.openModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
  }

  .messages {
    margin: $spacing-xxs;
    margin-bottom: 0;
  }
}

.menu {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-xs;
  border-bottom: 1px solid $gray400;

  > .button {
    height: 16px;
    padding: 0;
  }

  > .right {
    display: flex;
    align-items: center;

    > .button-delete {
      margin-left: $spacing-xs;
    }

    :deep(.pagination-block) {
      > .button-block {
        margin-left: 112px;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 1px;
          height: 16px;
          top: 50%;
          left: -56px;
          transform: translateY(-50%);
          background-color: $gray500;
        }
      }
    }
  }
}

.body {
  padding: $spacing-xxs $spacing-lg $spacing-lg;
  margin-bottom: 54px; // 音声再生プレイヤーの高さ分の余白
  overflow-y: scroll;

  > .chat-list {
    margin-top: $spacing-lg;
    flex: 1;
  }

  > .tab-part {
    margin-top: $spacing-xxs;
  }

  > .text {
    margin-top: $spacing-lg;
    font: $sans-none-14;
    color: $black700;

    &-normal {
      font: $sans-normal-14;
      margin-top: $spacing-lg;
      color: $black700;
    }

    &-green {
      margin-top: $spacing-xxs;
      font: $sans-none-14;
      color: $green500;
    }
  }
}
</style>
