<template>
  <IconBase :size="size">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8199 13.0316C16.3326 13.9064 15.6891 15 14.665 15H1.33484C0.308813 15 -0.331798 13.9047 0.179925 13.0316L6.84509 1.65584C7.35806 0.780594 8.64287 0.78218 9.15492 1.65584L15.8199 13.0316ZM8 10.6797C7.29431 10.6797 6.72223 11.2428 6.72223 11.9375C6.72223 12.6322 7.29431 13.1953 8 13.1953C8.7057 13.1953 9.27778 12.6322 9.27778 11.9375C9.27778 11.2428 8.7057 10.6797 8 10.6797ZM6.78687 6.15851L6.99292 9.87726C7.00256 10.0513 7.14873 10.1875 7.32576 10.1875H8.67426C8.85128 10.1875 8.99745 10.0513 9.00709 9.87726L9.21314 6.15851C9.22356 5.97055 9.07153 5.8125 8.88031 5.8125H7.11967C6.92845 5.8125 6.77645 5.97055 6.78687 6.15851Z"
        fill="#DE3618"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';
export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
