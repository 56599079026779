<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="actions">
      <ButtonBack @click="backToList" />
    </div>
    <div
      v-if="isContents"
      class="conts-wrap"
    >
      <div class="header">
        <h1 class="title">
          取引先編集
        </h1>
        <Button
          v-if="hasAuthDeleteSupplierAPI"
          size="inline"
          type="delete"
          @click="$refs.modalDelete?.openModal()"
        >
          取引先を削除
        </Button>
      </div>
      <div class="base">
        <div class="block-row">
          <FormBlock label="会社名">
            <FormInputText
              v-model="saveForm.company_name"
              :disabled="!hasAuthEditSupplierAPI"
            />
          </FormBlock>
        </div>
        <div class="block-row">
          <FormBlock label="電話番号">
            <FormInputText
              v-model="saveForm.tel_number"
              :disabled="!hasAuthEditSupplierAPI"
            />
          </FormBlock>
          <FormBlock
            label="表示名"
            info-text="電話番号の代わりに表示させる表示名になります。識別性が向上します。"
          >
            <FormInputText
              v-model="saveForm.display_name"
              :disabled="!hasAuthEditSupplierAPI"
            />
          </FormBlock>
        </div>

        <ButtonBlock
          v-if="hasAuthEditSupplierAPI"
          class="create-button-block"
        >
          <Button
            size="inline"
            type="normal"
            @click="$router.push({ name: 'VDXD00' })"
          >
            キャンセル
          </Button>
          <Button
            size="inline"
            type="primary"
            :disabled="!isDisabled"
            @click="onCreate"
          >
            変更を保存
          </Button>
        </ButtonBlock>
      </div>
    </div>

    <Modal ref="modalDelete">
      <div class="title">
        取引先を削除
      </div>
      <p class="text">
        取引先（{{ saveForm.company_name }}{{ saveForm.display_name }}）を削除しますか？
      </p>
      <ButtonBlock
        class="create-button-block"
      >
        <Button
          type="normal"
          size="inline"
          @click="$refs.modalDelete?.hideModal()"
        >
          キャンセル
        </Button>
        <Button
          type="delete"
          size="inline"
          @click="onDelete"
        >
          削除する
        </Button>
      </ButtonBlock>
    </Modal>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonBack from '@/components/button/ButtonBack';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutMain from '@/components/layout/LayoutMain';
import Modal from '@/components/common/Modal';
import { getUserRoleAuthsApi, deleteSupplierApi, getSupplierApi, editSupplierApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    Button,
    ButtonBack,
    FormBlock,
    FormInputText,
    ButtonBlock,
    MessageBar,
    Modal,
  },
  data() {
    return {
      /**
       * saveForm
       */
      saveForm: {
        company_name: '',
        display_name: '',
        tel_number: '',
      },

      /**
       * 権限一覧
       */
      authorizations: [],

      /**
       * 取引先詳細画面に表示するエラーメッセージ
       */
      errorMessages: [],

      /**
       * contents 表示・非表示フラグ
       */
      isContents: false,
    };
  },
  computed: {
    /**
     * 権限が無い場合
     * ・取引先を追加ボタンを非表示
     */
    hasAuthDeleteSupplierAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DELETE_SUPPLIER);
    },
    /**
     * 権限が無い場合
     * ・「変更を保存」ボタンを非表示
     */
    hasAuthEditSupplierAPI() {
      return hasAuthorization(this.authorizations, API_IDS.EDIT_SUPPLIER);
    },

    /**
     * 「変更を保存」ボタンのdisabled制御
     */
    isDisabled() {
      return this.saveForm.company_name.length && this.saveForm.display_name.length && this.saveForm.tel_number.length;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setStoreMessages',
    ]),

    async init() {
      this.startLoading();
      // 権限取得
      await this.getUserRoleAuths();

      // 取引先詳細取得API
      const { status, data } = await getSupplierApi({
        supplier_id: this.$route.params.id, 
      });

      if (status === 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.saveForm.company_name = data.company_name;
      this.saveForm.display_name = data.display_name;
      this.saveForm.tel_number = data.tel_number;
      this.isContents = true;
      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.DELETE_SUPPLIER,
          API_IDS.EDIT_SUPPLIER,
        ],
      });
      this.authorizations = data.data;
    },

    /**
     * 取引先一覧に戻る
     */
    backToList() {
      this.$router.push({ name: 'VDXD00' });
    },

    /**
     * 取引先削除確認モーダルの「削除する」ボタンのクリックイベント
     */
    async onDelete() {
      this.startLoading();
      const { status, data } = await deleteSupplierApi({ supplier_id: this.$route.params.id });

      if (status === 400 || status === 422) {
        this.$refs.modalDelete?.hideModal();
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDXD00' });
      this.stopLoading();
    },

    /**
     * 「変更を保存」ボタンのクリックイベント
     */
    async onCreate() {
      this.startLoading();

      const { status, data } = await editSupplierApi(
        { supplier_id: this.$route.params.id },  
        this.saveForm,
      );

      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDXD00' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.conts-wrap {
  padding: $spacing-xs $spacing-xlg;
}
.message-bar {
  margin: $spacing-xxs $spacing-xxs 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 47px;
  border-bottom: 1px solid $gray400;
  padding: 0 $spacing-xs;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 42px;
  border-bottom: 2px solid $gray400;
  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}

.form-input-text{
  width: 346px;
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  align-items: center;

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
  }
  > .form-block {
    margin-right: $spacing-md;
    &:last-child {
      margin-right: 0;
    }
  }
  > .form-label {
    font: $sans-none-16-bold;
    color: $black700;
  }
}

.create-button-block {
  margin-top: $spacing-sm;
}
</style>
