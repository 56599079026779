<template>
  <li class="listitem-keyword">
    <a
      class="item"
      @click="onClick"
    >
      <div class="keyword">
        {{ keyword }}
      </div>
      <div class="similar-keywords">
        {{ similarKeywords }}
      </div>
      <div class="create-date">
        {{ createDate }}
      </div>
    </a>
  </li>
</template>

<script>

export default {
  props: {
    keywordId: {
      type: String,
      default: '',
    },
    keyword: {
      type: String,
      default: '',
    },
    similarKeywords: {
      type: String,
      default: '',
    },
    createDate: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click', this.keywordId);
    },
  },
};
</script>

<style lang="scss" scoped>
.listitem-keyword {
  border-bottom: 1px solid $gray400;
  background-color: $white100;
  position: relative;

  .item {
    display: flex;
    text-decoration: none;
    align-items: center;
    cursor: pointer;

    > .keyword,
    .similar-keywords,
    .create-date {
      font: $sans-none-14;
      color: $black700;
      height: 40px;
      line-height: 40px;
    }

    > .keyword.failed {
      color: $red300;
    }

    > .keyword,
    .similar-keywords,
    .create-date {
      flex-basis: 16%;
      flex-grow: 0;
      flex-shrink: 0;
      overflow: hidden;
      white-space: nowrap;
    }
    > .keyword{
        flex-basis: 306px;
        text-overflow: ellipsis;
    }
    > .similar-keywords{
        flex-basis: calc(100% - 408px);
        text-overflow: ellipsis;
    }
    > .create-date {
        flex-basis: 102px;
        text-align: right;
        padding-right: $spacing-xxs;
    }
  }
}
</style>
