<template>
  <svg
    :class="{ 'is-disabled': disabled }" 
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0833 6.58333V9.5C10.0833 9.82083 9.82083 10.0833 9.5 10.0833H2.5C2.17917 10.0833 1.91667 9.82083 1.91667 9.5V6.58333C1.91667 6.2625 1.65417 6 1.33333 6C1.0125 6 0.75 6.2625 0.75 6.58333V10.0833C0.75 10.725 1.275 11.25 1.91667 11.25H10.0833C10.725 11.25 11.25 10.725 11.25 10.0833V6.58333C11.25 6.2625 10.9875 6 10.6667 6C10.3458 6 10.0833 6.2625 10.0833 6.58333ZM6.58333 6.39083L7.68 5.29417C7.9075 5.06667 8.275 5.06667 8.5025 5.29417C8.73 5.52167 8.73 5.88917 8.5025 6.11667L6.40833 8.21083C6.18083 8.43833 5.81333 8.43833 5.58583 8.21083L3.49167 6.11667C3.26417 5.88917 3.26417 5.52167 3.49167 5.29417C3.71917 5.06667 4.08667 5.06667 4.31417 5.29417L5.41667 6.39083V1.33333C5.41667 1.0125 5.67917 0.75 6 0.75C6.32083 0.75 6.58333 1.0125 6.58333 1.33333V6.39083Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.is-disabled path {
  fill: $black300;
}
</style>
