<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="20"
    fill="#7e7e84"
  >
    <path d="M11.708 15.833H5.854v-1.75h5.854q1.292 0 2.25-.791.959-.792.959-2.021 0-1.229-.959-2.031-.958-.802-2.25-.802H6.667l2.125 2.124-1.23 1.23-4.229-4.23 4.229-4.229 1.23 1.229-2.125 2.126h5.041q2.021 0 3.49 1.302 1.469 1.302 1.469 3.26 0 1.958-1.469 3.271-1.469 1.312-3.49 1.312Z" />
  </svg>
</template>
