<template>
  <div
    class="button-block"
    :class="['button-block-' + layout]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      default: 'center',
    },
  },
};
</script>

<style lang="scss" scoped>
.button-block {
  display: flex;
  flex-flow: wrap;

  :deep(.button) {
    & + .button {
      margin-left: $spacing-xxs;
    }
  }

  &.button-block-left {
    justify-content: flex-start;
  }

  &.button-block-center {
    justify-content: center;
  }

  &.button-block-right {
    justify-content: flex-end;
  }
}
</style>
