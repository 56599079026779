<template>
  <div
    class="message-bar"
    :class="['message-bar-' + type]"
  >
    <IconCheck v-if="type === 'success'" />
    <IconError v-if="type === 'error'" />
    <div class="container">
      <p
        v-for="(message, index) in messages"
        :key="index"
        class="message"
      >
        {{ message }}
      </p>
      <p
        v-if="description"
        class="description"
      >
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import IconCheck from '@/components/icons/IconCheck';
import IconError from '@/components/icons/IconError';

export default {
  components: {
    IconCheck,
    IconError,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ['success', 'error', 'info', 'warn'].includes(value);
      },
    },
    messages: {
      type: Array,
      default: () => [],
    },
    description : {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.message-bar {
  padding: $spacing-xxxs;
  display: flex;
  align-items: center;
  border-radius: $radius4;

  > .icon-lineup {
    margin-right: $spacing-xxxxs;
  }

  > .container {
    word-break: break-all;
    > .message {
      font: $sans-snug-14-bold;
      text-align: left;
    }
  }

  &-success {
    background-color: $green100;

    > .container {
      > .message {
        color: $green500;
      }
    }
  }

  &-error {
    background-color: $red100;

    > .container {
      > .message {
        color: $red300;
      }
    }
  }

  &-info {
    background-color: $black100;
    > .container {
      > .message {
        color: $black600;
      }
    }
  }

  &-warn {
    background-color: $yellow300;
    > .container {
      > .message {
        color: $black600;
      }
    }
  }
}
</style>
