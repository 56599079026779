<template>
  <div class="header">
    <div
      v-if="isShowCheckbox"
      class="header-item checkbox"
    >
      <VoiceCheckbox
        v-model="isCheckAll"
        :has-hover-action="false"
      />
    </div>
    <div class="header-item calling-number">
      発信
    </div>
    <div class="header-item incoming-number">
      着信
    </div>
    <div class="header-item calling-time">
      通話時間
    </div>
    <div class="header-item text">
      通話内容
    </div>
    <div class="header-item call-start-datetime">
      通話開始日時
    </div>
  </div>
  <ul
    ref="scrollable"
    class="list-voice"
  >
    <ListItemVoice
      v-for="item of items"
      :id="item.voice_file_summary_id"
      :key="item.voice_file_summary_id"
      v-model:checkedIds="checkedIdsModelValue"
      :calling-number="item.calling_number"
      :incoming-number="item.incoming_number"
      :calling-name="item.calling_name"
      :incoming-name="item.incoming_name"
      :calling-time="item.calling_time"
      :is-partition="item.is_partition"
      :text="item.text"
      :call-start-datetime="item.call_start_datetime"
      :status="item.status"
      :disabled="!item.is_deletable"
      :is-show-checkbox="isShowCheckbox"
      @click="onClick"
    />
  </ul>
</template>

<script>
import ListItemVoice from '@/components/voice/ListItemVoice';
import VoiceCheckbox from '@/components/voice/VoiceCheckbox';

export default {
  components: {
    ListItemVoice,
    VoiceCheckbox,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    checkedIds: {
      type: Array,
      default: () => [],
    },
    isShowCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'update:checkedIds'],
  computed: {
    checkedIdsModelValue: {
      get() {
        return this.checkedIds;
      },
      set(newValue) {
        this.$emit('update:checkedIds', newValue);
      },
    },
    /**
     * 全選択チェックボックス
     */
    isCheckAll: {
      /**
       * 全選択チェックボックスの値
       *
       * true チェックする / false チェックしない
       */
      get() {
        if (this.checkedIdsModelValue.length === 0) return false;
        // 分割ファイルで受信中が含まれる記録は選択不可のため
        // 選択可能なチェックボックスがすべてチェックされている場合はtrueを返却
        const deletables = this.items.filter(item => item.is_deletable);
        if (this.checkedIdsModelValue.length === deletables.length) {
          return true;
        } else {
          return false;
        }
      },
      /**
       * チェックボックスの値が更新された時に実行される
       */
      set(value) {
        let checkArray = [];
        // チェックがされた場合
        if (value) {
          // 選択可能なチェックボックスをすべてチェックする
          checkArray = this.items.filter(item => item.is_deletable).map(item => item.voice_file_summary_id);
        }
        this.checkedIdsModelValue = checkArray;
      },
    },
  },
  watch: {
    /**
     * propsのitemsが更新されたら実行される
     */
    items() {
      // 一覧のスクロールを最上部に戻す
      if (this.$refs.scrollable) {
        this.$refs.scrollable.scrollTop = 0;
      }
      // チェックボックスの初期化
      this.checkedIdsModelValue = [];
    },
  },
  methods: {
    onClick(id) {
      this.$emit('click', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  background-color: $white100;
  border-bottom: 2px solid $gray400;
  padding: 0 $spacing-xxs;
  overflow-y: scroll;
  min-height: 31px;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb{
    background: none;
  }

  > .header-item  {
    color: $black400;
    font: $sans-none-12-bold;
    text-align: left;
    height: 29px;
    line-height: 29px;
  }

  > .checkbox {
    width: 34px;
    display: flex;
    align-items: center;
  }
  > .calling-number {
    width: 190px;
  }
  > .incoming-number {
    width: 190px;
  }
  > .calling-time {
    width: 75px;
  }
  > .text {
    flex: 1; // 横幅可変
  }
  > .call-start-datetime {
    width: 160px;
  }
}

.list-voice {
  overflow-y: scroll;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track{
    background-color: none;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: $gray600;
  }
}
</style>
