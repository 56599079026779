<template>
  <div class="layout-main">
    <Header />
    <NavigationTop @refresh="$emit('refresh')" />
    <div class="container">
      <NavigationSide @refresh="$emit('refresh')" />
      <main class="main">
        <slot />
      </main>
    </div>
    <Loading
      v-if="isLoading"
    />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading.vue';
import Header from '@/components/layout/Header.vue';
import NavigationSide from '@/components/layout/NavigationSide.vue';
import NavigationTop from '@/components/layout/NavigationTop.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Header,
    NavigationTop,
    NavigationSide,
    Loading,
  },
  emits: ['refresh'],
  computed: {
    ...mapGetters(['isLoading']),
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  height: 100%;
  width: 100%;

  > .container {
    height: calc(100% - 121px);
    width: 100%;
    min-width: 1280px;
    padding: 0 $spacing-sm;
    display: flex;

    > .main {
      width: calc(100% - 180px);
    }
  }
}
</style>
