<template>
  <LayoutEntry>
    <div class="title">
      権限がありません
    </div>
    <p class="text">
      この機能を利用する権限がありません。管理者にお問合せください。<br>
      <br>
      <a
        class="text text-link"
        @click="$router.push({name: 'VDX100'})"
      >トップに戻る</a>
    </p>
  </LayoutEntry>
</template>

<script>
import LayoutEntry from '@/components/layout/LayoutEntry';

export default {
  components: {
    LayoutEntry,
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  > :deep(.container) {
    width: 386px;
  }
}
</style>
