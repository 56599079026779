<template>
  <LayoutEntry>
    <div class="title">
      タイムアウト
    </div>
    <p class="text">
      現在、処理が重くなっています<br>
      恐れ入りますが、しばらくしてから再度ご接続ください
    </p>
  </LayoutEntry>
</template>

<script>
import LayoutEntry from '@/components/layout/LayoutEntry';

export default {
  components: {
    LayoutEntry,
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  > :deep(.container) {
    width: 386px;
  }
}
</style>
