<template>
  <div class="search-message-box">
    <div class="container">
      <p
        v-for="(message, index) in messages"
        :key="index"
        class="message"
      >
        {{ message }}
      </p>
      <p
        v-if="description"
        class="description"
      >
        {{ description }}
      </p>
    </div>
    <div
      v-if="isShowDownloadBtn"
      class="download-btn-container"
    >
      <Button
        class="download-btn"
        size="inline"
        type="normal"
        :disabled="disabled"
        @click="$emit('download', DOWNLOAD_TYPE.SEARCH_DOWNLOAD)"
      >
        検索結果のダウンロード
      </Button>
      <p
        v-if="disabled"
        class="download-description"
      >
        {{ downloadMsg }}
      </p>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/Button';
import { DOWNLOAD_TYPE } from '@/utils/Constants';

export default {
  components: {
    Button,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    description : {
      type: String,
      default: '',
    },
    disabled : {
      type: Boolean,
      default: false,
    },
    downloadMsg : {
      type: String,
      default: '',
    },
    isShowDownloadBtn : {
      type: Boolean,
      default: false,
    },
  },
  emits: ['download'],
  computed: {
    DOWNLOAD_TYPE() {
      return DOWNLOAD_TYPE;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-message-box {
  padding: $spacing-xxxs;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: $radius4;
  background-color: $green100;
  box-sizing: border-box;

  > .container {
    word-break: break-all;
    > .message {
      font: $sans-snug-14-bold;
      text-align: left;
      color: $green500;
    }

    > .description {
      font: $sans-tight-14;
      color: $black500;
      text-align: left;
      margin-top: $spacing-xxxxs;
    }
  }

  > .download-btn-container {
    display: flex;
    min-width: 188px;
    max-width: 188px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;

    > .download-btn {
      border: 1px solid $black500;
      padding: 11px 16px;

      &.is-disabled {
        cursor: not-allowed;
        background-color: $gray500;
        border: none;
      }
    }

    > .download-description {
      font: $sans-tight-12;
      color: $black600;
      text-align: left;
      width: 186px;
      margin-top: 3px;
    }
  }
}
</style>
