<template>
  <label
    class="form-select-label"
    :class="[
      size,
      {
        'is-disabled': disabled,
        'is-error': isError,
        'is-focus': isFocus,
      },
    ]"
  >
    <select
      v-model="localValue"
      class="form-select"
      :disabled="disabled"
      @change="updateValue"
      @focus="inputFocus"
      @blur="inputBlur"
      @click="inputFocus"
    >
      <option
        v-for="(option, i) in convertedOptions"
        :key="i"
        :value="option.id"
      >
        {{ option.label }}
      </option>
    </select>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '',
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'label',
    },
  },
  emits: ['update:modelValue'],
  data () {
    return {
      isFocus: false,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.inputBlur();
      },
    },
    convertedOptions() {
      return this.options.map((option) => ({
        id: option[this.valueKey],
        label: option[this.labelKey],
      }));
    },
  },
  methods: {
    inputFocus() {
      this.isFocus = true;
    },
    inputBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style lang="scss">
.form-select-label {
  display: inline-block;
  position: relative;
  width: 100%;

  &.width-180 {
    width: $form-size-180;
  }

  &.width-260 {
    width: $form-size-260;
  }

  &.width-320 {
    width: $form-size-320;
  }

  &.width-400 {
    width: $form-size-400;
  }

  &.is-error {
    .form-select {
      color: $black700;
      background: $red100;
      border-color: $red300;
    }
  }

  &.is-disabled {
    .form-select {
      background: $gray200;
      border-color: $gray200;
      color: $black400;
      opacity: 1.0;
    }
  }

  &.is-focus {
    .form-select {
      border-color: $black400;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $spacing-xxxs;
    width: 16px;
    height: 16px;
    background: url(../../assets/images/icon-arrow-select.svg) 0 0 no-repeat;
    pointer-events: none;
  }
}

.form-select {
  display: block;
  width: 100%;
  flex-grow: 1;
  font: $sans-none-16;
  height: $input-height;
  line-height: $input-height;
  padding: 0 $spacing-xxxs;
  background: $white100;
  box-sizing: border-box;
  border: 1px solid $gray600;
  outline: none;
  border-radius: $radius4;
  color: $black700;
  appearance: none;
  -webkit-appearance: none;
}
</style>
