<template>
  <button
    class="button-save"
    @click="onClick"
  >
    保存
  </button>
</template>

<script>
export default {
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-save {
  width: 84px;
  height: 24px;
  padding: 0px;
  background-color: $primary500;
  border-radius: 12px;
  font: $sans-none-12;
  color: $white100;
  &:hover {
    background-color: $primary600;
  }
}
</style>
