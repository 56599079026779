<template>
  <Modal
    ref="modalAiInformation"
    class="modalAiInformation"
  >
    <div class="container">
      <div class="title">
        <ButtonClose
          class="button-close"
          @click="hideModal"
        />
        <IconInformation
          class="title-icon"
        />要約、ToDo機能の注意点について
      </div>
      <div class="context">
        <ul class="context-list">
          <li class="list-style-none">
            要約、ToDo機能については下記の注意点がございます。予めご確認の上、ご利用いただくようお願いいたします。
          </li>
          <li class="list-style-none">
            &lt;要約、ToDo機能の注意点&gt;
          </li>
          <li>
            本機能は生成AIを用いており、誤った回答が返ってくる可能性がございます。あくまでアシストとしてご利用ください。
          </li>
          <li>
            要約・ToDoの結果の完全性、正確性、確実性又は有用性については保証されていないことを理解したうえでご利用ください。
          </li>
          <li>
            以下のような場合、要約・ToDoが生成されずエラーが発生する可能性がございます。
            <br>
            - テキスト内に時事的な情報が含まれている場合<br>
            - 一定期間に利用が集中した場合<br>
            - テキスト内に不適切な文言が含まれている場合
            <span class="notice">尚、上記以外の通常の会話においてもエラーが発生する可能性がある点をご了承ください。</span>
          </li>
          <li>
            エラー発生時、テキスト編集時等は、再生成ボタンを押下いただくことで結果が表示されます。
          </li>
          <li>
            再生成は1日につき10回まで可能です。再生成回数は一日後にリセットされます。
          </li>
          <li>
            以下に該当する場合、正しく要約・ToDoが表示されない場合があります。生成結果を活用される際に適宜編集してください。<br>
            - 会話の通話時間が短く、情報量が十分でない場合<br>
            - 会話の内容にToDoがない場合<br>
            - テキスト化自体の精度がよくない場合
            <span class="notice">尚、上記以外の通常の会話においても正しく要約・ToDoが表示されない場合がある点をご了承ください。</span>
          </li>
          <li>
            40分以上の通話の場合は40分までの通話の内容で要約、ToDoが生成されることがあります。
          </li>
        </ul>
      </div>
      <ButtonBlock>
        <Button
          type="normal"
          size="inline"
          @click="hideModal"
        >
          閉じる
        </Button>
      </ButtonBlock>
    </div>
  </Modal>
</template>

<script>
import IconInformation from '@/components/icons/IconInformation';
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonClose from '@/components/button/ButtonClose';
import Modal from '@/components/common/Modal';

export default {
  components: {
    IconInformation,
    Button,
    ButtonBlock,
    ButtonClose,
    Modal,
  },
  props: {
    canCloseByOutside: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    openModal() {
      this.$refs.modalAiInformation.openModal();
    },
    hideModal() {
      this.$refs.modalAiInformation.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modalAiInformation {
  :deep(> .container) {
    width: 592px;
    .title {
      font: $sans-none-20-bold;
      color: $black700;
      margin-bottom: $spacing-xs;
      padding: 0 $spacing-xs;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .title-icon {
        display: inline-block;
        vertical-align: middle;
        width: 22px;
        min-width: 22px;
        height: 22px;
        margin-right: 8px;
        fill: $black700;
      }
      .button-close {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
    .context {
      font: $sans-tight-14;
      font-weight: 300;
      color: $black600;
      margin-bottom: $spacing-xs;
      text-align: left;
      &-list {
        > li {
          position: relative;
          padding-left: $spacing-xxs;
          margin-bottom: $spacing-xxxs;
          &:last-child {
            margin-bottom: 0;
          }
          &::after {
            content: "・";
            position: absolute;
            top: 0;
            left: 0;
          }
          &.list-style-none::after {
            display: none;
          }
          .notice {
            display: block;
            position: relative;
            padding-left: $spacing-xxxs;
            display: block;
            &::after {
              content: "※";
              position: absolute;
              top: 0;
              left: -4px;
            }
          }
        }
      }
    }
  }
}
</style>
