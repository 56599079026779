<template>
  <div class="loading">
    <div class="position">
      <div class="loader" />
      <p
        v-if="text"
        class="text"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($white100, $opacity95);
  z-index: $z-loading;

  > .position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    > .loader {
      width: 64px;
      height: 64px;
      margin: 0 auto;
      box-sizing: border-box;
      border-radius: 50%;
      border-top: 6px solid $gray500;
      border-right: 6px solid $gray500;
      border-bottom: 6px solid $gray500;
      border-left: 6px solid $primary600;
      animation: load 1.1s infinite linear;
    }

    @keyframes load {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    > .text {
      font: $sans-none-16;
      color: $black700;
      margin-top: $spacing-xs;
    }
  }
}
</style>
