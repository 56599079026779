<template>
  <div class="parts-area">
    <p
      v-if="isPartition"
      class="text-partition"
    >
      通話が1時間を超えるため、記録が分割されます
    </p>
    <p
      v-if="isReceiving"
      class="text-receiving"
    >
      音声データを受信中...
    </p>
    <TabPart
      v-if="isPartition"
      :items="parts"
      :model-value="currentPart"
      @change="(part) => $emit('change', part)"
    />
  </div>
</template>

<script>
import TabPart from '@/components/voice/TabPart';

export default {
  components: {
    TabPart,
  },
  props: {
    isPartition: {
      type: Boolean,
      default: false,
    },
    isReceiving: {
      type: Boolean,
      default: false,
    },
    parts: {
      type: Array,
      default: () => [],
    },
    currentPart: {
      type: Number,
      default: 1,
    },
  },
  emits: ['change'],
};
</script>

<style lang="scss" scoped>
.parts-area {
  > .text-partition {
    margin-bottom: $spacing-xxs;
    font: $sans-none-14;
    color: $black700;
  }

  > .text-receiving {
    margin-bottom: $spacing-xxs;
    font: $sans-none-14;
    color: $green500;
  }
}
</style>
