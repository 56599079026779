<template>
  <div class="layout-entry">
    <Header />
    <slot name="textBar" />
    <div class="container">
      <slot />
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import Loading from '@/components/common/Loading.vue';
import Header from '@/components/layout/Header.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Header,
    Loading,
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  height: 100%;
  width: 100%;
  text-align: center;

  > .container {
    border: 1px solid $gray600;
    padding: $spacing-sm;
    margin-top: $spacing-sm;
    margin-bottom: $spacing-xxlg;
    display: inline-block;
    border-radius: $radius8;
    border: 1px solid $gray600;
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
    text-align: left;

    > :slotted(.message-bar) {
      margin-bottom: $spacing-xs;
    }

    > :slotted(.title) {
      font: $sans-none-20-bold;
      color: $black700;
      text-align: center;
    }

    > :slotted(.text) {
      font: $sans-tight-16;
      color: $black700;
      margin-top: $spacing-xs;

      > .text-link {
        color: $primary500;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    > :slotted(.activate-code) {
      margin-top: $spacing-xs;
    }

    > :slotted(.button-block) {
      margin-top: $spacing-xs;
    }

    > :slotted(.form-block) {
      margin-top: $spacing-xs;
    }

    > :slotted(.form-text) {
      margin-top: $spacing-xxxxs;
      font: $sans-tight-14;
      color: $black900;
    }

    > :slotted(.form-row) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: $spacing-xs;

      > .label {
        width: 100%;
        font: $sans-none-16-bold;
        color: $black700;
        margin-bottom: $spacing-xxxxs;
      }
    }
  }
}
</style>
