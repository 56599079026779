<template>
  <LayoutEntry>
    <MessageBar
      v-if="errorMessages.length > 0"
      type="error"
      :messages="errorMessages"
    />
    <div class="title">
      パスワード再設定
    </div>

    <template v-if="!isSendMailComplete">
      <p class="text">
        以下にアカウントのメールアドレスを入力し[メール送信]ボタンをクリックしてください。<br>
        設定案内メールが送信されます。
        <span>
          メールが届かない場合、ご利用中のメールサービス提供元（通信キャリア・プロバイダ等）の迷惑メール制限や受信拒否等によりメールが受信不可となっている可能性があります。ドメイン「voice-portal.kddi.com」からのメールが受信できるよう設定をご確認ください。
        </span>
      </p>
      <FormBlock label="メールアドレス">
        <FormInputText
          v-model="emailAddress"
          size="width-320"
        />
      </FormBlock>
      <ButtonBlock>
        <Button
          :disabled="!isDisabled"
          @click="sendMail"
        >
          メール送信
        </Button>
      </ButtonBlock>
      <ButtonBlock class="back-button">
        <Button
          type="text"
          @click="backStep"
        >
          戻る
        </Button>
      </ButtonBlock>
    </template>
    <template v-else>
      <p class="text">
        パスワード再発行メールを送信しました。<br>
        メールに記載のURLをクリックしてパスワードを再設定してください。<br>
        しばらく経ってもメールが届かない場合、下記をご確認ください。
      </p>
      <ul class="text_notice">
        <li>入力されたメールアドレスに誤りがないか</li>
        <li>迷惑メールフォルダに振り分けられていないか</li>
      </ul>
    </template>
  </LayoutEntry>
</template>

<script>
import Button from '@/components/button/Button.vue';
import ButtonBlock from '@/components/button/ButtonBlock.vue';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutEntry from '@/components/layout/LayoutEntry';
import { requestPasswordReset } from '@/utils/ApiHelper';
import { hasIdToken } from '@/utils/IdTokenManager';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutEntry,
    MessageBar,
    FormBlock,
    FormInputText,
    ButtonBlock,
    Button,
  },
  data() {
    return {
      /**
       * エラーメッセージ
       */
      errorMessages: [],

      /**
       * メールアドレス
       */
      emailAddress: '',

      /**
       * メール送信後用のDOM切り替え
       */
      isSendMailComplete: false,
    };
  },
  computed: {
    isDisabled() {
      return this.emailAddress.length;
    },
  },
  mounted() {
    // ログイン済みの場合はホーム画面にリダイレクト
    if (hasIdToken()) {
      this.$router.push({ name: 'VDX100' });
      return;
    }
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading']),
    async sendMail() {
      this.startLoading();

      // メッセージクリア
      this.errorMessages = [];

      // パスワード再設定メール送信APIを呼び出す
      const { status, data } = await requestPasswordReset({
        email: this.emailAddress,
      });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map((item) => item.msg);
        this.stopLoading();
        return;
      }

      // パスワード再設定メール送信完了のDOM切り替え
      this.isSendMailComplete = true;

      this.stopLoading();
    },
    backStep() {
      // メールアドレス入力に戻る
      this.$router.push({ name: 'VDX050' });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  > :deep(.container) {
    width: 386px;
    > .text {
      font: $sans-tight-16;
      line-height: 21px;

      span {
        display: block;
        margin-top: $spacing-xs;
        padding-left: 17px;
        font: $sans-tight-14;
        line-height: 18px;
        position: relative;
        &::after {
          content: "※";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    > .text_notice {
      margin-top: $spacing-xs;
      > li {
        font: $sans-tight-16;
        line-height: 21px;
        position: relative;
        padding-left: $spacing-xs;
        color: $black700;
        &::after {
          content: "・";
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
    > .form-checkbox-block {
      text-align: center;
      margin-top: $spacing-xs;
      width: 100%;
      > * {
        margin: 0 auto;
      }
    }
    > .button-block {
      &.back-button {
        margin-top: $spacing-xxs;
      }
    }
  }
}
</style>
