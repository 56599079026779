<template>
  <div class="header">
    <div class="header-item func_name">
      機能名
    </div>
    <div class="header-item type_name">
      種別
    </div>
    <div class="header-item email_address">
      メールアドレス
    </div>
    <div class="header-item user_role_name">
      権限
    </div>
    <div class="header-item datetime">
      実行日時
    </div>
  </div>
  <ul
    ref="scrollable"
    class="list-log"
  >
    <ListItemLog
      v-for="item of items"
      :key="item.access_log_id"
      :func-name="item.func_name"
      :type-name="item.type_name"
      :email-address="item.email_address"
      :user-role-name="item.user_role_name"
      :operate-datetime="item.operate_datetime"
    />
  </ul>
</template>

<script>
import ListItemLog from '@/components/logtable/ListItemLog';

export default {
  components: {
    ListItemLog,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
  },
  watch: {
    /**
     * propsのitemsが更新されたら実行される
     */
    items() {
      // 一覧のスクロールを最上部に戻す
      if (this.$refs.scrollable) {
        this.$refs.scrollable.scrollTop = 0;
      }
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  background-color: $white100;
  border-bottom: 2px solid $gray400;
  overflow-y: scroll;
  min-height: 31px;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb{
    background: none;
  }

  > .header-item  {
    color: $black400;
    font: $sans-none-12-bold;
    text-align: left;
    height: 29px;
    line-height: 29px;
  }
  > .func_name {
    width: calc((100% - 520px) * 0.4);
  }
  > .type_name {
    width: 180px;
  }
  > .email_address {
    width: calc((100% - 520px) * 0.6);
  }
  > .user_role_name {
    width: 190px;
  }
  > .datetime {
    width: 150px;
  }
}

.list-log {
  overflow-y: scroll;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track{
    background-color: none;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: $gray600;
  }
}
</style>
