<template>
  <router-view />
</template>

<script>
import { APP_SERVICE_NAME } from '@/utils/Constants';

export default {
  watch: {
    '$route' (routeInstance) {
      document.title = `${routeInstance.meta.title} | ${APP_SERVICE_NAME}`;
    },
  },
};
</script>

<style lang="scss">
// ここに全画面で適応するスタイルを記述する
html, body, #app {
  height: 100%;
}
</style>
