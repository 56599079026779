<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 12.55V15.0833C0.5 15.3166 0.683333 15.5 0.916667 15.5H3.45C3.55833 15.5 3.66667 15.4583 3.74167 15.375L12.8417 6.28331L9.71667 3.15831L0.625 12.25C0.541667 12.3333 0.5 12.4333 0.5 12.55ZM15.2583 3.86664C15.5833 3.54164 15.5833 3.01664 15.2583 2.69164L13.3083 0.741644C12.9833 0.416644 12.4583 0.416644 12.1333 0.741644L10.6083 2.26664L13.7333 5.39164L15.2583 3.86664Z"
      fill="black"
      fill-opacity="0.5"
    />
  </svg>
</template>
