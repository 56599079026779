<template>
  <div
    class="search-field"
    :class="{'is-disabled': disabled}"
  >
    <div
      class="block"
    >
      <IconSearch
        class="icon-search"
      />
      <input
        v-model="value"
        type="text"
        class="form"
        placeholder="電話番号 / キーワード"
        :disabled="disabled"
        data-test="search-field-input"
        @input="input"
        @keydown.enter="trigger"
        @compositionstart="isCompositionFinished = false"
        @compositionend="isCompositionFinished = true"
      >
      <button
        v-if="isClearButton"
        class="button-clear"
        @click="clear"
      >
        <IconClose />
      </button>
    </div>
    <button
      class="button-advanced"
      :disabled="disabled"
      data-test="search-detail-button"
      @click="$emit('open')"
    >
      詳細検索
      <IconArrow />
    </button>
  </div>
</template>

<script>
import IconArrow from '@/components/icons/IconArrow';
import IconClose from '@/components/icons/IconClose';
import IconSearch from '@/components/icons/IconSearch';

export default {
  components: {
    IconArrow,
    IconSearch,
    IconClose,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue', 'enter', 'open'],
  data() {
    return {
      isCompositionFinished: true,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    isClearButton() {
      return this.modelValue !== '';
    },
  },
  methods: {
    clear() {
      this.value = '';
    },
    input(event) {
      // 半角かどうか判断
      if (event.inputType === 'insertText') {
        this.trigger(event);
      }
    },
    trigger(event) {
      // Safariかどうか判断
      const isSafari = navigator.userAgent.includes('Safari/') && navigator.userAgent.includes('Version/');
      if (isSafari && this.isCompositionFinished) {
        this.isCompositionFinished = false;
        return;
      }
      // Safari以外の変換確定時
      if (event.key !== 'Enter' || event.isComposing) {
        return;
      }
      this.$emit('enter');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  display: flex;
  height: 40px;
  width: 464px;
  border: 1px solid $gray600;
  box-sizing: border-box;
  border-radius: $radius8;

  > .block {
    display: flex;
    align-items: center;
    width: 344px;

    > .icon-search {
      margin-left: $spacing-xxs;
    }

    > .form {
      color: $black700;
      height: 100%;
      width: 100%;
      padding: 0;
      font: $sans-none-14;
      border: none;
      box-sizing: border-box;
      margin-left: $spacing-xxs;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $black300;
      }
    }

    > .button-clear {
      padding: 0;
      width: 16px;
      height: 16px;
      margin-right: $spacing-xxs;

      :deep(.icon-lineup):hover {
        > .icon {
          > svg path {
            fill: $black200;
          }
        }
      }
    }
  }

  > .button-advanced {
    color: $black700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    max-width: 120px;
    min-width: 120px;
    padding: 0;
    font: $sans-none-14;
    border-left: 1px solid $gray600;
    box-sizing: border-box;
    text-align: left;
    padding: 0 $spacing-xxs 0 $spacing-xs;
  }

  &.is-disabled {
    cursor: not-allowed;
    background-color: $gray400;

    > .block {
      > .icon-search {
        :deep(.icon) > svg path {
          fill: $black200;
        }
      }

      > .form {
        color: $black200;
        background-color: $gray400;

        &::placeholder {
          color: $black200;
        }

        &::-moz-placeholder {
          opacity: 1;
        }
      }
    }

    > .button-advanced {
      color: $black200;

      > .icon-lineup {
        :deep(.icon) > svg path {
          fill: $black200;
        }
      }
    }
  }
}
</style>
