<template>
  <IconBase :size="size">
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.51581 15.8492C8.87331 16.2067 9.45081 16.2067 9.80831 15.8492L14.0158 11.6417C14.3733 11.2842 14.3733 10.7067 14.0158 10.3492L9.80831 6.14169C9.45081 5.78419 8.87331 5.78419 8.51581 6.14169C8.15831 6.49919 8.15831 7.07669 8.51581 7.43419L12.0725 11L8.51581 14.5567C8.15831 14.9142 8.16748 15.5009 8.51581 15.8492V15.8492Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
