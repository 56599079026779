<template>
  <button
    class="button-copy"
    @click="onCopyText"
  >
    <IconCopy />コピー
    <transition name="fade-copied">
      <span
        v-if="isCopied"
        class="copied"
      >
        コピーしました
      </span>
    </transition>
  </button>
</template>

<script>
import IconCopy from '@/components/icons/IconCopy';

export default {
  components: {
    IconCopy,
  },
  props: {
    copyText: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isCopied: false,
  }),
  methods: {
    onCopyText() {
      // クリップボードにテキストをコピー
      navigator.clipboard.writeText(this.copyText);
      // テキスト「コピーしました」の表示
      if(!this.isCopied){
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 3000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-copy {
    width: 73px;
    height: 24px;
    padding: 0px;
    background-color: $black500;
    border-radius: 12px;
    border: none;
    min-width: inherit;
    min-height: inherit;
    display: inline-block;
    vertical-align: middle;
    font: $sans-none-12;
    color: $white100;
    position: relative;
    z-index: 0;
    &:hover {
        background-color: $black600;
    }
    &.is-disabled{
        background-color: $gray500;
        color: $black300;
        svg{
            fill: $black300;
        }
    }
    svg{
        display: inline-block;
        margin-right: 7px;
        vertical-align: text-top;
    }
    .copied{
      position: absolute;
      bottom: calc(100% + 2px);
      left: 6px;
      width: 129px;
      height: 54px;
      font: $sans-none-14;
      color: $black700;
      box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
      background: $white100;
      text-align: center;
      border-radius: $radius8;
      pointer-events: none;
      z-index: 1;
      line-height: 54px;
    }
}

.fade-copied-leave-from {
  opacity: 1;
}
.fade-copied-leave-active {
  transition: all .2s;
}
.fade-copied-leave-to {
  opacity: 0;
}
</style>
