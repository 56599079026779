<template>
  <IconBase :size="size">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2501 2.75843C12.9251 2.43343 12.4001 2.43343 12.0751 2.75843L8.0001 6.8251L3.9251 2.7501C3.6001 2.4251 3.0751 2.4251 2.7501 2.7501C2.4251 3.0751 2.4251 3.6001 2.7501 3.9251L6.8251 8.0001L2.7501 12.0751C2.4251 12.4001 2.4251 12.9251 2.7501 13.2501C3.0751 13.5751 3.6001 13.5751 3.9251 13.2501L8.0001 9.1751L12.0751 13.2501C12.4001 13.5751 12.9251 13.5751 13.2501 13.2501C13.5751 12.9251 13.5751 12.4001 13.2501 12.0751L9.1751 8.0001L13.2501 3.9251C13.5668 3.60843 13.5668 3.0751 13.2501 2.75843Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';
export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
