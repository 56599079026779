<template>
  <div class="table-list-supplier">
    <p
      v-if="!records.length"
      class="no-records"
    >
      取引先データはありません
    </p>
    <div
      v-else
      class="body"
    >
      <ListSupplier :items="records" />
    </div>
  </div>
</template>

<script>
import ListSupplier from '@/components/supplier/ListSupplier.vue';

export default {
  components: {
    ListSupplier,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.table-list-supplier {
  height: 100%;
  display: flex;
  flex-direction: column;
  > .body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  > .no-records {
    font: $sans-none-16;
    color: $black700;
  }
}
</style>
