<template>
  <div class="form-checkbox-block">
    <div
      v-for="(option, i) in options"
      :key="i"
    >
      <input
        :id="`${name}-${option[valueKey]}`"
        v-model="value"
        type="checkbox"
        class="input"
        :name="name"
        :value="option[valueKey]"
        :disabled="disabled"
      >
      <label
        :for="`${name}-${option[valueKey]}`"
        class="form-checkbox"
        :class="{
          'is-disabled': disabled,
          'is-error': isError,
        }"
      >
        <span class="form-checkbox-label">{{ option[labelKey] }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCheckbox',
  props: {
    modelValue: {
      type: [Boolean, Array],
      required: true,
    },
    options: {
      type: [Array, Object],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: 'check',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.form-checkbox-block {
  display: inline-flex;
  height: $form-radio-check;
  vertical-align: top;

  div {

    + div {
      margin-left: $spacing-xxxs;
    }
    .input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      border: 0;
      overflow: hidden;
      padding: 0;
      clip: rect(0, 0, 0, 0);

      + .form-checkbox {
        display: flex;
        align-items: center;
        position: relative;
        height: $form-radio-check;
        cursor: pointer;

        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: $form-radio-check;
          height: $form-radio-check;
          background-color: $white100;
          border: 1px solid $gray600;
          border-radius: $radius4;
          box-sizing: border-box;
          background-repeat: no-repeat;
        }

        &.is-error {
          &::before {
            background-color: $red100;
            border-color: $red300;
          }
        }

        &.is-disabled {
          cursor: not-allowed;

          &::before {
            background-color: $gray500;
            border-color: $gray500;
          }
        }

        > .form-checkbox-label {
          font: $sans-none-16;
          color: $black700;
          margin-left: 26px;
        }
      }

      &:checked {
        + .form-checkbox {
          &::before {
            background-color: $primary500;
            background-image: url(../../assets/images/icon-check-white.svg);
            background-repeat: no-repeat;
            background-size: 12px auto;
            background-position: top 3.5px left 2px;
            border-color: $primary500;
          }

          &.is-error {
            &::before {
              background-color: $red300;
              border-color: $red300;
            }
          }

          &.is-disabled {
            &::before {
              background-color: $gray500;
              background-image: url(../../assets/images/icon-check-black.svg);
              border-color: $gray500;
            }
          }
        }
      }
    }
  }
}
</style>
