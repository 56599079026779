<template>
  <li class="listitem-supplier">
    <a
      class="item-wrap"
      @click="toDetailPage(id)"
    >
      <div class="textblock company_name">
        {{ companyName }}
      </div>
      <div class="textblock display_name">
        {{ displayName }}
      </div>
      <div class="textblock tel_number">
        {{ telNumber }}
      </div>
      <time class="time">{{ createDate }}</time>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
    telNumber: {
      type: String,
      default: '',
    },
    createDate: {
      type: String,
      default: '',
    },
  },
  methods: {
    /**
     * 取引先詳細画面に遷移
     */
    toDetailPage(id) {
      this.$router.push({ name: 'VDXD20', params: { id } });
    },
  },
};

</script>

<style lang="scss" scoped>
.listitem-supplier {
  position: relative;
  border-bottom: 1px solid $gray400;
  height: 40px;
  width: 100%;
  color: $black700;

  .item-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-decoration: none;
    height: 100%;
    text-align: left;
    cursor: pointer;
    font: $sans-none-14;

    > .textblock {
      padding-right: $spacing-xxs;
      flex: 1; // 横幅可変
      width: 320px;
      line-height: 39px;
    }
    > .company_name,
    > .display_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > time {
      min-width: 116px;
      text-align: right;
      padding-right: 14px;
    }
  }
}
</style>
