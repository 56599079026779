<template>
  <div class="button-more-area">
    <IconMore
      class="icon-more"
      @click="toggle"
    />
    <div
      v-if="open"
      class="button-more"
    >
      <button
        v-if="buttonText"
        class="button"
        data-test="voice-download-button"
        @click="onClick"
      >
        {{ buttonText }}
      </button>
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
import IconMore from '@/components/icons/IconMore';

export default {
  name: 'ButtonCSVDownload',
  components: {
    IconMore,
  },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    window.addEventListener('click', this.closeDropDown);
  },
  unmounted() {
    window.removeEventListener('click', this.closeDropDown);
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
    toggle() {
      this.open = !this.open;
    },
    closeDropDown(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-more-area {
    position: relative;
  > .icon-more {
    cursor: pointer;
    position: relative;
    z-index: 100;
  }
  > .button-more {
    position: absolute;
    top: calc(100% + 20px);
    right: -40px;
    z-index: 100;
    background-color: $white100;
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
    height: auto;
    border-radius: $radius6;
    > ::v-deep .button {
      display: flex;
      align-items: center;
      justify-content: inherit;
      min-width: 180px;
      max-width: 180px;
      width: 100%;
      color: $black700;
      font: $sans-none-14;
      padding: 20px 16px;

      &:hover {
        background-color: $gray100;
      }

      &.is-disabled {
        background-color: $white100;
        border-color: $black400;
        color: $black400;
      }
    }
  }
}
</style>
