<template>
  <div class="talk-list">
    <TalkListItem
      v-for="(item, index) of data"
      :key="index"
      :calling-incoming-type="item.calling_incoming_type"
      :speech-phone-number="item.speech_phone_number"
      :speech-phone-name="item.speech_phone_name"
      :voice-text="item.voice_text"
      :keywords="keywords"
    />
  </div>
</template>

<script>
import TalkListItem from '@/components/voice/TalkListItem';

export default {
  components: {
    TalkListItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    keywords: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.talk-list {
  > .talk-list-item + .talk-list-item {
    margin-top: $spacing-md;
  }
}
</style>
