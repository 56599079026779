<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="actions">
      <ButtonBack @click="backToList" />
    </div>
    <div class="conts-wrap">
      <div class="header">
        <h1 class="title">
          キーワード登録
        </h1>
      </div>
      <div class="keyword">
        <div class="block-row">
          <FormBlock label="キーワード">
            <FormInputText
              v-model="keyword"
            />
          </FormBlock>
        </div>

        <FormInputItems
          title="類似表現"
          button="類似表現を追加"
          class="list-keyword-add"
          :max-numbers="max_numbers"
          :items="similar_keywords"
          :watch-messages="errorMessages"
          @onAdd="onAddKeyword"
          @onChange="onChangeKeyword"
          @onDelete="onDeleteKeyword"
        />

        <ButtonBlock
          class="edit-button-block mt-sm"
        >
          <Button
            size="inline"
            type="normal"
            @click="$router.push({ name: 'VDX800' })"
          >
            キャンセル
          </Button>
          <Button
            size="inline"
            type="primary"
            @click="onCreate"
          >
            新規登録
          </Button>
        </ButtonBlock>
      </div>
    </div>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonBack from '@/components/button/ButtonBack';
import MessageBar from '@/components/common/MessageBar';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import LayoutMain from '@/components/layout/LayoutMain';
import FormInputItems from '@/components/form/FormInputItems.vue';
import { createKeywordApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';
export default {
  components: {
    LayoutMain,
    Button,
    ButtonBack,
    FormBlock,
    FormInputText,
    ButtonBlock,
    MessageBar,
    FormInputItems,
  },
  data() {
    return {
      /**
       * キーワード登録情報
       */
      keyword: '',
      similar_keywords: [''],
      max_numbers: 30,


      /**
       * キーワード登録画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setStoreMessages',
    ]),

    /**
     * 一覧に戻る
     */
    backToList() {
      this.$router.push({ name: 'VDX800' });
    },

    /**
     * 類似表現の「類似表現の追加」ボタンのクリックイベント
     */
    onAddKeyword() {
      this.similar_keywords.unshift('');
    },

    /**
     * 類似表現入力項目のチェンジイベント
     */
    onChangeKeyword(textValue, index) {
      this.similar_keywords[index] = textValue;
    },

    /**
     * 類似表現の「削除」ボタンのクリックイベント
     */
    onDeleteKeyword(index) {
      this.similar_keywords.splice(index, 1);
    },

    /**
     * 「新規登録」ボタンのクリックイベント
     */
    async onCreate() {
      this.startLoading();

      // 配列内の空の値を削除
      const result_similar_keywords = this.similar_keywords.filter(item => item);

      const { status, data } = await createKeywordApi({
        keyword: this.keyword,
        similar_keywords: result_similar_keywords,
      });

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX800' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>

.conts-wrap {
  padding: $spacing-sm $spacing-xs;
}
.message-bar {
  margin: $spacing-xxs $spacing-xxs 0;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 47px;
  border-bottom: 1px solid $gray400;
  padding: 0 $spacing-xs;
}

.header {
  height: 42px;
  border-bottom: 2px solid $gray400;

  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}
.list-keyword-add{
  margin-top: $spacing-sm;
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;
  align-items: center;

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
  }
  > .add-button-block{
    margin-left: $spacing-xs;
  }
  > .form-label {
    font: $sans-none-16-bold;
    color: $black700;
  }
  .form-input-text{
  width: 346px;
  }
}
</style>
