<template>
  <div class="list-base">
    <div
      v-if="items.length > 0"
      class="header"
    >
      <div class="base-id">
        グループID
      </div>
      <div class="base-name">
        グループ名
      </div>
      <div class="tel-numbers">
        電話番号
      </div>
      <div class="date">
        登録日時
      </div>
    </div>
    <ul
      v-if="items.length > 0"
      id="list"
      class="list"
    >
      <ListItemBase
        v-for="item of items"
        :key="item.base_id"
        :base-id="item.base_id"
        :base-display-id="item.base_display_id"
        :base-name="item.base_name"
        :tel-numbers="item.tel_numbers"
        :create-datetime="item.create_datetime"
        @click="onClick"
      />
    </ul>
    <p
      v-if="items.length === 0"
      class="not-found"
    >
      グループ登録はありません
    </p>
  </div>
</template>

<script>
import ListItemBase from '@/components/base/ListItemBase';

export default {
  components: {
    ListItemBase,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click'],
  watch: {
    /**
     * propsのitemsが更新されたら実行される
     */
    'items' () {
      // リストのスクロールを最上部に戻す
      const domList = document.getElementById('list');
      if(domList) {
        domList.scrollTop = 0;
      }
    },
  },
  methods: {
    onClick(baseId) {
      this.$emit('click', baseId);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-base {

  > .header {
    display: flex;
    background-color: $white100;
    border-bottom: 2px solid $gray400;
    overflow-y: scroll;
    min-height: 30px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb{
      background: none;
    }
    > .base-id,
    .base-name,
    .tel-numbers,
    .date {
      color: $black400;
      font: $sans-none-12-bold;
      line-height: 12px;
      padding: 8px 14px 8px 0;
      box-sizing: border-box;
    }

    > .base-id {
      flex-basis: 120px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .base-name {
      max-width: calc((100% - 270px) * 0.35);
      flex-basis: calc((100% - 270px) * 0.35);
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .tel-numbers {
      max-width: calc((100% - 270px) * 0.65);
      flex-basis: calc((100% - 270px) * 0.65);
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .date {
      flex-basis: 150px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  > .list {
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      background-color: none;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: $gray600;
    }
  }

  > .not-found {
    margin: $spacing-sm $spacing-xlg 0;
    font: $sans-normal-16;
    color: $black700;
  }

}

</style>
