<template>
  <div
    class="form-label"
    :class="{
      'is-info': info,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.form-label {
  color: $black700;
  font: $sans-none-16-bold;
  text-align: left;
  display: inline-block;

  &.is-info {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      background-image: url(../../assets/images/info.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }
}
</style>
