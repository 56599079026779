<template>
  <div class="message-search-result">
    <p class="message">
      {{ message }}
    </p>
    <p
      v-if="query"
      class="query"
    >
      {{ query }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    query : {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.message-search-result {
  background-color: $green100;
  padding: $spacing-xxxs;
  border-radius: $radius4;
  word-break: break-all;
  > .message {
    font: $sans-snug-14-bold;
    color: $green500;
  }

  > .query {
    font: $sans-tight-14;
    color: $black500;
    margin-top: $spacing-xxxxs;
  }
}
</style>
