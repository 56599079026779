<template>
  <table class="table-usage">
    <tr>
      <th
        v-for="header in headers"
        :key="header"
        class="header"
      >
        {{ header }}
      </th>
    </tr>
    <tr
      v-for="usage in usages"
      :key="usage.date"
    >
      <th class="date">
        {{ usage.date }}
      </th>
      <td>{{ usage.cloud_storage }}</td>
      <td>{{ usage.stt_standard }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    usages: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.table-usage {
  color: $black700;

  th {
    padding: $spacing-xxxs;
    background-color: $gray100;
    border: 1px solid $gray400;
    white-space: nowrap;
    &.header {
      text-align: center;
      font: $sans-none-14-bold;
    }
    &.date {
      text-align: right;
      font: $sans-none-14;
    }
  }

  td {
    padding: $spacing-xxxs;
    border: 1px solid $gray400;
    font: $sans-none-14;
    text-align: right;
  }
}
</style>
