<template>
  <li class="listitem-voice">
    <VoiceCheckbox
      v-if="isShowCheckbox"
      v-model="checkedIdsModelValue"
      :value="id"
      :disabled="disabled"
    />
    <a
      class="link"
      :class="{ 'with-checkbox': isShowCheckbox }"
      data-test="list-item-voice-link"
      @click="onClick"
    >
      <div class="numbercontainer">
        <IconVoiceAreaBlack />
        <div class="number-text">
          <span
            v-if="callingName?.length"
            class="number"
          >
            {{ callingName }}
          </span>
          <span class="number">
            {{ callingNumber }}
          </span>
        </div>
      </div>
      <div class="numbercontainer">
        <IconVoiceAreaWhite />
        <div class="number-text">
          <span
            v-if="incomingName?.length"
            class="number"
          >
            {{ incomingName }}
          </span>
          <span class="number">
            {{ incomingNumber }}
          </span>
        </div>
      </div>
      <span class="talktime">{{ callingTime }}</span>
      <div class="textblock">
        <IconFile v-if="isPartition" />
        <p
          class="text"
          :class="['text-' + status]"
        >
          {{ text }}
        </p>
      </div>
      <time class="time">{{ callStartDatetime }}</time>
    </a>
  </li>
</template>

<script>
import IconFile from '@/components/icons/IconFile';
import IconVoiceAreaBlack from '@/components/icons/IconVoiceAreaBlack';
import IconVoiceAreaWhite from '@/components/icons/IconVoiceAreaWhite';
import VoiceCheckbox from '@/components/voice/VoiceCheckbox';

export default {
  components: {
    IconVoiceAreaBlack,
    IconVoiceAreaWhite,
    IconFile,
    VoiceCheckbox,
  },
  props: {
    checkedIds: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
    callingNumber: {
      type: String,
      default: '',
    },
    incomingNumber: {
      type: String,
      default: '',
    },
    callingName: {
      type: String,
      default: '',
    },
    incomingName: {
      type: String,
      default: '',
    },
    callingTime: {
      type: String,
      default: '',
    },
    isPartition: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    callStartDatetime: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'normal',
      validator: (value) => {
        return ['normal', 'error', 'receiving', 'text_nothing'].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isShowCheckbox: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'update:checkedIds'],
  computed: {
    checkedIdsModelValue: {
      get() {
        return this.checkedIds;
      },
      set(newValue) {
        this.$emit('update:checkedIds', newValue);
      },
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.id);
    },
  },
};

</script>

<style lang="scss" scoped>
.listitem-voice {
  position: relative;
  border-bottom: 1px solid $gray400;
  height: 49px;
  width: 100%;
  color: $black700;

  > .voice-checkbox {
    position: absolute;
    top: 15px;
    left: 16px;
  }

  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 $spacing-xxs;
    height: 100%;
    cursor: pointer;
    &.with-checkbox {
      padding-left: 50px; // 16px + チェックボックス18px + 16px
    }

    > .numbercontainer {
      display: flex;
      width: 174px;

      &:not(:first-child) {
        margin-left: $spacing-xxs;
      }
      > .number-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 140px;
        margin-left: $spacing-xxxs;
        > .number {
          font: $sans-none-14-bold;
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 18px;
          &:nth-of-type(2) {
            font: $sans-none-10;
            color: $black200;
            margin-top: 2px;
          }
        }
      }
    }

    > .durationofcall, .text {
      font: $sans-none-14;
    }

    > .talktime {
      font: $sans-none-14;
      min-width: 59px;
      text-align: center;
      margin-left: $spacing-xxs;
    }

    > .textblock {
      display: flex;
      align-items: center;
      margin-left: $spacing-xxs;
      text-align: left;
      width: calc(100% - 631px);

      > .icon-lineup {
        margin-right: $spacing-xxxs;
      }

      > .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 48px;

        &-normal {
          color: $black700;
        }

        &-error {
          color: $red300;
        }

        &-receiving {
          color: $green500;
        }

        &-text_nothing {
          color: $black200;
        }
      }
    }

    > time {
      font: $sans-none-14;
      min-width: 160px;
      margin-left: $spacing-xxs;
      text-align: right;
    }
  }
}
</style>
