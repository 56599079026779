<template>
  <button
    class="button-close"
    @click="click"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8754 4.13789C19.3879 3.65039 18.6004 3.65039 18.1129 4.13789L12.0004 10.2379L5.88789 4.12539C5.40039 3.63789 4.61289 3.63789 4.12539 4.12539C3.63789 4.61289 3.63789 5.40039 4.12539 5.88789L10.2379 12.0004L4.12539 18.1129C3.63789 18.6004 3.63789 19.3879 4.12539 19.8754C4.61289 20.3629 5.40039 20.3629 5.88789 19.8754L12.0004 13.7629L18.1129 19.8754C18.6004 20.3629 19.3879 20.3629 19.8754 19.8754C20.3629 19.3879 20.3629 18.6004 19.8754 18.1129L13.7629 12.0004L19.8754 5.88789C20.3504 5.41289 20.3504 4.61289 19.8754 4.13789Z"
        fill="#7E7E84"
      />
    </svg>
  </button>
</template>

<script>
export default {
  emits: ['click'],
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-close {
  padding: 0;
  margin: 0;
}
</style>
