<template>
  <select
    class="select-playback-rate"
    :value="playbackRate"
    @input="input"
  >
    <option value="0.75">
      0.75x
    </option>
    <option value="1.0">
      1.0x
    </option>
    <option value="1.25">
      1.25x
    </option>
    <option value="1.5">
      1.5x
    </option>
    <option value="2.0">
      2.0x
    </option>
  </select>
</template>

<script>
export default {
  props: {
    playbackRate: {
      type: String,
      required: true,
    },
  },
  emits: ['input'],
  methods: {
    input($event) {
      this.$emit('input', $event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-playback-rate {
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: $gray100;
  border: 1px solid $black600;
  border-radius: 6px;
  font: $sans-none-12;
  text-align: center;
  padding: 6px;
}
</style>
