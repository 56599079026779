<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11.9992"
      cy="12.0002"
      r="10.8"
      fill="#CACACA"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3996 11.2309L15.1683 11.9996L18.7068 8.46111V10.1377H19.7996V6.59961H16.2616V7.69241H17.9381L14.3996 11.2309ZM10.7996 12.5996C11.9594 12.5996 12.8996 11.6594 12.8996 10.4996C12.8996 9.33981 11.9594 8.39961 10.7996 8.39961C9.63981 8.39961 8.69961 9.33981 8.69961 10.4996C8.69961 11.6594 9.63981 12.5996 10.7996 12.5996ZM10.7996 13.6496C9.39786 13.6496 6.59961 14.3531 6.59961 15.7496V16.2746C6.59961 16.5634 6.83586 16.7996 7.12461 16.7996H14.4746C14.7634 16.7996 14.9996 16.5634 14.9996 16.2746V15.7496C14.9996 14.3531 12.2014 13.6496 10.7996 13.6496Z"
      fill="#7E7E84"
    />
  </svg>
</template>
