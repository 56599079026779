<template>
  <LayoutMain>
    <div class="header">
      <MessageBar
        v-if="errorMessages.length > 0"
        :messages="errorMessages"
        type="error"
        class="mt-xxs"
      />
      <div class="actions">
        <ButtonBack @click="backToList" />
      </div>
    </div>
    <div
      v-if="canVisible"
      id="scrollable"
      class="body"
    >
      <div class="inner">
        <PageHeading
          heading="キーワード通知詳細"
          class="mb-xs"
        />
        <MessageKeyword
          v-if="keywords.length > 0"
          :keywords="keywords"
          class="mb-xxs"
        />
        <SubjectArea
          :calling-number="callingNumber"
          :incoming-number="incomingNumber"
          :calling-name="callingName"
          :incoming-name="incomingName"
          :calling-time="callingTime"
          :call-start-datetime="callStartDatetime"
          :call-end-datetime="callEndDatetime"
          class="mb-lg"
        />
        <PartsArea
          v-if="isPartition"
          :is-partition="isPartition"
          :is-receiving="isReceiving"
          :parts="parts"
          :current-part="currentPart"
          class="mb-lg"
          @change="changePart"
        />
        <TalkRecordArea
          :status="status"
          :data="data"
          :keywords="highlightKeywords"
        />
      </div>
    </div>
  </LayoutMain>
</template>

<script>
import ButtonBack from '@/components/button/ButtonBack';
import MessageBar from '@/components/common/MessageBar';
import PageHeading from '@/components/common/PageHeading';
import LayoutMain from '@/components/layout/LayoutMain';
import MessageKeyword from '@/components/voice/MessageKeyword';
import PartsArea from '@/components/voice/PartsArea';
import SubjectArea from '@/components/voice/SubjectArea';
import TalkRecordArea from '@/components/voice/TalkRecordArea';
import { getNoticeKeywordApi } from '@/utils/ApiHelper';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    ButtonBack,
    PageHeading,
    MessageKeyword,
    SubjectArea,
    PartsArea,
    TalkRecordArea,
    MessageBar,
  },
  data() {
    return {
      /**
       * キーワード通知詳細表示フラグ
       */
      canVisible: false,

      /**
       * 現在の表示パート
       */
      currentPart: 1,

      callingNumber: '',
      incomingNumber: '',
      callingName: '',
      incomingName: '',
      callingTime: '',
      callStartDatetime: '',
      callEndDatetime: '',

      keywords: [],
      highlightKeywords: [],

      isPartition: false,
      isReceiving: false,
      parts: [],

      status: '',
      data: [],

      /**
       * キーワード通知詳細画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'keywordNoticeRestoreFlagOn',
    ]),

    /**
     * 初期処理
     */
    async init() {
      this.startLoading();
      // キーワード通知詳細を取得
      await this.fetchData();
      this.stopLoading();
    },

    /**
     * キーワード通知詳細を取得する
     */
    async fetchData() {
      // キーワード通知詳細取得APIを呼び出す
      const { status, data } = await getNoticeKeywordApi(
        { id: this.$route.params.id },
        { part: this.currentPart },
      );

      // 業務エラーの場合はエラーメッセージを設定して処理終了
      if (status == 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 取得したキーワード通知詳細を設定する
      this.canVisible = true;
      this.callingNumber = data.calling_number;
      this.incomingNumber = data.incoming_number;
      this.callingName = data.calling_name;
      this.incomingName = data.incoming_name;
      this.callingTime = data.calling_time;
      this.callStartDatetime = data.call_start_datetime;
      this.callEndDatetime = data.call_end_datetime;
      this.keywords = data.keywords;
      this.highlightKeywords = data.highlight_keywords;
      this.isPartition = data.is_partition;
      this.isReceiving = data.is_receiving;
      this.parts = data.part;
      this.status = data.status;
      this.data = data.data;
    },

    /**
     * 一覧に戻る
     */
    backToList() {
      // キーワード通知一覧画面で検索条件を復元する
      this.keywordNoticeRestoreFlagOn();
      this.$router.push({ name: 'VDXA00' });
    },

    /**
     * 表示Partを変更する
     */
    async changePart(selectedPart) {
      this.startLoading();
      this.canVisible = false;
      this.currentPart = selectedPart;
      // キーワード通知詳細を取得
      await this.fetchData();
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
  }
}

.header {
  border-bottom: 1px solid $gray400;
  padding: 0 $spacing-xs;

  > .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 47px;
  }
}

.body {
  overflow-y: scroll;

  > .inner {
    width: 1036px; // UI改善で変数化する
    margin: 0 auto;
    padding: $spacing-sm $spacing-xs;
  }
}
</style>
