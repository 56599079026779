<template>
  <LayoutMain @refresh="refresh">
    <MessageBar
      v-if="successMessages.length > 0"
      :messages="successMessages"
      type="success"
      class="mb-xs"
    />
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
      class="mb-xs"
    />
    <PageHeading
      heading="SSO設定"
      class="mb-xs"
    />

    <template v-if="!isActiveSSO">
      <template v-if="currentStep === STEPS.TURN_ON_SSO">
        <div class="form-label">
          シングルサインオン設定
        </div>
        <div class="form-input">
          <FormSwitch
            v-model="switchValue"
            :disabled="!hasAuthEditIdpSettingAPI"
          />
          <span>{{ switchValue ? 'ON' : 'OFF' }}</span>
        </div>
        <ButtonBlock class="mt-md">
          <Button
            :disabled="!switchValue"
            @click="nextStep"
          >
            次へ
          </Button>
        </ButtonBlock>
      </template>

      <template v-if="currentStep === STEPS.IMPORT_METADATA">
        <MessageBar
          type="info"
          :messages="['SSOサービスのメタデータをインポートしてください']"
          class="mb-xs"
        />
        <div class="form-label">
          メタデータをインポート
        </div>
        <div class="form-input">
          <Button
            type="normal"
            size="inline"
            @click="clickInputTypeFile"
          >
            ファイル選択
            <input
              id="file"
              name="file"
              type="file"
              hidden
              accept=".xml"
              @change="selectMetadata"
            >
          </Button>
          <span>{{ file ? file.name : '選択されていません' }}</span>
        </div>
        <ButtonBlock class="mt-md">
          <Button
            :disabled="!file"
            @click="importMetadata"
          >
            インポート
          </Button>
        </ButtonBlock>
      </template>

      <template v-if="currentStep === STEPS.STRAT_CONNECTION">
        <p class="text">
          まだSSO設定は完了していません。<br>
          SSO接続開始をクリックして設定完了してください。
        </p>
        <ButtonBlock class="mt-md">
          <Button @click="testConnection">
            SSO接続開始
          </Button>
        </ButtonBlock>
      </template>
    </template>

    <template v-if="isActiveSSO">
      <div class="form-label">
        シングルサインオン設定
      </div>
      <div class="form-input">
        <FormSwitch
          :model-value="switchValue"
          :disabled="!hasAuthEditIdpSettingAPI"
          :prevent="true"
          @click="$refs.modal?.openModal()"
        />
        <span>{{ switchValue ? 'ON' : 'OFF' }}</span>
      </div>
    </template>
  </LayoutMain>

  <Modal ref="modal">
    <div class="title">
      シングルサインオン設定
    </div>
    <p class="text">
      シングルサインオン設定をOFFにすると、SSOはご利用できなくなります。OFFにしますか？
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="$refs.modal?.hideModal()"
      >
        キャンセル
      </Button>
      <Button
        size="inline"
        type="primary"
        @click="unset"
      >
        OFFにする
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import PageHeading from '@/components/common/PageHeading';
import FormSwitch from '@/components/form/FormSwitch';
import LayoutMain from '@/components/layout/LayoutMain';
import { editIdPSettingApi, getIdpSettingApi, getUserRoleAuthsApi, postIdpMetadataApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS, ERROR_MESSAGES } from '@/utils/Constants';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    ButtonBlock,
    Button,
    PageHeading,
    MessageBar,
    FormSwitch,
    Modal,
  },
  data() {
    return {
      /**
       * SSO設定ステップ一覧
       */
      STEPS: {
        TURN_ON_SSO: '1',
        IMPORT_METADATA: '2',
        STRAT_CONNECTION: '3',
      },
      /**
       * 現在のSSO設定のステップ
       */
      currentStep: '',
      /**
       * IdP種別
       * IdP設定取得APIで取得する
       */
      idpType: '',
      /**
       * SSO設定スイッチの値
       */
      switchValue: false,
      /**
       * 選択されたファイル
       */
      file: null,
      /**
       * 接続確認するIDプロバイダー名
       */
      providerName: '',
      /**
       * 権限一覧
       */
      authorizations: [],
      /**
       * SSO設定画面に表示する成功メッセージ
       */
      successMessages: [],
      /**
       * SSO設定画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  computed: {
    /**
     * IdP設定更新APIの権限チェック
     *
     * 権限が有る場合
     * ・SSO設定スイッチを活性化
     */
    hasAuthEditIdpSettingAPI() {
      return hasAuthorization(this.authorizations, API_IDS.EDIT_IDP_SETTING);
    },
    /**
     * SSO設定が有効かどうか
     */
    isActiveSSO() {
      return this.idpType !== 'KVV';
    },
  },
  async mounted() {
    this.startLoading();

    // SSO接続成功
    if (this.$route.query.code) {
      // URLクエリパラメータを除外
      this.$router.push({ name: 'VDXB00' });
      // IdP設定更新APIを呼び出す
      const { status, data } = await editIdPSettingApi(
        { idp_type: 'KBI' },
      );
      if (status == 200) {
        // 成功メッセージを設定
        this.successMessages = ['SSO接続が完了しました'];
      } else if (status == 400 || status == 422) {
        // エラーメッセージを設定
        this.errorMessages = data.detail.map(item => item.msg);
      }
    }

    // 権限による画面制御
    await this.getUserRoleAuths();
    // IdP設定取得
    await this.getIdpSetting();

    // SSO接続失敗
    if (this.$route.query.error) {
      console.error(this.$route.query.error_description);
      // URLクエリパラメータを除外
      this.$router.push({ name: 'VDXB00' });
      // エラーメッセージを設定
      this.errorMessages = [ERROR_MESSAGES.SSO_CONNECT_FAILED];
      // SSO接続開始に進む
      this.currentStep = this.STEPS.STRAT_CONNECTION;
    }

    this.stopLoading();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
    ]),

    /**
     * 画面初期化
     */
    async refresh() {
      this.startLoading();
      // メッセージクリア
      this.successMessages = [];
      this.errorMessages = [];
      this.file = null;
      // 権限による画面制御
      await this.getUserRoleAuths();
      // IdP設定取得
      await this.getIdpSetting();
      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.EDIT_IDP_SETTING,
        ],
      });
      this.authorizations = data.data;
    },

    /**
     * IdP設定取得
     */
    async getIdpSetting() {
      // IdP設定取得APIを呼び出す
      const { data } = await getIdpSettingApi();
      this.idpType = data.idp_type;
      this.providerName = data.saml_idp_name;
      this.switchValue = this.idpType !== 'KVV';
      this.currentStep = this.STEPS.TURN_ON_SSO;
    },

    /**
     * 次へボタン押下時のイベント
     */
    nextStep() {
      // メッセージクリア
      this.successMessages = [];
      this.errorMessages = [];
      // メタデータのインポートに進む
      this.currentStep = this.STEPS.IMPORT_METADATA;
    },

    /**
     * ファイル選択ボタン押下時のイベント
     */
    clickInputTypeFile() {
      document.getElementById('file').click();
    },

    /**
     * ファイル選択時のイベント
     */
    selectMetadata(e) {
      // 選択されたファイルを設定
      this.file = e.target.files[0];
    },

    /**
     * インポートボタン押下時のイベント
     */
    async importMetadata() {
      this.startLoading();

      // メッセージクリア
      this.successMessages = [];
      this.errorMessages = [];

      // IdPメタデータ登録APIの呼び出し
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('idp_type', 'KBI');
      const { status, data } = await postIdpMetadataApi(formData);

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status === 400 || status === 422)  {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 成功メッセージを設定
      this.successMessages = data.detail.map(item => item.msg);
      // IDプロバイダー名を設定
      this.providerName = data.provider_name;
      // SSO接続開始に進む
      this.currentStep = this.STEPS.STRAT_CONNECTION;

      this.stopLoading();
    },

    /**
     * SSO接続開始ボタン押下時のイベント
     */
    testConnection() {
      this.startLoading();
      // Cognitoの認証エンドポイントを利用してSSO接続確認を行う
      location.href = `https://${process.env.VUE_APP_COGNITO_OAUTH_DOMAIN}/oauth2/authorize?identity_provider=${this.providerName}&response_type=code&client_id=${process.env.VUE_APP_COGNITO_CLIENT_ID}&redirect_uri=${window.location.href}&v=${Date.now()}`;
    },

    /**
     * SSO設定を解除する
     */
    async unset() {
      this.startLoading();

      // メッセージクリア
      this.successMessages = [];
      this.errorMessages = [];

      // IdP設定更新APIを呼び出し、idp_typeをKVVに更新する
      const { status, data } = await editIdPSettingApi(
        { idp_type: 'KVV' },
      );

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        // モーダルを閉じる
        this.$refs.modal?.hideModal();
        this.stopLoading();
        return;
      }

      // 成功メッセージを設定
      this.successMessages = data.detail.map(item => item.msg);

      // モーダルを閉じる
      this.$refs.modal?.hideModal();

      // IdP設定取得APIを呼び出し、画面を更新する
      this.getIdpSetting();

      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    padding: $spacing-sm $spacing-md;
  }

  .form-label {
    font: $sans-none-16-bold;
    color: $black700;
    margin-bottom: $spacing-xxxs;
  }

  .form-input {
    display: flex;
    align-items: center;
    font: $sans-none-14;
    color: $black700;
    > span {
      margin-left: $spacing-xxxxs;
    }
  }

  .text {
    font: $sans-normal-14;
    color: $black700;
  }
}
</style>
