<template>
  <div class="pagination-block">
    <div
      v-if="!isShow"
      class="text"
    >
      {{ startCount.toLocaleString() }}-{{ endCount.toLocaleString() }} / {{ totalCount.toLocaleString() }}件
    </div>
    <div class="button-block">
      <button
        class="button"
        :disabled="!hasPrev"
        data-test="pagination-prev"
        @click="onPrev"
      >
        <IconArrowLeft
          :class="{'is-disabled': !hasPrev}"
        />
      </button>
      <button
        class="button"
        :disabled="!hasNext"
        data-test="pagination-next"
        @click="onNext"
      >
        <IconArrowRight
          :class="{'is-disabled': !hasNext}"
        />
      </button>
    </div>
  </div>
</template>

<script>
import IconArrowLeft from '@/components/icons/IconArrowLeft';
import IconArrowRight from '@/components/icons/IconArrowRight';

export default {
  components: {
    IconArrowLeft,
    IconArrowRight,
  },
  props: {
    startCount: {
      type: Number,
      default: null,
    },
    endCount: {
      type: Number,
      default: null,
    },
    totalCount: {
      type: Number,
      default: null,
    },
    hasPrev: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['prev', 'next'],
  computed: {
    isShow() {
      return this.startCount === null && this.endCount === null && this.totalCount === null;
    },
  },
  methods: {
    onPrev() {
      this.$emit('prev');
    },
    onNext() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-block {
  display: flex;
  align-items: center;

  > .text {
    font: $sans-none-14;
    color: $black700;
  }

  > .button-block {
    margin-left: $spacing-sm;
    width: 60px;
    display: flex;
    justify-content: space-between;

    > .button {
      padding: 0;

      > .is-disabled{
        > :deep(.icon) {
          svg path {
            fill: $black200;
          }
        }
      }
    }
  }
}
</style>
