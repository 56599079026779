<template>
  <LayoutEntry>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <div class="title">
      アクティベートコードの入力
    </div>
    <p class="text text-activate">
      お申し込み時に設定いただいたアクティベートコードをご入力ください
    </p>
    <ActivateCode
      v-model:model-value1="activateCode1"
      v-model:model-value2="activateCode2"
      v-model:model-value3="activateCode3"
      v-model:model-value4="activateCode4"
      v-model:model-value5="activateCode5"
      v-model:model-value6="activateCode6"
      @keydown.enter="verify"
    />
    <ButtonBlock>
      <Button
        ref="button"
        :disabled="isButtonDisabled"
        @click="verify"
      >
        OK
      </Button>
    </ButtonBlock>
  </LayoutEntry>
</template>

<script>
import ActivateCode from '@/components/activate/ActivateCode';
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import MessageBar from '@/components/common/MessageBar';
import LayoutEntry from '@/components/layout/LayoutEntry';
import { verifyActivateCodeApi, verifyActivateStateApi } from '@/utils/ApiHelper';
import { hasIdToken } from '@/utils/IdTokenManager';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutEntry,
    ActivateCode,
    ButtonBlock,
    Button,
    MessageBar,
  },
  data() {
    return {
      errorMessages: [],
      activateCode1: '',
      activateCode2: '',
      activateCode3: '',
      activateCode4: '',
      activateCode5: '',
      activateCode6: '',
    };
  },
  computed: {
    isButtonDisabled() {
      return this.activateCode1.length === 0 || this.activateCode2.length === 0 || this.activateCode3.length === 0 || this.activateCode4.length === 0 || this.activateCode5.length === 0 || this.activateCode6.length === 0;
    },
  },
  mounted() {
    // ログイン済みの場合はホーム画面にリダイレクト
    if (hasIdToken()) {
      this.$router.push({ name: 'VDX100' });
      return;
    }
    this.fetchData();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'setUserEmailAddress', 'setUserFamilyName', 'setUserFirstName', 'setActivateQuery']),
    async fetchData() {
      this.startLoading();
      const { status, data } = await verifyActivateStateApi(
        { q: this.$route.query.q },
      );

      if (status == 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.stopLoading();
    },
    async verify() {
      this.startLoading();
      const { status, data } = await verifyActivateCodeApi(
        {
          query: encodeURIComponent(this.$route.query.q),
          activate_code: this.activateCode1 + this.activateCode2 + this.activateCode3 + this.activateCode4 + this.activateCode5 + this.activateCode6,
        },
      );

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }
      // Eメールアドレス・姓名をストアに保存
      this.setUserEmailAddress({ userEmailAddress: data.user_email_address });
      this.setUserFamilyName({ userFamilyName: data.user_family_name });
      this.setUserFirstName({ userFirstName: data.user_first_name });
      // queryをストアに保存
      this.setActivateQuery({ activateQuery: this.$route.query.q });
      this.$router.push({ name: 'VDX020' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-entry {
  > :deep(.container) {
    width: 418px;
  }
}
</style>
