<template>
  <label
    class="form-input-text"
    :class="[
      size,
      {
        'is-focus': isFocus,
        'is-error': isError,
        'is-disabled': disabled,
      },
    ]"
  >
    <input
      v-model="value"
      class="input"
      :type="type"
      :maxlength="maxlength"
      :disabled="disabled"
      @focus="inputFocus"
      @blur="inputBlur"
    >
  </label>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    size: {
      type: String,
      default: '',
    },
    maxlength: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isFocus: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  methods: {
    inputFocus() {
      this.isFocus = true;
    },
    inputBlur() {
      this.isFocus = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form-input-text {
  display: flex;
  align-items: center;
  width: 100%;
  height: $input-height;
  cursor: text;
  position: relative;

  &.width-180 {
    width: $form-size-180;
  }

  &.width-260 {
    width: $form-size-260;
  }

  &.width-320 {
    width: $form-size-320;
  }

  &.width-400 {
    width: $form-size-400;
  }

  &.is-focus {
    > .input {
      border-color: $black400;
    }
  }

  &.is-error {
    > .input {
      background: $red100;
      border-color: $red300;
    }
  }

  &.is-disabled {
    > .input {
      background: $gray200;
      border-color: $gray200;
      color: $black400;
    }
  }

  > .input {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    padding: 0 $spacing-xxxs;
    box-sizing: border-box;
    outline: none;
    background: $white100;
    border: 1px solid $gray600;
    border-radius: $radius4;
    color: $black700;
    font: $sans-none-16;

    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance:textfield;
    }
  }
}
</style>
