<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15.9992"
      cy="16"
      r="12.05"
      fill="#DDEFFF"
      stroke="#A0D9FF"
      stroke-width="1.5"
    />
    <path
      d="M15.8676 15.2C17.9671 15.2 19.6676 13.4995 19.6676 11.4C19.6676 9.30048 17.9671 7.59998 15.8676 7.59998C13.7681 7.59998 12.0676 9.30048 12.0676 11.4C12.0676 13.4995 13.7681 15.2 15.8676 15.2ZM15.8676 17.1C13.3311 17.1 8.26758 18.373 8.26758 20.9V21.85C8.26758 22.3725 8.69508 22.8 9.21758 22.8H22.5176C23.0401 22.8 23.4676 22.3725 23.4676 21.85V20.9C23.4676 18.373 18.4041 17.1 15.8676 17.1Z"
      fill="#4EB8FF"
    />
  </svg>
</template>
