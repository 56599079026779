<template>
  <button
    v-if="!isPlaying"
    class="button-play"
    @click="$emit('play')"
  >
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7231_88652)">
        <path
          d="M12 7.5V28.5L28.5 18L12 7.5Z"
          fill="#4A4B53"
        />
      </g>
      <defs>
        <clipPath id="clip0_7231_88652">
          <rect
            width="36"
            height="36"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </button>
  <button
    v-if="isPlaying"
    class="button-pause"
    @click="$emit('pause')"
  >
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8340_10104)">
        <path
          d="M9 28.5H15V7.5H9V28.5ZM21 7.5V28.5H27V7.5H21Z"
          fill="#4A4B53"
        />
      </g>
      <defs>
        <clipPath id="clip0_8340_10104">
          <rect
            width="36"
            height="36"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    isPlaying: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['play', 'pause'],
};
</script>

<style lang="scss" scoped>
.button-play, .button-pause {
  padding: 0;
}
</style>
