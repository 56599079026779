<template>
  <li class="list-item-notice-keyword">
    <a
      class="link"
      @click="onClick"
    >
      <div class="flex-item calling-number">
        <IconCalling
          width="22px"
          height="22px"
        />
        <div class="number-text">
          <span
            v-if="callingName?.length"
            class="number"
          >
            {{ callingName }}
          </span>
          <span class="number">
            {{ callingNumber }}
          </span>
        </div>
      </div>
      <div class="flex-item incoming-number">
        <IconIncoming
          width="22px"
          height="22px"
        />
        <div class="number-text">
          <span
            v-if="incomingName?.length"
            class="number"
          >
            {{ incomingName }}
          </span>
          <span class="number">
            {{ incomingNumber }}
          </span>
        </div>
      </div>
      <div class="flex-item keyword">{{ keyword }}</div>
      <div class="flex-item transcription">
        <IconFile v-if="isPartition" />
        <span
          class="text"
          :class="['text-' + status]"
        >{{ text }}</span>
      </div>
      <div class="flex-item call-start-datetime">{{ callStartDatetime }}</div>
    </a>
  </li>
</template>

<script>
import IconCalling from '@/components/icons/IconCalling';
import IconFile from '@/components/icons/IconFile';
import IconIncoming from '@/components/icons/IconIncoming';

export default {
  components: {
    IconCalling,
    IconIncoming,
    IconFile,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    callingNumber: {
      type: String,
      default: '',
    },
    incomingNumber: {
      type: String,
      default: '',
    },
    callingName: {
      type: String,
      default: '',
    },
    incomingName: {
      type: String,
      default: '',
    },
    keyword: {
      type: String,
      default: '',
    },
    isPartition: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'normal',
      validator: (value) => {
        return ['normal', 'error', 'receiving', 'text_nothing'].includes(value);
      },
    },
    callStartDatetime: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click', this.id);
    },
  },
};

</script>

<style lang="scss" scoped>
.list-item-notice-keyword {
  height: 40px;
  width: 100%;
  border-bottom: 1px solid $gray400;
  font: $sans-none-14;
  line-height: 40px;
  color: $black700;

  > .link {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;

    > .calling-number, > .incoming-number {
      display: flex;
      align-items: center;
      flex-basis: 160px;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 14px;
      > .number-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(160px - 14px - 22px - 6px);
        margin-left: 6px;
        > .number {
          font: $sans-none-14-bold;
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 18px;
          &:nth-of-type(2) {
            font: $sans-none-10;
            color: $black200;
            margin-top: 2px;
          }
        }
      }
    }

    > .keyword {
      max-width: calc((100% - 160px - 160px - 150px) * 0.34);
      flex-basis: calc((100% - 160px - 160px - 150px) * 0.34);
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    > .transcription {
      display: flex;
      align-items: center;
      height: 100%;
      max-width: calc((100% - 160px - 160px - 150px) * 0.66);
      flex-basis: calc((100% - 160px - 160px - 150px) * 0.66);
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 14px;
      > .icon-lineup {
        margin-right: $spacing-xxxs;
      }
      > .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &-normal {
          color: $black700;
        }

        &-error {
          color: $red300;
        }

        &-receiving {
          color: $green500;
        }

        &-text_nothing {
          color: $black200;
        }
      }
    }

    > .call-start-datetime {
      flex-basis: 150px;
      flex-grow: 0;
      flex-shrink: 0;
      padding-right: 14px;
      text-align: right;
    }
  }
}
</style>
