<template>
  <Loading v-if="isLoading" />
</template>

<script>
import Loading from '@/components/common/Loading.vue';
import { COGNITO_CONFIG, ERROR_MESSAGES } from '@/utils/Constants';
import { hasIdToken, setIdToken } from '@/utils/IdTokenManager';
import { Amplify, Hub } from 'aws-amplify';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  mounted() {
    this.startLoading();

    // ログイン済みの場合はホーム画面にリダイレクト
    if (hasIdToken()) {
      this.$router.push({ name: 'VDX100' });
      return;
    }

    // Cognito接続設定
    Amplify.configure(COGNITO_CONFIG);

    // SSOログイン処理
    Hub.listen('auth', ({ payload: { event, data } }) => {

      if (event === 'cognitoHostedUI') {
        // SSOログインに成功
        // IDトークンをローカルストレージに保存
        const idToken = data.signInUserSession.idToken;
        setIdToken({
          jwtToken: idToken.jwtToken,
          email: idToken.payload['email'],
          voiceDxCode: idToken.payload['custom:voice-dx-code'],
          userFamilyName: idToken.payload['custom:user-family-name'],
          userFirstName: idToken.payload['custom:user-first-name'],
          userRole: idToken.payload['custom:user-role'],
          userRoleName: idToken.payload['custom:user-role-name'],
          baseName: idToken.payload['custom:base-name'],
          useStt: idToken.payload['custom:use-stt'],
          useArchive: idToken.payload['custom:use-archive'],
        });
        // 記録一覧画面に遷移
        this.$router.push({ name: 'VDX100' });
        this.stopLoading();

      } else if (event === 'cognitoHostedUI_failure') {
        // SSOログインに失敗
        // Cognito Lambda のエラーメッセージがエンコードされているためデコード
        const decode = decodeURIComponent(data.message);
        const replaced = decode.replace(/\+/g, ' ');
        // コンソールにエラー内容を出力
        console.error(replaced);
        const result = replaced.match(/\[BusinessException:(.+)\]/);
        if (result) {
          const message = result[1];
          // エラーメッセージをストアに保存
          this.setStoreMessages({
            storeMessages: {
              type: 'error',
              messages: [message],
            },
          });
        } else {
          // エラーメッセージをストアに保存
          this.setStoreMessages({
            storeMessages: {
              type: 'error',
              messages: [ERROR_MESSAGES.SSO_LOGIN_FAILED],
            },
          });
        }
        // ログイン画面に遷移
        this.$router.push({ name: 'VDX050' });
        this.stopLoading();
      }
    });
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'setStoreMessages']),
  },
};
</script>

<style lang="scss" scoped>
</style>
