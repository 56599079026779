<template>
  <Modal
    ref="modal"
    class="modal-search-notice-keyword"
  >
    <div class="header">
      <span class="title">キーワード検索</span>
      <ButtonClose @click="hideModal" />
    </div>
    <MessageBar
      v-if="errors.length > 0"
      type="error"
      :messages="errors"
    />
    <FormBlock label="キーワード">
      <FormSelect
        v-model="keywordIdModelValue"
        :options="keywordOptions"
      />
    </FormBlock>
    <div class="form-row mt-xs">
      <div class="form">
        <FormBlock label="発信">
          <FormInputText
            v-model="callingNumberModelValue"
            size="width-260"
          />
        </FormBlock>
        <p class="text">
          発信番号を指定します
        </p>
      </div>
      <div class="form">
        <FormBlock label="着信">
          <FormInputText
            v-model="incomingNumberModelValue"
            size="width-260"
          />
        </FormBlock>
        <p class="text">
          着信番号を指定します
        </p>
      </div>
    </div>
    <FormBlock
      label="検索期間"
      class="form-block-row"
    >
      <FormDatePicker
        v-model="searchPeriodFromModelValue"
        size="width-180"
      />
      <div class="wave">
        ~
      </div>
      <FormDatePicker
        v-model="searchPeriodToModelValue"
        size="width-180"
      />
    </FormBlock>
    <ButtonBlock layout="center">
      <Button
        type="normal"
        size="inline"
        @click="reset"
      >
        リセット
      </Button>
      <Button
        type="primary"
        size="inline"
        @click="search"
      >
        検索
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonClose from '@/components/button/ButtonClose';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import FormBlock from '@/components/form/FormBlock';
import FormDatePicker from '@/components/form/FormDatePicker';
import FormInputText from '@/components/form/FormInputText';
import FormSelect from '@/components/form/FormSelect';

export default {
  components: {
    Button,
    ButtonClose,
    ButtonBlock,
    MessageBar,
    Modal,
    FormBlock,
    FormDatePicker,
    FormInputText,
    FormSelect,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    keywordOptions: {
      type: Array,
      default: () => [],
    },
    keywordId: {
      type: String,
      default: '',
    },
    callingNumber: {
      type: String,
      default: '',
    },
    incomingNumber: {
      type: String,
      default: '',
    },
    searchPeriodFrom: {
      type: String,
      default: '',
    },
    searchPeriodTo: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:keywordId',
    'update:callingNumber',
    'update:incomingNumber',
    'update:searchPeriodFrom',
    'update:searchPeriodTo',
    'search',
  ],
  computed: {
    keywordIdModelValue: {
      get() {
        return this.keywordId;
      },
      set(value) {
        this.$emit('update:keywordId', value);
      },
    },
    callingNumberModelValue: {
      get() {
        return this.callingNumber;
      },
      set(value) {
        this.$emit('update:callingNumber', value);
      },
    },
    incomingNumberModelValue: {
      get() {
        return this.incomingNumber;
      },
      set(value) {
        this.$emit('update:incomingNumber', value);
      },
    },
    searchPeriodFromModelValue: {
      get() {
        return this.searchPeriodFrom;
      },
      set(value) {
        this.$emit('update:searchPeriodFrom', value);
      },
    },
    searchPeriodToModelValue: {
      get() {
        return this.searchPeriodTo;
      },
      set(value) {
        this.$emit('update:searchPeriodTo', value);
      },
    },
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    reset() {
      this.keywordIdModelValue = '';
      this.callingNumberModelValue = '';
      this.incomingNumberModelValue = '';
      this.searchPeriodFromModelValue = '';
      this.searchPeriodToModelValue = '';
    },
    search() {
      this.$emit('search');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-search-notice-keyword {
  > ::v-deep(.container) {
    width: 592px;
    padding: $spacing-xs;

    > .header {
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $spacing-xs;
      position: relative;
      > .title {
        font: $sans-none-20-bold;
        color: $black700;
      }

      > .button-close {
        position: absolute;
        right: 0;
      }
    }

    > .form-row {
      display: flex;
      justify-content: space-between;
      > .form {
        > .text {
          font: $sans-tight-12;
          color: $black700;
          margin-top: $spacing-xxxxs;
        }
      }
    }

    > .form-block-row {
      > .form-item {
        display: flex;
        > .wave {
          display: flex;
          align-items: center;
          color: $black700;
          width: 8px;
          margin-left: 4px;
          margin-right: 4px;
          font: $sans-none-14;
        }
      }
    }
  }
}
</style>
