<template>
  <LayoutMain @refresh="init">
    <div class="body">
      <MessageBar
        v-if="flashMessages.messages.length > 0"
        :type="flashMessages.type"
        :messages="flashMessages.messages"
      />
      <div class="header">
        <h1 class="title">
          ユーザー一覧
        </h1>
        <Button
          v-if="hasAuthSendUserInviteAPI"
          size="inline"
          @click="onAddUser"
        >
          ＋ユーザーを追加
        </Button>
        <ButtonMore
          v-if="hasAuthSendUserInviteAPI"
          ref="csvButton"
        >
          <template #buttons>
            <button
              class="button"
              data-test="user-upload-button"
              @click="onInputFileSelect"
            >
              CSVインポート
              <input
                id="file"
                name="file"
                type="file"
                hidden
                accept=".csv"
                @change="onCSVImport"
              >
            </button>
            <button
              class="button"
              data-test="user-download-button"
              @click="onCSVDownload"
            >
              CSVダウンロード
            </button>
          </template>
        </ButtonMore>
      </div>

      <ListUser
        :items="userList"
        @click="(id) => $router.push({ name: 'VDX510', params: { id } })"
      />
    </div>
  </LayoutMain>

  <Modal
    ref="modalAddUser"
    class="modalAddUser"
  >
    <MessageBar
      v-if="modalErrorMessages.length > 0"
      type="error"
      :messages="modalErrorMessages"
    />
    <div class="title">
      ユーザーを追加
    </div>
    <FormBlock label="メールアドレス">
      <FormInputText
        v-model="emailValue"
      />
    </FormBlock>
    <FormBlock label="権限">
      <FormSelect
        v-model="roleValue"
        :options="userRoleOptions"
        @change="roleValueChange"
      />
    </FormBlock>
    <FormBlock label="グループ">
      <FormSelect
        v-model="baseValue"
        :options="basesNameOptions"
        :disabled="roleValueFrag"
      />
    </FormBlock>

    <div class="form-read mt-xs">
      お名前
    </div>
    <div class="form-row mt-xxxxs">
      <div class="form">
        <FormBlock label="姓 (Family Name)">
          <FormInputText
            v-model="familyName"
            size="width-180"
          />
        </FormBlock>
      </div>
      <div class="form">
        <FormBlock label="名 (First Name)">
          <FormInputText
            v-model="firstName"
            size="width-180"
          />
        </FormBlock>
      </div>
    </div>

    <ButtonBlock>
      <Button
        type="normal"
        size="inline"
        @click="$refs.modalAddUser?.hideModal()"
      >
        キャンセル
      </Button>
      <Button
        type="primary"
        size="inline"
        :disabled="!emailValue || !roleValue || ( !baseValue && !roleValueFrag ) || !familyName || !firstName"
        @click="onSendInvite"
      >
        追加
      </Button>
    </ButtonBlock>
  </Modal>

  <Modal
    ref="modalSendEmail"
    @close="onCompleteInvite"
  >
    <IconMail />
    <div class="title">
      招待メールを送信しました
    </div>
    <p class="text">
      {{ sendResultMessage }}
    </p>
    <div class="code">
      {{ activateCode }}<ButtonCopy :copy-text="activateCode" />
    </div>
      
    <ButtonBlock>
      <Button
        @click="$refs.modalSendEmail?.hideModal()"
      >
        閉じる
      </Button>
    </ButtonBlock>
  </Modal>

  <Modal
    ref="modalCompleteAddUser"
    @close="onCompleteInvite"
  >
    <div class="title">
      ユーザー登録完了
    </div>
    <p class="text">
      {{ sendResultMessage }}
    </p>
    <ButtonBlock>
      <Button
        @click="$refs.modalCompleteAddUser?.hideModal()"
      >
        閉じる
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import ButtonCopy from '@/components/button/ButtonCopy';
import ButtonMore from '@/components/button/ButtonMore';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import FormSelect from '@/components/form/FormSelect';
import IconMail from '@/components/icons/IconMail';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import ListUser from '@/components/user/ListUser';
import { getBasesNameApi, getUserCSVDownloadApi, getUserRoleAuthsApi, getUserRolesApi, getUsersApi, postUserCSVImportApi, sendInviteMailApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS, USER_ROLE_TYPE } from '@/utils/Constants';
import { saveAs } from 'file-saver';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    LayoutMain,
    ListUser,
    Button,
    ButtonCopy,
    ButtonMore,
    Modal,
    FormBlock,
    FormInputText,
    ButtonBlock,
    MessageBar,
    IconMail,
    FormSelect,
  },
  data() {
    return {

      /**
       * ユーザー一覧
       */
      userList: [],

      /**
       * ユーザー一覧画面に表示するフラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },

      /**
       * ユーザー追加モーダルのメールアドレス
       */
      emailValue: '',

      /**
       * ユーザー追加モーダルの権限
       */
      roleValue: '',

      /**
       * ユーザー追加モーダルのグループ
       */
      baseValue: '',

      /**
       * ユーザー追加モーダルの名前（姓名）
       */
      familyName: '',
      firstName: '',

      /**
       * ユーザー追加モーダルに表示するエラーメッセージ
       */
      modalErrorMessages: [],

      /**
       * 招待メール送信モーダルおよび登録完了モーダルに表示するメッセージ
       */
      sendResultMessage: '',

      /**
       * 招待メール送信モーダルに表示するアクティベートコード
       */
      activateCode: '',

      /**
       * ロール一覧
       */
      userRoleOptions: [],

      /**
       * グループ名一覧
       */
      basesNameOptions: [],

      /**
       * 権限一覧
       */
      authorizations: [],
    };
  },
  computed: {
    ...mapGetters(['storeMessages']),

    /**
     * 招待メール送信、ユーザ一括登録、ユーザ一括出力APIの権限チェック
     *
     * 権限が無い場合
     * ・ユーザーを追加ボタンを非表示
     * ・ユーザーCSVインポート、CSVダウンロードボタンを非表示
     */
    hasAuthSendUserInviteAPI() {
      return hasAuthorization(this.authorizations, API_IDS.SEND_USER_INVITE);
    },
    /**
     * ユーザー追加モーダルの権限のフラグ
     *
     * 管理者または閲覧者の場合
     * ・ユーザー追加モーダルのグループを非活性
     */
    roleValueFrag() {
      return this.roleValue === USER_ROLE_TYPE.ADMINISTRATOR || this.roleValue === USER_ROLE_TYPE.VIEWER ? true : false;
    },
    
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'clearStoreMessages']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 権限取得
      await this.getUserRoleAuths();
      // ユーザー一覧取得
      await this.fetchData();
      // ロール一覧取得
      await this.fetchUserRoles();
      // グループ名一覧取得
      await this.fetchBasesName();

      // ユーザー編集画面で格納したストアメッセージがあれば表示する
      this.flashMessages = this.storeMessages;
      this.clearStoreMessages();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.SEND_USER_INVITE,
        ],
      });
      this.authorizations = data.data;
    },

    /**
     * ユーザー一覧取得
     */
    async fetchData() {
      const { data } = await getUsersApi();
      this.userList = data.data;
    },

    /**
     * ロール一覧取得
     */
    async fetchUserRoles() {
      const { data } = await getUserRolesApi();
      this.userRoleOptions = data.data.map(item => (
        {
          id: item.user_role_type,
          label: item.user_role_name,
        }
      ));
      this.userRoleOptions.unshift({id: '', label: '選択してください'});
    },

    /**
     * グループ名一覧取得
     */
    async fetchBasesName() {
      const { data } = await getBasesNameApi();
      this.basesNameOptions = data.data.map(item => (
        {
          id: item.base_id,
          label: item.base_name,
        }
      ));
      this.basesNameOptions.unshift({id: '', label: '選択してください'});
    },

    /**
     * 「ユーザーを追加」ボタンのクリックイベント
     */
    onAddUser() {
      // ユーザー追加モーダルのメールアドレス初期化
      this.emailValue = '';
      // ユーザー追加モーダルの権限初期化
      this.roleValue = '';
      // ユーザー追加モーダルのグループ初期化
      this.baseValue = '';
      // ユーザー追加モーダルの名前(姓名)初期化
      this.familyName = '';
      this.firstName = '';
      // ユーザー追加モーダルのエラーメッセージクリア
      this.modalErrorMessages = [];
      // ユーザー追加モーダル表示
      this.$refs.modalAddUser?.openModal();
    },

    /**
     * ユーザー追加モーダルの「権限」セレクト時のイベント
     */
    async roleValueChange() {
      // 権限が管理者または閲覧者の場合、グループを空値および「選択してください」に設定
      if(this.roleValueFrag) this.baseValue = '';
    },

    /**
     * ユーザー追加モーダルの「招待メールを送信」ボタンのクリックイベント
     */
    async onSendInvite() {
      this.startLoading();

      // 招待メール送信APIを呼び出し
      const { status, data } = await sendInviteMailApi({
        user_email_address: this.emailValue,
        user_role_type: this.roleValue,
        base_id: this.baseValue,
        user_family_name: this.familyName,
        user_first_name: this.firstName,
      });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.modalErrorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.$refs.modalAddUser?.hideModal();

      // activate_codeの有無（およびSSO設定の有無）でのモーダル出し分け
      if(data.activate_code){
        // 招待メール送信モーダルに表示するメッセージを設定
        this.sendResultMessage = `${this.emailValue}に招待メールを送信しました。以下のアクティベートコードをお手元に控えてお伝えください`;
        // 招待メール送信モーダルに表示するアクティベートコードを設定
        this.activateCode = `${data.activate_code.substring(0, 3)} - ${data.activate_code.substring(3, 6)}`;
        // 招待メール送信モーダルを開く
        this.$refs.modalSendEmail?.openModal();
      } else {
        // 登録完了モーダルに表示するメッセージを設定
        this.sendResultMessage = data.msg;
        // 登録完了モーダルを開く
        this.$refs.modalCompleteAddUser?.openModal();
      }
      
      this.stopLoading();
    },

    /**
     * 招待メール送信モーダルおよび登録完了モーダルの「閉じる」ボタンのクリックイベント
     */
    async onCompleteInvite() {
      this.startLoading();
      // フラッシュメッセージクリア
      this.flashMessages = {
        type: '',
        messages: [],
      };
      // ユーザー一覧取得
      await this.fetchData();
      this.stopLoading();
    },

    /**
     * 「CSVインポート」ボタンのクリックイベント
     */
    onInputFileSelect() {
      this.$refs.csvButton.open = false;
      document.getElementById('file').value = ''; // input['file']の初期化
      document.getElementById('file').click();
    },
    async onCSVImport(e) {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // CSVインポートAPIの呼び出し
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { status, data } = await postUserCSVImportApi(formData);

      if (status === 200) {
        this.flashMessages.type = 'success';
        this.flashMessages.messages = data.detail.map(item => item.msg);
      } else if (status === 400 || status === 422)  {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }
      // ユーザー一覧取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * 「CSVダウンロード」ボタンのクリックイベント
     */
    async onCSVDownload() {
      this.$refs.csvButton.open = false;
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // CSVダウンロードAPIの呼び出し
      const { data, headers } = await getUserCSVDownloadApi();

      const content = headers['content-disposition'];
      const regex = content.match(/filename=(.+)/);
      const filename = regex[1];
      saveAs(data, filename);
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-xxs $spacing-xs 0;

  > .message-bar {
    margin-bottom: $spacing-xxs;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 36px;
    > .title {
      font: $sans-snug-20-bold;
      height: 32px;
      color: $black700;
    }
    > .button{
      margin-left: auto;
    }
    > .button-more-area{
      margin-left: 16px;
    }
  }

  > .list-user {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
.modalAddUser :deep(.container){
  width: 464px;
  > .form-read {
    color: $black700;
    font: $sans-none-16-bold;
    text-align: left;
    display: inline-block;
  }
  > .form-row {
    display: flex;
    justify-content: space-between;
  }
}
.modal :deep(.button-copy){
  margin: 0 0 6px $spacing-xxxs;
}
</style>
