const idTokenKey = 'id_token';

export const setIdToken = (data) => {
  localStorage.setItem(idTokenKey, JSON.stringify(data));
};

export const getIdToken = (key) => {
  const idToken = JSON.parse(localStorage.getItem(idTokenKey));
  if (idToken) {
    return idToken[key];
  } else {
    return;
  }
};

export const hasIdToken = () => {
  const idToken = JSON.parse(localStorage.getItem(idTokenKey));
  return !!idToken;
};

export const removeIdToken = () => {
  localStorage.removeItem(idTokenKey);
};

/**
 * IDトークンの項目を上書きする
 *
 * @param {*} key 項目名
 * @param {*} value 値
 */
export const updateIdToken = (key, value) => {
  const idToken = JSON.parse(localStorage.getItem(idTokenKey));
  idToken[key] = value;
  localStorage.setItem(idTokenKey, JSON.stringify(idToken));
};
