<template>
  <LayoutMain @refresh="init">
    <div class="page">
      <MessageBar
        v-if="errorMessages.length > 0"
        :messages="errorMessages"
        type="error"
        class="mb-xs"
      />
      <MessageSearchResult
        v-if="isSearched && searchResultMessage"
        :message="searchResultMessage"
        :query="searchResultQuery"
        class="mb-xs"
      />
      <div class="header mb-xs">
        <h1 class="title">
          キーワード通知一覧
        </h1>
        <ButtonSearchKeyword @click="$refs.modalSearchNoticeKeyword.openModal()" />
      </div>
      <ListNoticeKeyword
        :is-asc="query.isAsc"
        :page="query.page"
        :total-record="totalRecord"
        :record-section-from="recordSectionFrom"
        :record-section-to="recordSectionTo"
        :items="data"
        @sortAsc="sortAsc"
        @sortDesc="sortDesc"
        @prevPage="prevPage"
        @nextPage="nextPage"
        @toDetailPage="toDetailPage"
      />
    </div>
  </LayoutMain>

  <ModalSearchNoticeKeyword
    ref="modalSearchNoticeKeyword"
    v-model:keyword-id="input.keywordId"
    v-model:calling-number="input.callingNumber"
    v-model:incoming-number="input.incomingNumber"
    v-model:search-period-from="input.searchPeriodFrom"
    v-model:search-period-to="input.searchPeriodTo"
    :keyword-options="keywordOptions"
    :errors="searchErrorMessages"
    @search="search"
  />
</template>

<script>
import ButtonSearchKeyword from '@/components/button/ButtonSearchKeyword';
import MessageBar from '@/components/common/MessageBar';
import MessageSearchResult from '@/components/common/MessageSearchResult';
import LayoutMain from '@/components/layout/LayoutMain';
import ModalSearchNoticeKeyword from '@/components/modal/ModalSearchNoticeKeyword';
import ListNoticeKeyword from '@/components/notice/ListNoticeKeyword';
import { getKeywordsNameApi, getNoticeKeywordsApi } from '@/utils/ApiHelper';
import { toSnakeCaseObject } from '@/utils/CommonUtil';
import { mapActions, mapGetters } from 'vuex';

function initialize() {
  return {
    /**
     * キーワード検索モーダルの入力値
     */
    input: {
      keywordId: '',
      callingNumber: '',
      incomingNumber: '',
      searchPeriodFrom: '',
      searchPeriodTo: '',
    },

    /**
     * 検索結果
     */
    totalRecord: 0,
    recordSectionFrom: 0,
    recordSectionTo: 0,
    data: [],
    detail: [],
    searchResultMessage: '',
    searchResultQuery: '',

    /**
     * キーワード一覧
     */
    keywordOptions: [],

    /**
     * エラーメッセージ
     */
    errorMessages: [],

    /**
     * キーワード検索のエラーメッセージ
     */
    searchErrorMessages: [],
  };
}

export default {
  components: {
    LayoutMain,
    MessageSearchResult,
    ButtonSearchKeyword,
    ListNoticeKeyword,
    ModalSearchNoticeKeyword,
    MessageBar,
  },
  data() {
    return initialize();
  },
  computed: {
    ...mapGetters({
      query: 'keywordNoticeQuery',
      isSearched: 'keywordNoticeIsSearched',
      isRestore: 'keywordNoticeIsRestore',
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
    ]),
    ...mapActions({
      setQuery: 'setKeywordNoticeQuery',
      setIsSearched: 'setKeywordNoticeIsSearched',
      restoreFlagOff: 'keywordNoticeRestoreFlagOff',
    }),

    /**
     * 初期処理
     */
    async init() {
      this.startLoading();
      // dataの初期化
      Object.assign(this.$data, initialize());

      // キーワード通知一覧を取得
      if (this.isRestore) {
        // 詳細画面から一覧に戻った場合はストアから検索条件を復元
        this.input.keywordId = this.query.keywordId;
        this.input.callingNumber = this.query.callingNumber;
        this.input.incomingNumber = this.query.incomingNumber;
        this.input.searchPeriodFrom = this.query.searchPeriodFrom;
        this.input.searchPeriodTo = this.query.searchPeriodTo;
        await this.fetchData(this.query);
        this.restoreFlagOff();
      } else {
        // デフォルトの検索条件で取得
        await this.fetchData({ isAsc: false, page: 1 });
        this.setIsSearched({ isSearched: false });
      }

      // キーワード一覧を取得
      await this.fetchkeywordOptions();
      this.stopLoading();
    },

    /**
     * キーワード通知一覧を取得する
     */
    async fetchData(query) {
      // エラーメッセージクリア
      this.errorMessages = [];
      this.searchErrorMessages = [];

      const { status, data } = await getNoticeKeywordsApi(toSnakeCaseObject(query));

      // 業務エラーが返却された場合はエラーメッセージを設定して処理終了
      if (status == 400) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 取得したキーワード通知一覧を設定する
      this.totalRecord = data.total_record;
      this.recordSectionFrom = data.record_section_from;
      this.recordSectionTo = data.record_section_to;
      this.data = data.data;
      this.searchResultMessage = data.detail[0].msg;
      this.searchResultQuery = data.detail[0].query;

      // 検索クエリをストアに保存
      this.setQuery({ query });
    },

    /**
     * キーワード一覧を取得する
     */
    async fetchkeywordOptions() {
      const { data } = await getKeywordsNameApi();
      const options = data.data.map(item => ({
        id: item.keyword_id,
        label: item.keyword,
      }));
      const defaultOption = [{ id: '', label: '選択してください' }];
      this.keywordOptions = defaultOption.concat(options);
    },

    /**
     * 通話開始日時の新しい順に並び替える
     */
    async sortDesc() {
      this.startLoading();
      await this.fetchData({
        ...this.query,
        ...{
          isAsc: false,
          page: 1,
        },
      });
      this.stopLoading();
    },

    /**
     * 通話開始日時の古い順に並び替える
     */
    async sortAsc() {
      this.startLoading();
      await this.fetchData({
        ...this.query,
        ...{
          isAsc: true,
          page: 1,
        },
      });
      this.stopLoading();
    },

    /**
     * 前のページを表示する
     */
    async prevPage() {
      this.startLoading();
      await this.fetchData({
        ...this.query,
        ...{
          page: this.query.page - 1,
        },
      });
      this.stopLoading();
    },

    /**
     * 次のページを表示する
     */
    async nextPage() {
      this.startLoading();
      await this.fetchData({
        ...this.query,
        ...{
          page: this.query.page + 1,
        },
      });
      this.stopLoading();
    },

    /**
     * キーワード検索する
     */
    async search() {
      this.startLoading();
      // エラーメッセージクリア
      this.errorMessages = [];
      this.searchErrorMessages = [];

      const query = {
        keywordId: this.input.keywordId,
        callingNumber: this.input.callingNumber,
        incomingNumber: this.input.incomingNumber,
        searchPeriodFrom: this.input.searchPeriodFrom,
        searchPeriodTo: this.input.searchPeriodTo,
        isAsc: this.query.isAsc,
        page: 1,
      };
      const { status, data } = await getNoticeKeywordsApi(toSnakeCaseObject(query));

      // キーワード検索でエラーが返却された場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.searchErrorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 取得したキーワード通知一覧を設定する
      this.totalRecord = data.total_record;
      this.recordSectionFrom = data.record_section_from;
      this.recordSectionTo = data.record_section_to;
      this.data = data.data;
      this.searchResultMessage = data.detail[0].msg;
      this.searchResultQuery = data.detail[0].query;

      // 検索クエリをストアに保存
      this.setIsSearched({ isSearched: true });
      this.setQuery({ query });
      this.$refs.modalSearchNoticeKeyword.hideModal();
      this.stopLoading();
    },

    toDetailPage(id) {
      this.$router.push({ name: 'VDXA10', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-sm $spacing-xs 0;
  > .header {
    display: flex;
    justify-content: space-between;
    min-height: 36px;
    > .title {
      font: $sans-snug-20-bold;
      color: $black700;
      height: 36px;
    }
  }
  > .list-notice-keyword {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
</style>
