<template>
  <Modal
    ref="modalDownloadUnableCreation"
  >
    <div class="title">
      ダウンロードデータ作成
    </div>
    <p class="text">
      {{ msg }}
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="hideModal"
      >
        キャンセル
      </Button>
      <Button
        v-if="isDownloadLimit"
        size="inline"
        type="primary"
        @click="$emit('move')"
      >
        ダウンロード状況確認
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    Modal,
  },
  props: {
    isDownloadLimit: {
      type: Boolean,
      default: false,
    },
    isAllReceiving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['move'],
  computed: {
    msg() {
      let message = '';
      if (this.isDownloadLimit) {
        message = 'ダウンロードデータが上限に達しました。ダウンロードデータを作成するには、ダウンロード状況よりダウンロードデータを削除してください。';
      } else if (!this.isDownloadLimit && this.isAllReceiving){
        message = '音声データ受信中はダウンロードデータを作成できません。';
      }
      return message;
    },
  },
  methods: {
    openModal() {
      this.$refs.modalDownloadUnableCreation.openModal();
    },
    hideModal() {
      this.$refs.modalDownloadUnableCreation.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  font: $sans-tight-14;
  color: $black700;
  padding-left: 1em;
  text-indent: -1em;
}
</style>
