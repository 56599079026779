<template>
  <IconBase :size="size">
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.6 6.3999H6.4C2.88 6.3999 0 9.2799 0 12.7999V51.1999C0 54.7199 2.88 57.5999 6.4 57.5999H57.6C61.12 57.5999 64 54.7199 64 51.1999V12.7999C64 9.2799 61.12 6.3999 57.6 6.3999ZM56.32 19.9999L35.392 33.0879C33.312 34.3999 30.688 34.3999 28.608 33.0879L7.68 19.9999C6.88 19.4879 6.4 18.6239 6.4 17.6959C6.4 15.5519 8.736 14.2719 10.56 15.3919L32 28.7999L53.44 15.3919C55.264 14.2719 57.6 15.5519 57.6 17.6959C57.6 18.6239 57.12 19.4879 56.32 19.9999Z"
        fill="#B1B2B5"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
