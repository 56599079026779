<template>
  <LayoutMain>
    <div class="actions">
      <MessageBar
        v-if="errorMessages.length > 0"
        :messages="errorMessages"
        type="error"
        class="error-messages"
        data-test="error-messages"
      />
      <div class="menu">
        <button
          class="button"
          data-test="back-to-list-button"
          @click="backToList"
        >
          <IconBack />
        </button>
        <div class="right">
          <ButtonRestore
            v-if="hasAuthRestoreTrashListAPI && currentVoiceFileSummaryId"
            @click="restoreRecord"
          />
          <Pagination
            v-if="currentVoiceFileSummaryId"
            :has-prev="!!prevVoiceFileSummaryId"
            :has-next="!!nextVoiceFileSummaryId"
            @prev="prevRecord"
            @next="nextRecord"
          />
        </div>
      </div>
    </div>
    <div
      v-if="currentVoiceFileSummaryId"
      class="body"
    >
      <MessageBar
        type="warn"
        :messages="[deletingMessage]"
        class="deleting-message"
        data-test="info-message"
      />
      <SubjectArea
        :call-start-datetime="callStartDatetime"
        :call-end-datetime="callEndDatetime"
        :calling-number="callingNumber"
        :calling-name="callingName"
        :incoming-number="incomingNumber"
        :incoming-name="incomingName"
        :calling-time="callingTime"
      />
      <p
        v-if="isPartition"
        class="text"
      >
        通話が1時間を超えるため、記録が分割されます
      </p>
      <p
        v-if="isReceiving"
        class="text-green"
      >
        音声データを受信中...
      </p>
      <TabPart
        v-if="isPartition"
        :model-value="currentPart"
        :items="part"
        @change="changePart"
      />
      <ChatList
        v-if="status === 'normal'"
        :items="data"
      />
      <p
        v-if="status === 'stt_nothing'"
        class="text-normal"
      >
        音声テキスト化プランが必要です
      </p>
      <p
        v-if="status === 'text_nothing'"
        class="text-normal"
      >
        テキストデータがありません<br>
        （処理中の場合、しばらくたつとテキストに変換されます。時間をおいて再度アクセスしてください）
      </p>
      <p
        v-if="status === 'stt_error'"
        class="text-normal"
      >
        こちらの記録は、文字起こしに失敗しました
      </p>
    </div>
  </LayoutMain>
</template>

<script>
import ButtonRestore from '@/components/button/ButtonRestore';
import MessageBar from '@/components/common/MessageBar';
import Pagination from '@/components/common/Pagination';
import IconBack from '@/components/icons/IconBack';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import SubjectArea from '@/components/layout/SubjectArea';
import ChatList from '@/components/voice/ChatList';
import TabPart from '@/components/voice/TabPart';
import { getRecordApi, getUserRoleAuthsApi, restoreRecordsApi } from '@/utils/ApiHelper';
import { hasAuthorization, toSnakeCaseObject } from '@/utils/CommonUtil';
import { API_IDS } from '@/utils/Constants';
import { mapActions, mapGetters } from 'vuex';

function initialState() {
  return {
    currentVoiceFileSummaryId: '',
    currentPart: 1,
    prevVoiceFileSummaryId: '',
    nextVoiceFileSummaryId: '',
    callingNumber: '',
    incomingNumber: '',
    callingTime: '',
    callStartDatetime: '',
    callEndDatetime: '',
    isPartition: false,
    isReceiving: false,
    deletingMessage: '',
    status: '',
    part: [],
    data: [],
    /**
     * ゴミ箱詳細画面に表示するエラーメッセージ
     */
    errorMessages: [],
  };
}

export default {
  components: {
    LayoutMain,
    IconBack,
    ButtonRestore,
    Pagination,
    ChatList,
    SubjectArea,
    TabPart,
    MessageBar,
  },
  data() {
    return {
      ...initialState(),
      ...{
        /**
         * 権限一覧
         */
        authorizations: [],
      },
    };
  },
  computed: {
    ...mapGetters(['query', 'currentIndex']),

    /**
     * 記録削除取消APIの権限チェック
     *
     * 権限が無い場合
     * ・削除を取り消すボタンを非表示
     */
    hasAuthRestoreTrashListAPI() {
      return hasAuthorization(this.authorizations, API_IDS.RESTORE_TRASH_LIST);
    },
  },
  watch: {
    async $route(to, from) {
      if (to.name === from.name) {
        this.startLoading();
        // dataの初期化
        Object.assign(this.$data, initialState());
        // 記録詳細取得
        await this.fetchRecord();
        this.stopLoading();
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setQuery',
      'setRestoreFlagOn',
      'setStoreMessages',
      'prevIndex',
      'nextIndex',
      'setUserRoleAuths',
    ]),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();
      // 権限取得
      await this.getUserRoleAuths();
      // 記録詳細取得
      await this.fetchRecord();
      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.RESTORE_TRASH_LIST,
          API_IDS.GET_DOWNLOAD_DATA,
        ],
      });
      this.authorizations = data.data;

      // 権限をストアに保存
      this.setUserRoleAuths({
        userRoleAuths: this.authorizations,
      });
    },

    /**
     * 記録詳細を取得する
     */
    async fetchRecord(part = 1) {
      // 記録詳細取得APIを呼び出す
      const { status, data } = await getRecordApi(
        { id: this.$route.params.id },
        {
          part,
          is_deleted: true,
          query: toSnakeCaseObject({
            ...this.query,
            ...{
              page: Math.ceil(this.currentIndex / 50),
              isDeleted: true,
            },
          }),
        },
      );

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された記録詳細を設定する
      this.currentVoiceFileSummaryId = data.current_voice_file_summary_id;
      this.currentPart = data.current_part;
      this.prevVoiceFileSummaryId = data.prev_voice_file_summary_id;
      this.nextVoiceFileSummaryId = data.next_voice_file_summary_id;
      this.callingNumber = data.calling_number;
      this.callingName = data.calling_name;
      this.incomingNumber = data.incoming_number;
      this.incomingName = data.incoming_name;
      this.callingTime = data.calling_time;
      this.callStartDatetime = data.call_start_datetime;
      this.callEndDatetime = data.call_end_datetime;
      this.isPartition = data.is_partition;
      this.isReceiving = data.is_receiving;
      this.deletingMessage = data.deleting_message;
      this.status = data.status;
      this.part = data.part;
      this.data = data.data;
    },

    /**
     * 記録の削除を取り消す
     */
    async restoreRecord() {
      this.startLoading();

      // 記録削除取消APIを呼び出す
      const { status, data } = await restoreRecordsApi({
        summary_ids: [this.currentVoiceFileSummaryId],
      });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを表示
      if (status === 400 || status === 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      // ゴミ箱画面で検索条件を復元する（削除取消した場合は１ページ目を表示）
      this.setQuery({
        query: {
          ...this.query,
          ...{
            page: 1,
          },
        },
      });
      this.setRestoreFlagOn();

      // ゴミ箱画面に遷移する
      this.$router.push({ name: 'VDX200' });
      this.stopLoading();
    },

    /**
     * 一覧に戻る
     */
    backToList() {
      // ゴミ箱画面で検索条件を復元する
      this.setRestoreFlagOn();
      this.$router.push({ name: 'VDX200' });
    },

    /**
     * 前の記録を表示
     */
    prevRecord() {
      this.prevIndex();
      this.$router.push({
        name: 'VDX210',
        params: { id: this.prevVoiceFileSummaryId },
      });
    },

    /**
     * 次の記録を表示
     */
    nextRecord() {
      this.nextIndex();
      this.$router.push({
        name: 'VDX210',
        params: { id: this.nextVoiceFileSummaryId },
      });
    },

    /**
     * 表示Partを変更する
     */
    async changePart(part) {
      this.startLoading();
      // dataの初期化
      Object.assign(this.$data, initialState());
      // 記録詳細取得
      await this.fetchRecord(part);
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
  }

  .error-messages {
    margin: $spacing-xxs;
    margin-bottom: 0;
  }
}

.deleting-message {
  margin-bottom: 16px;
}

.menu {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $spacing-xs;
  border-bottom: 1px solid $gray400;

  > .button {
    height: 16px;
    padding: 0;
  }

  > .right {
    display: flex;
    align-items: center;

    :deep(.pagination-block) {
      > .button-block {
        margin-left: 112px;
        position: relative;

        &::before {
          position: absolute;
          content: "";
          width: 1px;
          height: 16px;
          top: 50%;
          left: -56px;
          transform: translateY(-50%);
          background-color: $gray500;
        }
      }
    }
  }
}

.body {
  padding: $spacing-xxs $spacing-lg $spacing-xlg;
  overflow-y: scroll;

  > .chat-list {
    margin-top: $spacing-lg;
    flex: 1;
  }

  > .tab-part {
    margin-top: $spacing-xxs;
  }

  > .text {
    margin-top: $spacing-lg;
    font: $sans-none-14;
    color: $black700;

    &-normal {
      font: $sans-normal-14;
      margin-top: $spacing-lg;
      color: $black700;
    }

    &-green {
      margin-top: $spacing-xxs;
      font: $sans-none-14;
      color: $green500;
    }
  }
}
</style>
