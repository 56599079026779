<template>
  <div class="button-download">
    <button
      class="button"
      :disabled="!canVoiceDl && !canTextDl"
      data-test="download-button"
      @click="toggle"
    >
      <IconDownload />
      <div class="text">
        ダウンロード
      </div>
    </button>
    <div
      v-if="open"
      class="container"
    >
      <button
        v-if="canVoiceDl"
        class="button"
        data-test="voice-download-button"
        @click="onVoice"
      >
        <div class="text">
          {{ items[0].text }}
        </div>
        <div class="extension">
          {{ items[0].extension }}
        </div>
      </button>
      <button
        v-if="canTextDl"
        class="button"
        data-test="text-download-button"
        @click="onText"
      >
        <div class="text">
          {{ items[1].text }}
        </div>
        <div class="extension">
          {{ items[1].extension }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import IconDownload from '@/components/icons/IconDownload';

export default {
  components: {
    IconDownload,
  },
  props: {
    canVoiceDl: {
      type: Boolean,
      default: false,
    },
    canTextDl: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['voice', 'text'],
  data: () => ({
    open: false,
    items: [
      {
        text: '音声',
        extension: '.wav',
      },
      {
        text: 'テキスト',
        extension: '.csv',
      },
    ],
  }),
  mounted() {
    window.addEventListener('click', this.closeDropDown);
  },
  unmounted() {
    window.removeEventListener('click', this.closeDropDown);
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    onVoice() {
      this.open = false;
      this.$emit('voice');
    },
    onText() {
      this.open = false;
      this.$emit('text');
    },
    closeDropDown(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.open = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-download {
  width: 108px;
  position: relative;

  > .button {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;

    > .text {
      font: $sans-none-14;
      color: $black500;
      margin-left: $spacing-xxxxs;
    }
  }

  > .container {
    position: absolute;
    top: 31px;
    left: 50%;
    transform: translateX(-50%);
    padding: $spacing-xxxxs 0;
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.1);
    border-radius: $radius8;
    background-color: $white100;
    z-index: $z-dropdown;

    > .button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 132px;
      height: 38px;
      padding: $spacing-xxxs $spacing-xxs;
      color: $black700;
      font: $sans-none-14;

      > .extension {
        text-align: left;
        width: 34px;
        color: $black300;
      }
    }
  }
}
</style>
