<template>
  <div class="talk-record-area">
    <TalkList
      v-if="status === 'normal'"
      :data="data"
      :keywords="keywords"
    />
    <p
      v-if="status === 'stt_nothing'"
      class="message"
    >
      音声テキスト化プランが必要です
    </p>
    <p
      v-if="status === 'text_nothing'"
      class="message"
    >
      テキストデータがありません<br>
      （処理中の場合、しばらくたつとテキストに変換されます。時間をおいて再度アクセスしてください）
    </p>
    <p
      v-if="status === 'stt_error'"
      class="message"
    >
      こちらの記録は、文字起こしに失敗しました
    </p>
  </div>
</template>

<script>
import TalkList from '@/components/voice/TalkList';

export default {
  components: {
    TalkList,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    keywords: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.talk-record-area {
  > .message {
    font: $sans-normal-14;
    color: $black700;
  }
}
</style>
