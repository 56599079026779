<template>
  <IconBase :size="size">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.70999 7.71L7.29999 10.3C7.68999 10.69 8.31999 10.69 8.70999 10.3L11.3 7.71C11.93 7.08 11.48 6 10.59 6H5.40999C4.51999 6 4.07999 7.08 4.70999 7.71Z"
        fill="#4EB8FF"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';
export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
