<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.66602 14.6667C3.39935 14.6667 3.16602 14.5667 2.96602 14.3667C2.76602 14.1667 2.66602 13.9334 2.66602 13.6667V2.33337C2.66602 2.06671 2.76602 1.83337 2.96602 1.63337C3.16602 1.43337 3.39935 1.33337 3.66602 1.33337H9.68268L13.3327 4.98337V13.6667C13.3327 13.9334 13.2327 14.1667 13.0327 14.3667C12.8327 14.5667 12.5993 14.6667 12.3327 14.6667H3.66602ZM9.18268 5.43337V2.33337H3.66602V13.6667H12.3327V5.43337H9.18268Z"
      fill="white"
    />
  </svg>
</template>
