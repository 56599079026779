<template>
  <div class="form-radio-block">
    <div
      v-for="(option, i) in options"
      :key="i"
      class="container"
    >
      <input
        :id="`${name}-${option[valueKey]}`"
        v-model="value"
        type="radio"
        class="input"
        :name="name"
        :value="option[valueKey]"
        :disabled="disabled"
      >
      <label
        :for="`${name}-${option[valueKey]}`"
        class="form-radio"
        :class="{
          'is-disabled': disabled,
          'is-error': isError,
        }"
      >
        <span class="form-radio-label">{{ option[labelKey] }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: 'radio',
    },
    valueKey: {
      type: String,
      default: 'id',
    },
    labelKey: {
      type: String,
      default: 'text',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.form-radio-block {
  display: flex;
  flex-flow: wrap;

  .input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    overflow: hidden;
    padding: 0;
    clip: rect(0, 0, 0, 0);

    + .form-radio {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      height: $form-radio-check;
      margin-right: $spacing-xxxs;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: $form-radio-check;
        height: $form-radio-check;
        background-color: $white100;
        border: 1px solid $gray600;
        border-radius: 50%;
        box-sizing: border-box;
        background-repeat: no-repeat;
      }

      &.is-error {
        &::before {
          background-color: $red100;
          border-color: $red300;
        }
      }

      &.is-disabled {
        cursor: not-allowed;

        &::before {
          background-color: $gray500;
          border-color: $gray500;
        }
      }

      > .form-radio-label {
        font: $sans-none-16;
        color: $black700;
        margin-left: 26px;
      }
    }

    &:checked {
      + .form-radio {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 9px;
          height: 9px;
          background-color: $primary500;
          border-radius: 50%;
          transform: translate(4.5px, 4.5px);
        }

        &.is-error {
          &::after {
            background-color: $red300;
          }
        }

        &.is-disabled {
          &::after {
            background-color: $black200;
          }
        }
      }
    }
  }
}
</style>
