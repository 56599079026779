<template>
  <Modal
    ref="modalDownloadCompleteCreation"
  >
    <div class="title">
      ダウンロードデータ作成
    </div>
    <p class="text">
      ダウンロードデータ作成を受付ました。
    </p>
    <p class="note mt-xs">
      ※作成したデータはダウンロード状況でご確認できます。
    </p>
    <ButtonBlock>
      <Button
        size="inline"
        type="normal"
        @click="hideModal"
      >
        閉じる
      </Button>
      <Button
        size="inline"
        type="primary"
        @click="$emit('move')"
      >
        ダウンロード状況確認
      </Button>
    </ButtonBlock>
  </Modal>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import Modal from '@/components/common/Modal';

export default {
  components: {
    Button,
    ButtonBlock,
    Modal,
  },
  emits: ['move'],
  methods: {
    openModal() {
      this.$refs.modalDownloadCompleteCreation.openModal();
    },
    hideModal() {
      this.$refs.modalDownloadCompleteCreation.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  font: $sans-tight-14;
  color: $black700;
  padding-left: 1em;
  text-indent: -1em;
}
</style>
