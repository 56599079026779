import { createStore } from 'vuex';

export default createStore({
  state: {
    /**
     * ローディング表示／非表示フラグ
     */
    isLoading: false,
    /**
     * ページまたぎでメッセージを保持したい場合に使用する
     */
    storeMessages: {
      type: '',
      messages: [],
    },
    // TODO 検索条件を記録一覧用とゴミ箱一覧用に分ける(現在は共通の為)
    /**
     * 記録検索条件
     */
    query: {
      isAsc: false,
      page: 1,
      isDeleted: false,
    },
    /**
     * 検索タイプ
     *
     * Constants.js
     * RECORD_SEARCH_TYPEの「FULL_TEXT」または「DETAIL」を設定する
     */
    searchType: null,
    /**
     * 詳細から一覧に戻ったときに検索条件を復元するかどうか
     */
    isRestore: false,
    /**
     * 記録の検索結果をもとに、詳細画面に表示している記録のインデックス番号を保持する
     * 記録詳細情報取得APIのリクエストパラメータのページ数を設定するために保持する
     */
    currentIndex: 1,
    /**
     * アクティベートメールアドレス・姓名
     *
     * アクティベートコード検証後に返却されるメールアドレス・姓名を初期登録画面で使用する
     */
    userEmailAddress: '',
    userFamilyName: '',
    userFirstName: '',
    /**
     * アクティベートステータス確認用クエリ
     *
     * アクティベート画面でURLから取得したクエリを初期登録画面でも使用する
     */
    activateQuery: '',
    /**
     * ユーザー権限
     *
     * サイドメニュー表示・非表示の制御を行う為に使用する
     */
    userRoleAuths: [],

    /**
     * キーワード通知一覧画面
     */
    keywordNotice: {
      /**
       * 検索クエリ
       */
      query: {
        keywordId: '',
        callingNumber: '',
        incomingNumber: '',
        searchPeriodFrom: '',
        searchPeriodTo: '',
        isAsc: false,
        page: 1,
      },
      /**
       * 検索したかどうか
       */
      isSearched: false,
      /**
       * 詳細から一覧に戻ったときに検索条件を復元するかどうか
       */
      isRestore: false,
    },
  },
  getters: {
    isLoading: (state) => {
      return state.isLoading;
    },
    storeMessages: (state) => {
      return state.storeMessages;
    },
    query: (state) => {
      return state.query;
    },
    searchType: (state) => {
      return state.searchType;
    },
    isRestore: (state) => {
      return state.isRestore;
    },
    currentIndex: (state) => {
      return state.currentIndex;
    },
    userEmailAddress: (state) => {
      return state.userEmailAddress;
    },
    userFamilyName: (state) => {
      return state.userFamilyName;
    },
    userFirstName: (state) => {
      return state.userFirstName;
    },
    activateQuery: (state) => {
      return state.activateQuery;
    },
    userRoleAuths: (state) => {
      return state.userRoleAuths;
    },
    keywordNoticeQuery: (state) => {
      return state.keywordNotice.query;
    },
    keywordNoticeIsSearched: (state) => {
      return state.keywordNotice.isSearched;
    },
    keywordNoticeIsRestore: (state) => {
      return state.keywordNotice.isRestore;
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.isLoading = payload.isLoading;
    },
    setStoreMessages(state, payload) {
      state.storeMessages = payload.storeMessages;
    },
    setQuery(state, payload) {
      state.query = payload.query;
    },
    setSearchType(state, payload) {
      state.searchType = payload.searchType;
    },
    setRestoreFlag(state, payload) {
      state.isRestore = payload.isRestore;
    },
    setCurrentIndex(state, payload) {
      state.currentIndex = payload.currentIndex;
    },
    setUserEmailAddress(state, payload) {
      state.userEmailAddress = payload.userEmailAddress;
    },
    setUserFamilyName(state, payload) {
      state.userFamilyName = payload.userFamilyName;
    },
    setUserFirstName(state, payload) {
      state.userFirstName = payload.userFirstName;
    },
    setActivateQuery(state, payload) {
      state.activateQuery = payload.activateQuery;
    },
    setUserRoleAuths(state, payload) {
      state.userRoleAuths = payload.userRoleAuths;
    },
    setKeywordNoticeQuery(state, payload) {
      state.keywordNotice.query = payload.query;
    },
    setKeywordNoticeIsSearched(state, payload) {
      state.keywordNotice.isSearched = payload.isSearched;
    },
    setKeywordNoticeIsRestore(state, payload) {
      state.keywordNotice.isRestore = payload.isRestore;
    },
  },
  actions: {
    startLoading({ commit }) {
      commit('setLoading', { isLoading: true });
    },
    stopLoading({ commit }) {
      commit('setLoading', { isLoading: false });
    },
    setStoreMessages({ commit }, payload) {
      commit('setStoreMessages', payload);
    },
    clearStoreMessages({ commit }) {
      commit('setStoreMessages', {
        storeMessages: {
          type: '',
          messages: [],
        },
      });
    },
    setQuery({ commit }, payload) {
      commit('setQuery', payload);
    },
    setSearchType({ commit }, payload) {
      commit('setSearchType', payload);
    },
    setRestoreFlagOn({ commit }) {
      commit('setRestoreFlag', { isRestore: true });
    },
    setRestoreFlagOff({ commit }) {
      commit('setRestoreFlag', { isRestore: false });
    },
    setCurrentIndex({ commit }, payload) {
      commit('setCurrentIndex', payload);
    },
    prevIndex({ commit, getters }) {
      let currentIndex = getters.currentIndex;
      if (currentIndex <= 1) return;
      currentIndex--;
      commit('setCurrentIndex', { currentIndex });
    },
    nextIndex({ commit, getters }) {
      let currentIndex = getters.currentIndex;
      currentIndex++;
      commit('setCurrentIndex', { currentIndex });
    },
    setUserEmailAddress({ commit }, payload) {
      commit('setUserEmailAddress', payload);
    },
    setUserFamilyName({ commit }, payload) {
      commit('setUserFamilyName', payload);
    },
    setUserFirstName({ commit }, payload) {
      commit('setUserFirstName', payload);
    },
    setActivateQuery({ commit }, payload) {
      commit('setActivateQuery', payload);
    },
    setUserRoleAuths({ commit }, payload) {
      commit('setUserRoleAuths', payload);
    },
    setKeywordNoticeQuery({ commit }, payload) {
      commit('setKeywordNoticeQuery', payload);
    },
    setKeywordNoticeIsSearched({ commit }, payload) {
      commit('setKeywordNoticeIsSearched', payload);
    },
    keywordNoticeRestoreFlagOn({ commit }) {
      commit('setKeywordNoticeIsRestore', { isRestore: true });
    },
    keywordNoticeRestoreFlagOff({ commit }) {
      commit('setKeywordNoticeIsRestore', { isRestore: false });
    },
  },
  modules: {
  },
});
