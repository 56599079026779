<template>
  <button
    class="button-forward"
    @click="$emit('click')"
  >
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_8812_102253)">
        <path
          d="M22.5 16.25C22.5 20.3875 19.1375 23.75 15 23.75C10.8625 23.75 7.5 20.3875 7.5 16.25C7.5 12.1125 10.8625 8.75 15 8.75V13.75L21.25 7.5L15 1.25V6.25C9.475 6.25 5 10.725 5 16.25C5 21.775 9.475 26.25 15 26.25C20.525 26.25 25 21.775 25 16.25H22.5Z"
          fill="#4A4B53"
        />
        <path
          d="M13.575 19.925V14.5875H13.4625L11.25 15.375V16.2375L12.5125 15.85V19.925H13.575Z"
          fill="#4A4B53"
        />
        <path
          d="M15.3125 16.8V17.725C15.3125 20.1 16.95 20 17.1125 20C17.2875 20 18.9125 20.1125 18.9125 17.725V16.8C18.9125 14.425 17.275 14.525 17.1125 14.525C16.9375 14.525 15.3125 14.4125 15.3125 16.8ZM17.8625 16.65V17.8625C17.8625 18.825 17.6 19.15 17.125 19.15C16.65 19.15 16.375 18.825 16.375 17.8625V16.65C16.375 15.7125 16.65 15.3875 17.1125 15.3875C17.5875 15.375 17.8625 15.7125 17.8625 16.65Z"
          fill="#4A4B53"
        />
      </g>
      <defs>
        <clipPath id="clip0_8812_102253">
          <rect
            width="30"
            height="30"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  </button>
</template>

<script>
export default {
  emits: ['click'],
};
</script>

<style lang="scss" scoped>
.button-forward {
  margin: 0;
  padding: 0;
}
</style>
