<template>
  <li class="listitem-base">
    <a
      class="link"
      @click="onClick"
    >
      <div class="base-id">
        {{ baseDisplayId }}
      </div>
      <div class="base-name">
        {{ baseName }}
      </div>
      <div class="tel-numbers">
        {{ telNumbers }}
      </div>
      <div class="date">
        {{ createDatetime }}
      </div>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    baseId: {
      type: String,
      default: '',
    },
    baseDisplayId: {
      type: String,
      default: '',
    },
    baseName: {
      type: String,
      default: '',
    },
    telNumbers: {
      type: String,
      default: '',
    },
    createDatetime: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click', this.baseId);
    },
  },
};
</script>

<style lang="scss" scoped>
.listitem-base {
  border-bottom: 1px solid $gray400;
  background-color: $white100;

  .link {
    display: flex;
    text-decoration: none;
    cursor: pointer;

    > .base-id,
    .base-name,
    .tel-numbers,
    .date {
      font: $sans-none-14;
      color: $black700;
      line-height: 14px;
      padding: 13px 14px 13px 0;
    }

    > .base-id,
    .base-name,
    .tel-numbers {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .base-id {
      flex-basis: 120px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .base-name {
      max-width: calc((100% - 270px) * 0.35);
      flex-basis: calc((100% - 270px) * 0.35);
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .tel-numbers{
      max-width: calc((100% - 270px) * 0.65);
      flex-basis: calc((100% - 270px) * 0.65);
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .date {
      flex-basis: 150px;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: right;
    }
  }
}
</style>
