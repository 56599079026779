<template>
  <div
    class="form-date-picker"
    :class="size"
  >
    <DatePicker
      :value="value"
      class="form-date-picker__input"
      :class="{
        'is-disabled': disabled,
        'is-error': isError,
      }"
      :format="format"
      :title-format="format"
      :type="type"
      :lang="lang"
      :clearable="clearable"
      :placeholder="placeholder"
      :disabled-date="disabledDates"
      :min="min"
      :max="max"
      :disabled="disabled"
      :popup-class="popupClass"
      :editable="false"
      value-type="format"
      @update:value="value = $event"
    />
    <IconCalendar
      v-if="type === 'date'"
      class="form-date-picker__icon"
      :class="{
        'is-disabled': disabled,
      }"
    />
    <IconClock
      v-if="type === 'time'"
      class="form-date-picker__icon"
      :class="{
        'is-disabled': disabled,
      }"
    />
  </div>
</template>

<script>
import IconCalendar from '@/components/icons/IconCalendar';
import IconClock from '@/components/icons/IconClock';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/locale/ja';

export default {
  components: {
    DatePicker,
    IconCalendar,
    IconClock,
  },
  props: {
    modelValue: {
      type: [String, Date],
      default: new Date(),
    },
    size: {
      type: String,
      default: '',
    },
    type: {
      validator: function (value) {
        return ['month', 'date', 'time'].indexOf(value) !== -1;
      },
      default: 'date',
    },
    placeholder: {
      type: String,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Date],
      default: null,
    },
    max: {
      type: [String, Date],
      default: new Date(),
    },
    isUseInModal: {
      type: Boolean,
      default: false,
    },
    isDisabledFutureDates: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      lang: {
        monthBeforeYear: false,
        yearFormat: 'YYYY年 ',
        monthFormat: 'MM月',
      },
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
    format() {
      return this.type === 'time' ? 'HH:mm' : this.type === 'date' ? 'YYYY/MM/DD' : 'YYYY/MM';
    },
    popupClass() {
      let classes = [];
      if (this.isUseInModal) classes.push('is-use-in-modal');
      return classes;
    },
  },
  methods: {
    disabledDates(date) {
      if (this.isDisabledFutureDates) {
        const today = this.max; //例 new Date();
        const toDate = this.min; //例 new Date('20191002'); or today.setMonth(today.getMonth() - 24);

        if (today === null) {
          return date < toDate;
        } else {
          return date > today || date < toDate;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.form-date-picker {
  position: relative;
  width: 100%;

  &.width-120 {
    width: $form-size-120;
  }

  &.width-180 {
    width: $form-size-180;
  }

  &.width-260 {
    width: $form-size-260;
  }

  &.width-320 {
    width: $form-size-320;
  }

  &.width-400 {
    width: $form-size-400;
  }

  :deep(.mx-datepicker) {
    width: 100%;
  }

  .form-date-picker__input {
    :deep(.mx-input) {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: $input-height;
      padding: 0 $spacing-xxxs;
      background: $white100;
      border-radius: $radius4;
      outline: none;
      color: $black700;
      font: $sans-none-16;
      cursor: pointer;

      &::placeholder {
        color: $black400;
      }

      &:focus {
        border-color: $black400;
      }
    }

    &.is-error {
      :deep(.mx-input) {
        background: $red100;
        border-color: $red300;
        color: $black400;
      }
    }

    &.is-disabled {
      :deep(.mx-input) {
        background: $gray200;
        border-color: $gray600;
        color: $black400;
        cursor: not-allowed;
      }
    }

    :deep(.mx-icon-calendar) {
      display: none;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 10px;
    line-height: 0;
    pointer-events: none;

    &.is-disabled {
      :deep(path) {
        fill: #999;
      }
    }
  }
}
</style>

<style lang="scss">
.mx-icon-left::before,
.mx-icon-right::before,
.mx-icon-double-left::before,
.mx-icon-double-right::before,
.mx-icon-double-left::after,
.mx-icon-double-right::after {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-style: solid;
  border-color: currentColor;
  border-width: 2px 0 0 2px;
  border-radius: 1px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(-45deg) scale(0.7);
  transform: rotate(-45deg) scale(0.7);
}

.mx-icon-double-left::after {
  left: -4px;
}

.mx-icon-double-right::before {
  left: 4px;
}

.mx-icon-right::before,
.mx-icon-double-right::before,
.mx-icon-double-right::after {
  -webkit-transform: rotate(135deg) scale(0.7);
  transform: rotate(135deg) scale(0.7);
}

.mx-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: $radius4;
  color: $black700;
  white-space: nowrap;
}

.mx-btn:hover {
  border-color: $primary400;
  color: $primary400;
}

.mx-calendar-panel-year .mx-calendar-header-label::after,
.mx-calendar-panel-month .mx-calendar-header-label::after {
  content: "年";
}

.mx-btn-text {
  border: 0;
  padding: 0 4px;
  text-align: left;
  line-height: inherit;
}

.mx-scrollbar {
  height: 100%;
}

.mx-input {
  display: inline-block;
  width: 100%;
  height: 34px;
  padding: 6px 30px;
  padding-left: 10px;
  font-size: 14px;
  color: $black700;
  background-color: $white100;
  border: 1px solid $gray600;
  border-radius: $radius4;
}

.mx-input:disabled,
.mx-input.disabled {
  color: $black400;
  background-color: $gray200;
  border-color: $gray600;
  cursor: not-allowed;
}

.mx-datepicker-main {
  font: $sans-none-14;
  color: $black700;
  background-color: $white100;
  border: 1px solid $gray500;
}

.mx-datepicker-popup {
  position: absolute;
  z-index: 2001;
}

.mx-calendar {
  width: 273px;
  padding: 17px 14px 14px 14px;
}

.mx-calendar-header,
.mx-time-header {
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
}

.mx-btn-icon-left,
.mx-btn-icon-double-left {
  float: left;
}

.mx-btn-icon-right,
.mx-btn-icon-double-right {
  float: right;
}

.mx-calendar-decade-separator {
  margin: 0 2px;
}

.mx-calendar-decade-separator::after {
  content: "~";
}

.mx-calendar-content {
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mx-calendar-content .cell {
  cursor: pointer;
  border: 1px solid $white100;
  box-sizing: border-box;
  border-radius: $radius4;
}

.mx-table.mx-table-month .cell,
.mx-table.mx-table-year .cell {
  min-width: 82px;
  height: 44px;
  line-height: 44px;
}

.mx-calendar-content .cell:not(.disabled):hover {
  border-color: $primary500;
}

.mx-calendar-content .cell.active {
  color: $white100;
  background-color: $primary500;
  border-color: $primary500;
}

.mx-calendar-content .cell.in-range {
  color: $black700;
  background-color: #dbedfb;
}

.mx-calendar-content .cell.disabled {
  cursor: not-allowed;
  color: $gray600;
  background-color: $gray200;
}

.mx-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}

.mx-table.mx-table-month,
.mx-table.mx-table-year {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
}

.mx-table th {
  padding: 0;
  font-weight: 400;
}

.mx-table td {
  padding: 0;
}

.mx-table-date td,
.mx-table-date th {
  height: 33px;
  line-height: 33px;
  font-size: 14px;
}

.mx-table-date .cell.not-current-month {
  color: $gray600;
}

.mx-time {
  flex: 1;
  width: 224px;
  background: $white100;
}

.mx-date-time {
  position: relative;
  width: 248px;
  height: 270px;
  .mx-time {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.mx-date-time-range {
  position: relative;
  width: 248px * 2;
  height: 270px;
  .mx-time-range {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.mx-scrollbar {
  height: 100%;
}

.mx-time-content {
  height: 224px;
  box-sizing: border-box;
  overflow: hidden;
}

.mx-time-columns {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.mx-time-column {
  flex: 1;
  position: relative;
  text-align: center;

  &:first-child {
    border-left: 0;
  }
  .mx-time-list {
    margin: 0;
    padding: 0;
    list-style: none;
    &::after {
      content: '';
      display: block;
      height: 32 * 6px;
    }
  }
  .mx-time-item {
    cursor: pointer;
    font: $sans-none-14;
    height: 38px;
    line-height: 38px;
    position: relative;
    z-index: 0;
    &:hover {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        border: 1px solid $primary500;
        width: 33px;
        height: 33px;
        border-radius: 4px;
      }
    }
    &.active {
      color: $white100;
      &::after {
        z-index: -1;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        background: $primary500;
        width: 33px;
        height: 33px;
        border-radius: 4px;
        border: none;
      }
    }
  }
}

.mx-scrollbar.mx-time-column {
  overflow-y: auto!important;
  &::-webkit-scrollbar{
    width: 6px;
  }
  &::-webkit-scrollbar-track{
    background-color: none;
  }
  &::-webkit-scrollbar-thumb{
    border-radius: 20px;
    background-color: $gray600;
  }
  .mx-scrollbar-wrap {
    width: 100%;
  }
  .mx-time-list::after{
    display: none;
  }
}

.mx-icon-clear {
  position: absolute;
  width: 16px;
  height: 16px;
  background: url(../../assets/images/icon-button-clear.svg) center no-repeat;
  background-size: cover;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 38px;
  cursor: pointer;
}
</style>