<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_13982_21697)">
      <path d="M7.99968 1.33337C4.31967 1.33337 1.33301 4.32004 1.33301 8.00004C1.33301 11.68 4.31967 14.6667 7.99968 14.6667C11.6797 14.6667 14.6663 11.68 14.6663 8.00004C14.6663 4.32004 11.6797 1.33337 7.99968 1.33337ZM7.99968 13.3334C5.05967 13.3334 2.66634 10.94 2.66634 8.00004C2.66634 5.06004 5.05967 2.66671 7.99968 2.66671C10.9397 2.66671 13.333 5.06004 13.333 8.00004C13.333 10.94 10.9397 13.3334 7.99968 13.3334ZM7.33301 10.6667H8.66634V12H7.33301V10.6667ZM8.40634 4.02671C7.03301 3.82671 5.81968 4.67337 5.45301 5.88671C5.33301 6.27337 5.62634 6.66671 6.03301 6.66671H6.16634C6.43967 6.66671 6.65968 6.47337 6.75301 6.22004C6.96634 5.62671 7.59968 5.22004 8.28634 5.36671C8.91968 5.50004 9.38634 6.12004 9.33301 6.76671C9.26634 7.66004 8.25301 7.85337 7.69968 8.68671C7.69968 8.69337 7.69301 8.69337 7.69301 8.70004C7.68634 8.71337 7.67968 8.72004 7.67301 8.73337C7.61301 8.83337 7.55301 8.94671 7.50634 9.06671C7.49968 9.08671 7.48634 9.10004 7.47968 9.12004C7.47301 9.13337 7.47301 9.14671 7.46634 9.16671C7.38634 9.39337 7.33301 9.66671 7.33301 10H8.66634C8.66634 9.72004 8.73968 9.48671 8.85301 9.28671C8.86634 9.26671 8.87301 9.24671 8.88634 9.22671C8.93968 9.13337 9.00634 9.04671 9.07301 8.96671C9.07968 8.96004 9.08634 8.94671 9.09301 8.94004C9.15968 8.86004 9.23301 8.78671 9.31301 8.71337C9.95301 8.10671 10.8197 7.61337 10.6397 6.34004C10.4797 5.18004 9.56634 4.20004 8.40634 4.02671Z" />
    </g>
    <defs>
      <clipPath id="clip0_13982_21697">
        <rect
          width="16"
          height="16"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>

export default {
  props: {
    size: {
      type: Number,
      default: 18,
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  fill: $black300;
}
</style>