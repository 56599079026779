<template>
  <button
    class="button-back"
    @click="onClick"
  >
    <IconBack />
  </button>
</template>

<script>
import IconBack from '@/components/icons/IconBack';

export default {
  components: {
    IconBack,
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-back {
  padding: 0;
  margin: 0;
}
</style>
