<template>
  <LayoutMain>
    <MessageBar
      v-if="errorMessages.length > 0"
      :messages="errorMessages"
      type="error"
    />
    <button
      class="button-back"
      @click="$router.push({ name: 'VDX500' })"
    >
      <IconArrowLeftLarge :size="14" />
      <div class="text">
        ユーザー一覧
      </div>
    </button>
    <div class="header">
      <h1 class="title">
        ユーザー編集
      </h1>
      <Button
        v-if="hasAuthDeleteUserAPI && user.is_deletable"
        size="inline"
        type="delete"
        @click="$refs.modalDelete?.openModal()"
      >
        ユーザーを削除
      </Button>
    </div>
    <div
      v-if="user.user_id"
      class="user"
    >
      <div class="block-row">
        <div class="label mt-0">
          {{ user.user_role_type_value }}{{ user.base_name && ` / ${user.base_name}` }}
        </div>
      </div>
      <div class="block-row">
        <div class="label">
          お名前
        </div>
        <FormBlock label="姓 (Family Name)">
          <FormInputText
            v-model="form.userFamilyName"
            size="width-180"
            :disabled="!hasAuthUpdateUserAPI"
          />
        </FormBlock>
        <FormBlock label="名 (First Name)">
          <FormInputText
            v-model="form.userFirstName"
            size="width-180"
            :disabled="!hasAuthUpdateUserAPI"
          />
        </FormBlock>
      </div>
      <div
        v-if="hasAuthSendUserPasswordResetAPI && canPasswordReset"
        class="block-password"
      >
        <div class="label">
          パスワード
        </div>
        <button
          class="button"
          data-test="password-reset"
          @click="$refs.modalResetConfirm?.openModal()"
        >
          パスワードの再設定
        </button>
      </div>
      <div class="block-row">
        <FormBlock
          label="電話番号"
          mini-label="（任意）"
          info-text="電話番号の代わりに表示名を表示させたい場合、電話番号の入力をお願いします。表示名の入力も必須になります。"
        >
          <FormInputText
            v-model="form.userTelNumber"
            size="width-400"
            :disabled="!hasAuthUpdateUserAPI"
          />
        </FormBlock>
        <FormBlock
          label="表示名"
          info-text="電話番号の代わりに表示させる表示名になります。識別性が向上します。"
        >
          <FormInputText
            v-model="form.userDisplayName"
            size="width-400"
            :disabled="!hasAuthUpdateUserAPI || !form.userTelNumber"
          />
        </FormBlock>
      </div>
      <FormBlock label="メールアドレス">
        <FormInputText
          v-model="user.user_email_address"
          size="width-400"
          :disabled="true"
        />
      </FormBlock>
      <div class="block-row">
        <FormBlock label="権限">
          <FormSelect
            v-model="form.userRoleType"
            size="width-180"
            :options="userRoleOptions"
            :disabled="!hasAuthUpdateUserAPI"
            @change="userRoleTypeChange"
          />
        </FormBlock>
      </div>
      <div class="block-row">
        <FormBlock label="グループ">
          <FormSelect
            v-model="form.baseId"
            size="width-400"
            :options="basesNameOptions"
            :disabled="baseIdFrag || !hasAuthUpdateUserAPI"
          />
        </FormBlock>
      </div>
      <div class="block-row">
        <FormBlock label="状態">
          <FormInputText
            v-model="user.user_status_value"
            size="width-180"
            :disabled="true"
          />
        </FormBlock>
      </div>
      <FormBlock
        v-if="isUseStt"
        label="キーワードメール通知"
        class="mt-md"
      >
        <FormRadio
          v-model="form.keywordNotification"
          :options="keywordNotificationOptions"
          name="keywordNotification"
          label-key="label"
          value-key="value"
          :disabled="keywordNotificationFrag || !hasAuthUpdateUserAPI"
        />
      </FormBlock>
      <FormBlock
        v-if="isUseStt"
        label="会話要約とToDoメール通知"
        class="mt-md"
      >
        <FormRadio
          v-model="form.aiGeneratedTextNotification"
          :options="aiGeneratedTextNotificationOptions"
          name="aiGeneratedTextNotification"
          label-key="label"
          value-key="value"
          :disabled="!hasAuthUpdateUserAPI"
        />
      </FormBlock>
      <ButtonBlock
        v-if="hasAuthUpdateUserAPI"
        layout="left"
        class="edit-button-block"
      >
        <Button
          size="inline"
          type="normal"
          @click="$router.push({ name: 'VDX500' })"
        >
          キャンセル
        </Button>
        <Button
          size="inline"
          type="primary"
          @click="onEditSave"
        >
          変更を保存
        </Button>
      </ButtonBlock>
    </div>

    <Modal
      ref="modalDelete"
      class="modal-user-delete"
      @close="deleteErrorMessages = []"
    >
      <MessageBar
        v-if="deleteErrorMessages.length > 0"
        :messages="deleteErrorMessages"
        type="error"
      />
      <div class="title">
        ユーザーを削除
      </div>
      <p class="text">
        ユーザー（{{ user.user_family_name }}&nbsp;{{ user.user_first_name }}）{{ user.user_email_address }}を削除しますか？
      </p>
      <ButtonBlock>
        <Button
          type="delete"
          @click="onDeleteUser"
        >
          削除する
        </Button>
      </ButtonBlock>
      <ButtonBlock>
        <Button
          type="normal"
          @click="$refs.modalDelete?.hideModal()"
        >
          キャンセル
        </Button>
      </ButtonBlock>
    </Modal>

    <Modal
      ref="modalResetConfirm"
      data-test="password-reset-modal"
      @close="resetErrorMessages = []"
    >
      <MessageBar
        v-if="resetErrorMessages.length > 0"
        :messages="resetErrorMessages"
        type="error"
      />
      <div class="title">
        パスワードの再設定
      </div>
      <p class="text">
        「送信する」を選択すると、ユーザーのアドレスに再設定用メールが送信されます
      </p>
      <ButtonBlock>
        <Button
          data-test="execute"
          @click="onResetPassword"
        >
          送信する
        </Button>
      </ButtonBlock>
      <ButtonBlock>
        <Button
          type="normal"
          @click="$refs.modalResetConfirm?.hideModal()"
        >
          キャンセル
        </Button>
      </ButtonBlock>
    </Modal>

    <Modal ref="modalResetComplete">
      <IconMail />
      <div class="title">
        メールを送信しました
      </div>
      <p class="text">
        {{ resultMessage }}
      </p>
      <ButtonBlock>
        <Button @click="$refs.modalResetComplete?.hideModal()">
          OK
        </Button>
      </ButtonBlock>
    </Modal>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonBlock from '@/components/button/ButtonBlock';
import MessageBar from '@/components/common/MessageBar';
import Modal from '@/components/common/Modal';
import FormBlock from '@/components/form/FormBlock';
import FormInputText from '@/components/form/FormInputText';
import FormRadio from '@/components/form/FormRadio';
import FormSelect from '@/components/form/FormSelect';
import IconArrowLeftLarge from '@/components/icons/IconArrowLeftLarge';
import IconMail from '@/components/icons/IconMail';
import LayoutMain from '@/components/layout/LayoutMain';
import { deleteUserApi, editUserApi, getBasesNameApi, getUserApi, getUserRoleAuthsApi, getUserRolesApi, sendResetPwMailApi } from '@/utils/ApiHelper';
import { hasAuthorization } from '@/utils/CommonUtil';
import { API_IDS, USER_ROLE_TYPE } from '@/utils/Constants';
import { mapActions } from 'vuex';
import { getIdToken } from '@/utils/IdTokenManager';

export default {
  components: {
    LayoutMain,
    Button,
    IconArrowLeftLarge,
    FormBlock,
    FormInputText,
    FormRadio,
    Modal,
    ButtonBlock,
    IconMail,
    MessageBar,
    FormSelect,
  },
  data() {
    return {

      /**
       * ユーザー情報
       */
      user: {},

      /**
       * 編集用フォーム
       */
      form: {
        userFamilyName: '',
        userFirstName: '',
        userTelNumber: '',
        userDisplayName: '',
        userRoleType: '',
        baseId: null,
        keywordNotification: false,
        aiGeneratedTextNotification: false,
      },

      /**
       * ユーザー編集画面に表示するエラーメッセージ
       */
      errorMessages: [],

      /**
       * パスワード再設定確認モーダルに表示するエラーメッセージ
       */
      resetErrorMessages: [],

      /**
       * パスワード再設定完了モーダルに表示するメッセージ
       */
      resultMessage: '',

      /**
       * ユーザー削除確認モーダルに表示するエラーメッセージ
       */
      deleteErrorMessages: [],

      /**
       * ロール一覧
       */
      userRoleOptions: [],

      /**
       * キーワードメール通知 ラジオボタンのオプション
       */
      keywordNotificationOptions: [
        {
          'label': '通知する',
          'value': true,
        },
        {
          'label': '通知しない',
          'value': false,
        },
      ],

      /**
       * 会話要約とToDoメール通知 ラジオボタンのオプション
       */
      aiGeneratedTextNotificationOptions: [
        {
          'label': '通知する',
          'value': true,
        },
        {
          'label': '通知しない',
          'value': false,
        },
      ],

      /**
       * グループ名一覧
       */
      basesNameOptions: [],

      /**
       * 権限一覧
       */
      authorizations: [],

      /**
       * パスワード再設定可否フラグ
       */
      canPasswordReset: false,
    };
  },
  computed: {
    /**
     * パスワード再設定メール送信APIの権限チェック
     *
     * 権限が無い場合
     * ・ユーザー編集のパスワード項目を非表示
     */
    hasAuthSendUserPasswordResetAPI() {
      return hasAuthorization(this.authorizations, API_IDS.SEND_USER_PASSWORD_RESET);
    },

    /**
     * ユーザ削除APIの権限チェック
     *
     * 権限が無い場合
     * ・ユーザーを削除ボタンを非表示
     */
    hasAuthDeleteUserAPI() {
      return hasAuthorization(this.authorizations, API_IDS.DELETE_USER);
    },

    /**
     * ユーザ更新APIの権限チェック
     *
     * 権限が無い場合
     * ・「お名前」と「権限」を非活性
     * ・「キャンセル」ボタンと「変更を保存」ボタンを非表示
     */
    hasAuthUpdateUserAPI() {
      return hasAuthorization(this.authorizations, API_IDS.UPDATE_USER);
    },

    /**
     * 権限セレクトボックスのフラグ
     *
     * 管理者または閲覧者の場合
     * ・グループセレクトボックスを非活性
     */
    baseIdFrag() {
      return this.form.userRoleType === USER_ROLE_TYPE.ADMINISTRATOR || this.form.userRoleType === USER_ROLE_TYPE.VIEWER ? true : false;
    },

    /**
     * 権限セレクトボックスのフラグ
     *
     * 管理者以外の場合
     * ・キーワードメール通知を非活性
     */
    keywordNotificationFrag() {
      return this.form.userRoleType !== USER_ROLE_TYPE.ADMINISTRATOR ? true : false;
    },

    /**
     * 字起こし契約の有無のチェック
     *
     * 字起こし契約なしの場合
     * ・会話要約とToDoメール通知を非表示
     */
    isUseStt() {
      return getIdToken('useStt') === 'true';
    },
  },
  watch: {
    /**
     * errorMessagesが更新されたら実行される
     */
    errorMessages() {
      // スクロールトップへ
      const domMain = document.querySelector('main');
      if (domMain) {
        domMain.scrollTop = 0;
      }
    },
    'form.userTelNumber' (n) {
      if(!n) {
        this.form.userDisplayName = '';
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions([
      'startLoading',
      'stopLoading',
      'setStoreMessages',
    ]),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 権限取得
      await this.getUserRoleAuths();
      // ユーザー詳細取得
      await this.fetchData();
      // ロール一覧取得
      await this.fetchUserRoles();
      // グループ名一覧取得
      await this.fetchBasesName();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.SEND_USER_PASSWORD_RESET,
          API_IDS.DELETE_USER,
          API_IDS.UPDATE_USER,
        ],
      });
      this.authorizations = data.data;
    },

    /**
     * ユーザー情報取得
     */
    async fetchData() {
      const { status, data } = await getUserApi({ id: this.$route.params.id });

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        return;
      }

      // TODO 改善
      this.user = data;
      this.form.userFamilyName = data.user_family_name;
      this.form.userFirstName = data.user_first_name;
      this.form.userTelNumber = data.tel_number;
      this.form.userDisplayName = data.display_name;
      this.form.userRoleType = data.user_role_type_key;
      this.form.baseId = data.base_id;
      this.form.keywordNotification = data.keyword_notification;
      this.form.aiGeneratedTextNotification = data.ai_generated_text_notification;
      this.canPasswordReset = data.can_password_reset;
    },

    /**
     * ロール一覧取得
     */
    async fetchUserRoles() {
      const { data } = await getUserRolesApi();
      this.userRoleOptions = data.data.map(item => (
        {
          id: item.user_role_type,
          label: item.user_role_name,
        }
      ));
    },

    /**
     * グループ名一覧取得
     */
    async fetchBasesName() {
      const { data } = await getBasesNameApi();
      this.basesNameOptions = data.data.map(item => (
        {
          id: item.base_id,
          label: item.base_name,
        }
      ));
      this.basesNameOptions.unshift({id: null, label: 'グループ所属無し'});
    },

    /**
     * 権限セレクトボックス選出時のイベント
     */
    async userRoleTypeChange() {
      // 権限が管理者または閲覧者の場合、グループを空値および「グループ所属無し」に設定
      if(this.baseIdFrag) this.form.baseId = null;
      // 権限が管理者以外の場合、キーワードメール通知を「通知しない」にする
      if(this.keywordNotificationFrag) this.form.keywordNotification = false;
    },

    /**
     * ユーザー削除確認モーダルの「削除する」ボタンのクリックイベント
     */
    async onDeleteUser() {
      this.startLoading();
      const { status, data } = await deleteUserApi({ id: this.$route.params.id });

      if (status == 400 || status == 422) {
        this.deleteErrorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX500' });
      this.stopLoading();
    },

    /**
     * パスワード再設定モーダルの「送信する」ボタンのクリックイベント
     */
    async onResetPassword() {
      this.startLoading();
      const { status, data } = await sendResetPwMailApi({ id: this.$route.params.id });

      if (status == 400 || status == 422) {
        this.resetErrorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      this.resultMessage = data.detail[0].msg;
      this.$refs.modalResetConfirm?.hideModal();
      this.$refs.modalResetComplete?.openModal();
      this.stopLoading();
    },

    /**
     * 「変更を保存」ボタンのクリックイベント
     */
    async onEditSave() {
      this.startLoading();

      // ユーザー編集APIを呼び出し
      const { status, data } = await editUserApi(
        { id: this.$route.params.id },
        {
          user_family_name: this.form.userFamilyName,
          user_first_name: this.form.userFirstName,
          user_role_type: this.form.userRoleType,
          base_id: this.form.baseId,
          keyword_notification: this.form.keywordNotification,
          ai_generated_text_notification: this.form.aiGeneratedTextNotification,
          tel_number: this.form.userTelNumber,
          display_name: this.form.userDisplayName, 
        },
      );

      if (status == 400 || status == 422) {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // 返却された成功メッセージをストアに保存する
      this.setStoreMessages({
        storeMessages: {
          type: 'success',
          messages: data.detail.map(item => item.msg),
        },
      });

      this.$router.push({ name: 'VDX500' });
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    padding: $spacing-xs;
    overflow-y: scroll;
    position: relative;
  }
}

.message-bar {
  margin-bottom: $spacing-xs;
}

.button-back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  height: 14px;
  width: 108px;

  > :deep(.icon-lineup) {
    > .icon {
      svg {
        vertical-align: top;
      }
    }
  }

  > .text {
    font: $sans-none-14;
    color: $black700;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: $spacing-xs;
  height: 42px;
  border-bottom: 2px solid $gray400;

  > .title {
    font: $sans-snug-20-bold;
    color: $black700;
  }
}

.form-block {
  margin-top: $spacing-sm;
  &.mt-md{
    margin-top: $spacing-md;
  }
}

.block-password {
  margin-top: $spacing-sm;

  > .label {
    font: $sans-none-16-bold;
    color: $black700;
  }

  > .button {
    margin-top: $spacing-xxxs;
    padding: 0;
    font: $sans-none-16;
    color: $primary500;
    text-decoration: underline;
  }
}

.block-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-sm;

  > .label {
    width: 100%;
    margin-bottom: $spacing-xxxxs;
    font: $sans-none-16-bold;
    color: $black700;
    &.mt-0{
      margin-bottom: 0;
    }
  }

  > .form-block {
    margin-top: 0;

    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}

.edit-button-block {
  margin-top: $spacing-md;
}
</style>
