<template>
  <div class="table-list-log">
    <p
      v-if="!records.length"
      class="no-records"
    >
      ログデータはありません
    </p>
    <div class="header">
      <div class="header-left" />
      <div class="header-right">
        <Button
          v-if="records.length > 0"
          type="normal"
          size="small"
          class="csv-download"
          @click="csvDownload"
        >
          CSVダウンロード
        </Button>
        <SortOrder
          v-if="records.length > 0"
          :is-asc="isAsc"
          data-test="sort-order"
          @sortAsc="sortAsc"
          @sortDesc="sortDesc"
        />
        <Pagination
          v-if="records.length > 0"
          :start-count="recordSectionFrom"
          :end-count="recordSectionTo"
          :total-count="totalCount"
          :has-prev="hasPrev"
          :has-next="hasNext"
          data-test="pagination"
          @prev="prevPage"
          @next="nextPage"
        />
      </div>
    </div>
    <div
      v-if="records.length > 0"
      class="body"
    >
      <ListLog
        ref="listLog"
        :items="records"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import SortOrder from '@/components/common/SortOrder';
import ListLog from '@/components/logtable/ListLog.vue';
import Button from '@/components/button/Button.vue';

export default {
  components: {
    SortOrder,
    Pagination,
    ListLog,
    Button,
  },
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    totalRecord: {
      type: Number,
      default: null,
    },
    recordSectionFrom: {
      type: Number,
      default: null,
    },
    recordSectionTo: {
      type: Number,
      default: null,
    },
    isAsc: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  emits: [
    'sortAsc',
    'sortDesc',
    'prevPage',
    'nextPage',
    'csvDownload',
  ],
  computed: {
    totalCount() {
      return this.totalRecord;
    },
    hasPrev() {
      return this.page > 1;
    },
    hasNext() {
      return this.totalRecord > this.recordSectionTo;
    },
  },
  methods: {
    sortAsc() {
      this.$emit('sortAsc');
    },
    sortDesc() {
      this.$emit('sortDesc');
    },
    prevPage() {
      this.$emit('prevPage');
    },
    nextPage() {
      this.$emit('nextPage');
    },
    csvDownload() {
      this.$emit('csvDownload');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-list-log {
  height: 100%;
  display: flex;
  flex-direction: column;
  > .header {
    padding: $spacing-xs 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-right {
      display: flex;
      .csv-download {
        min-width: 118px;
        height: 28px;
        margin-right: $spacing-xxxs;
        border-color: $gray600;
      }
      .pagination-block {
        margin-left: 30px;
      }
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  > .no-records {
    font: $sans-none-16;
    color: $black700;
    padding-top: $spacing-xs;
  }
}
</style>
