<template>
  <LayoutMain @refresh="init">
    <div class="body">
      <MessageBar
        v-if="flashMessages.messages.length > 0"
        :type="flashMessages.type"
        :messages="flashMessages.messages"
        class="mt-xs mr-xs ml-xs"
      />
      <PageHeading heading="ダウンロードデータ作成状況" />
      <MessageBar
        v-if="downloadList.items.length > 0"
        type="info"
        :messages="[
          'ダウンロードデータは' + downloadList.date_msg + '日を過ぎると自動的に削除されます。',
          '失敗ステータスのデータはダウンロードできません。再度、ダウンロードデータ作成からやり直してください。',
        ]"
        class="mt-sm mr-xs mb-sm ml-xs"
      />

      <ListDownload
        v-model:checkedIds="checkedIds"
        :items="downloadList.items"
        @download="downloadZip"
      />
      <ActionBarBottom
        v-if="checkedIds.length"
        type="delete"
        :text="checkedIds.length + '件のデータを選択'"
        :btn-text="'削除'"
        @delete="$refs.modalDownloadDataDelete.openModal()"
        @cancel="() => checkedIds = []"
      />
    </div>
  </LayoutMain>

  <ModalDownloadDataDelete
    ref="modalDownloadDataDelete"
    @delete="deleteDownloadData"
  />
</template>

<script>
import MessageBar from '@/components/common/MessageBar';
import PageHeading from '@/components/common/PageHeading';
import ListDownload from '@/components/download/ListDownload.vue';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import ModalDownloadDataDelete from '@/components/modal/ModalDownloadDataDelete';
import ActionBarBottom from '@/components/voice/ActionBarBottom';
import { deleteDownloadDataApi, getDownloadDataApi, getDownloadUrlApi, getUserRoleAuthsApi } from '@/utils/ApiHelper';
import { API_IDS } from '@/utils/Constants';
import { mapActions } from 'vuex';

export default {
  components: {
    LayoutMain,
    MessageBar,
    ListDownload,
    ActionBarBottom,
    ModalDownloadDataDelete,
    PageHeading,
  },
  data() {
    return {

      /**
       * ダウンロードデータ一覧
       */
      downloadList: {
        date_msg: '',
        items: [],
      },

      /**
       * ダウンロードデータ一覧画面に表示するフラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },

      /**
       * ダウンロードデータ選択チェック一覧
       */
      checkedIds: [],

      /**
       * 権限一覧
       */
      authorizations: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'setUserRoleAuths']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // 権限取得
      await this.getUserRoleAuths();
      // ダウンロードデータ一覧取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * 権限取得
     */
    async getUserRoleAuths() {
      const { data } = await getUserRoleAuthsApi({
        api_ids: [
          API_IDS.GET_DOWNLOAD_DATA,
        ],
      });
      this.authorizations = data.data;

      // 権限をストアに保存
      this.setUserRoleAuths({
        userRoleAuths: this.authorizations,
      });
    },

    /**
     * ダウンロードデータ一覧取得
     */
    async fetchData() {
      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      // チェックボックスクリア
      this.checkedIds = [];

      const { data } = await getDownloadDataApi();

      // ダウンロードデータ一覧クリア
      this.downloadList.date_msg = '';
      this.downloadList.items = [];

      // ダウンロードデータ一覧を設定
      this.downloadList.date_msg = data.date_msg;
      this.downloadList.items = data.data;
    },

    /**
     * 選択されたダウンロードデータを削除する
     */
    async deleteDownloadData() {
      this.startLoading();

      // フラッシュメッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // ダウンロードデータ削除APIを呼び出す
      const { status, data } = await deleteDownloadDataApi({ download_ids: this.checkedIds });

      // 業務エラーまたはバリデーションエラーの場合はエラーメッセージを設定して処理終了
      if (status == 400 || status == 422) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        // 削除確認モーダルを閉じる
        this.$refs.modalDownloadDataDelete.hideModal();
        this.stopLoading();
        return;
      }

      // ダウンロードデータ一覧を再取得
      await this.fetchData();

      // 成功メッセージを表示する
      this.flashMessages.type = 'success';
      this.flashMessages.messages = data.detail.map(item => item.msg);
      // 削除確認モーダルを閉じる
      this.$refs.modalDownloadDataDelete.hideModal();

      this.stopLoading();
    },

    /**
     * ZIPダウンロード
     * @param {*} id ダウンロードデータID
     */
    async downloadZip(id) {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];

      // ZIPダウンロードURL取得APIの呼び出し
      const { status, data } = await getDownloadUrlApi(
        {
          download_id: id,
        },
      );

      if (status === 400 || status === 422) {
        this.flashMessages.type = 'error';
        this.flashMessages.messages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      let link = document.createElement('a');
      link.href = data.download_url;
      link.click();
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  display: flex;
  flex-direction: column;
  height: 100%;

  > .page-heading {
    margin: $spacing-xs $spacing-xs 0;
  }
}
</style>
