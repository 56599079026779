<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10218_15329)">
      <path
        d="M10.2596 9.02847H9.61118L9.38138 8.80687C10.3662 7.65786 10.8751 6.09028 10.596 4.42421C10.2103 2.1426 8.30623 0.320595 6.00821 0.0415496C2.53655 -0.385226 -0.385226 2.53655 0.0415496 6.00821C0.320595 8.30623 2.1426 10.2103 4.42421 10.596C6.09028 10.8751 7.65786 10.3662 8.80687 9.38138L9.02847 9.61118V10.2596L12.5165 13.7476C12.853 14.0841 13.4029 14.0841 13.7394 13.7476C14.0759 13.4111 14.0759 12.8612 13.7394 12.5247L10.2596 9.02847ZM5.33521 9.02847C3.29161 9.02847 1.64196 7.37881 1.64196 5.33521C1.64196 3.29161 3.29161 1.64196 5.33521 1.64196C7.37881 1.64196 9.02847 3.29161 9.02847 5.33521C9.02847 7.37881 7.37881 9.02847 5.33521 9.02847Z"
        fill="#202124"
      />
    </g>
    <defs>
      <clipPath id="clip0_10218_15329">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
