<template>
  <IconBase :size="size">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7252 10.3182H10.9842L10.7216 10.065C11.8471 8.75184 12.4287 6.96032 12.1098 5.05624C11.6689 2.44869 9.49284 0.366395 6.86652 0.0474853C2.89891 -0.440259 -0.440259 2.89891 0.0474853 6.86652C0.366395 9.49284 2.44869 11.6689 5.05624 12.1098C6.96032 12.4287 8.75184 11.8471 10.065 10.7216L10.3182 10.9842V11.7252L14.3046 15.7116C14.6892 16.0961 15.3176 16.0961 15.7022 15.7116C16.0868 15.327 16.0868 14.6986 15.7022 14.314L11.7252 10.3182ZM6.09739 10.3182C3.76184 10.3182 1.87653 8.43293 1.87653 6.09739C1.87653 3.76184 3.76184 1.87653 6.09739 1.87653C8.43293 1.87653 10.3182 3.76184 10.3182 6.09739C10.3182 8.43293 8.43293 10.3182 6.09739 10.3182Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';
export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
