<template>
  <button
    class="button-cancel"
    @click="onClick"
  >
    <IconCancel class="icon-cancel" />キャンセル
  </button>
</template>

<script>
import IconCancel from '@/components/icons/IconCancel';

export default {
  components: {
    IconCancel,
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-cancel {
  // width: 84px;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0px $spacing-xxxxs;
  font: $sans-none-12;
  color: $black400;
  border-radius: 12px;
  &:hover {
    background-color: $black100;
  }
  .icon-cancel {
    height: 12px;
    width: 12px;
    margin-right: 6px;
    // margin-right: $spacing-xxxxs;
  }
}
</style>
