<template>
  <div class="list-notice-keyword">
    <div
      v-if="totalRecord > 0"
      class="actions mb-xxxs"
    >
      <div class="actions-right">
        <SortOrder
          :is-asc="isAsc"
          class="mr-md"
          @sortAsc="$emit('sortAsc')"
          @sortDesc="$emit('sortDesc')"
        />
        <Pagination
          :start-count="recordSectionFrom"
          :end-count="recordSectionTo"
          :total-count="totalRecord"
          :has-prev="hasPrev"
          :has-next="hasNext"
          @prev="$emit('prevPage')"
          @next="$emit('nextPage')"
        />
      </div>
    </div>
    <template v-if="items.length > 0">
      <div class="header">
        <div class="header-item calling-number">
          発信
        </div>
        <div class="header-item incoming-number">
          着信
        </div>
        <div class="header-item keyword">
          キーワード
        </div>
        <div class="header-item text">
          通話内容
        </div>
        <div class="header-item call-start-datetime">
          通話開始日時
        </div>
      </div>
      <ul
        id="scrollableBody"
        ref="scrollable"
        class="body"
      >
        <ListItemNoticeKeyword
          v-for="item of items"
          :id="item.voice_file_summary_id"
          :key="item.voice_file_summary_id"
          :calling-number="item.calling_number"
          :incoming-number="item.incoming_number"
          :calling-name="item.calling_name"
          :incoming-name="item.incoming_name"
          :keyword="item.keyword"
          :is-partition="item.is_partition"
          :text="item.text"
          :status="item.status"
          :call-start-datetime="item.call_start_datetime"
          @click="(id) => ($emit('toDetailPage', id))"
        />
      </ul>
    </template>
    <template v-else>
      <p class="message">
        キーワード通知はありません
      </p>
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/common/Pagination';
import SortOrder from '@/components/common/SortOrder';
import ListItemNoticeKeyword from '@/components/notice/ListItemNoticeKeyword';

export default {
  components: {
    ListItemNoticeKeyword,
    SortOrder,
    Pagination,
  },
  props: {
    totalRecord: {
      type: Number,
      default: 0,
    },
    recordSectionFrom: {
      type: Number,
      default: 0,
    },
    recordSectionTo: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isAsc: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  emits: ['sortAsc', 'sortDesc', 'prevPage', 'nextPage', 'toDetailPage'],
  computed: {
    hasPrev() {
      return this.page > 1;
    },
    hasNext() {
      return this.totalRecord > this.recordSectionTo;
    },
  },
  watch: {
    'items'() {
      // itemsの内容が変わるたびにスクロールトップへ
      const scrollableBody = document.getElementById('scrollableBody');
      if (scrollableBody) document.getElementById('scrollableBody').scrollTop = 0;
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.list-notice-keyword {
  > .actions {
    height: 28px;
    display: flex;
    justify-content: flex-end;

    > .actions-right {
      display: flex;
      align-items: center;
    }
  }

  .header {
    display: flex;
    background-color: $white100;
    border-bottom: 2px solid $gray400;
    overflow-y: scroll;
    min-height: 29px;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb{
      background: none;
    }
    > .header-item  {
      color: $black400;
      font: $sans-none-12-bold;
      height: 27px;
      line-height: 27px;
    }

    > .calling-number {
      width: 160px;
    }
    > .incoming-number {
      width: 160px;
    }
    > .keyword {
      width: calc((100% - 160px - 160px - 150px) * 0.34);
    }
    > .text {
      width: calc((100% - 160px - 160px - 150px) * 0.66);
    }
    > .call-start-datetime {
      width: 150px;
    }
  }

  .body {
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width: 6px;
    }
    &::-webkit-scrollbar-track{
      background-color: none;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 20px;
      background-color: $gray600;
    }
  }

  .message {
    font: $sans-none-16;
    color: $black700;
  }
}
</style>
