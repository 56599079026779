<template>
  <button
    class="button"
    :class="[
      type,
      size,
      {
        'is-disabled': disabled,
      },
    ]"
    :disabled="disabled"
    @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius6;
  width: 100%;
  height: $button-height;
  font: $sans-none-16;
  min-width: 180px;
  max-width: 180px;
  padding: 0;

  &.small {
    font: $sans-none-12;
    min-width: 110px;
    max-width: 110px;
    height: $button-height-small;
  }

  &.inline {
    font: $sans-none-14;
    padding: 0 $spacing-xxs;
    min-width: initial;
    max-width: initial;
    width: auto;
    height: $button-height-inline;
  }

  &.primary {
    background-color: $primary500;
    border: 1px solid $primary500;
    color: $white100;

    &:hover {
      background-color: $primary600;
      border-color: $primary600;
    }

    &.is-disabled {
      background-color: $gray500;
      border-color: $gray500;
      color: $black400;
    }
  }

  &.normal {
    background-color: $white100;
    border: 1px solid $black700;
    color: $black700;

    &:hover {
      background-color: $gray100;
    }

    &.is-disabled {
      background-color: $gray500;
      border-color: $gray500;
      color: $black400;
    }
  }

  &.delete {
    background-color: $white100;
    border: 1px solid $red300;
    color: $red300;

    &:hover {
      background-color: $red100;
    }

    &.is-disabled {
      background-color: $white100;
      border-color: $black400;
      color: $black400;
    }
  }

  &.text {
    background-color: $white100;
    border: 1px solid $white100;
    color: $black700;

    &:hover {
      background-color: $gray100;
      border: 1px solid $gray100;
    }
  }
}
</style>
