<template>
  <li class="listitem-user">
    <a
      class="link"
      @click="onClick"
    >
      <div class="name">
        {{ userFamilyName }}&nbsp;{{ userFirstName }}
      </div>
      <div class="address">
        {{ userEmailAddress }}
      </div>
      <div
        class="status"
        :class="['status-' + isUserStatus]"
      >
        {{ userStatusValue }}
      </div>
      <div class="authority">
        {{ userRoleTypeValue }}
      </div>
      <div class="date">
        {{ createDatetime }}
      </div>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    userId: {
      type: [String, Number],
      default: '',
    },
    userFamilyName: {
      type: String,
      default: '',
    },
    userFirstName: {
      type: String,
      default: '',
    },
    userEmailAddress: {
      type: String,
      default: '',
    },
    userStatusKey: {
      type: String,
      default: '',
      validator: (value) => {
        return ['in_use', 'stop_use', 'lock', 'activate'].includes(value);
      },
    },
    userStatusValue: {
      type: String,
      default: '',
    },
    userRoleTypeValue: {
      type: String,
      default: '',
    },
    createDatetime: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    isUserStatus() {
      let userStatus = '';
      if (this.userStatusKey === 'in_use') {
        userStatus = 'use';
      } else if (this.userStatusKey === 'lock') {
        userStatus = 'lock';
      } else if (this.userStatusKey === 'activate') {
        userStatus = 'activate';
      } else if (this.userStatusKey === 'stop_use') {
        userStatus = 'stop';
      }
      return userStatus;
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.userId);
    },
  },
};
</script>

<style lang="scss" scoped>
.listitem-user {
  border-bottom: 1px solid $gray400;
  background-color: $white100;

  .link {
    display: flex;
    text-decoration: none;
    cursor: pointer;

    > .name,
    .address,
    .status,
    .authority,
    .date {
      font: $sans-none-14;
      color: $black700;
      height: 40px;
      line-height: 40px;
    }

    > .name,
    .address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > .name {
      flex-basis: 21.25%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .address {
      flex-basis: 30.67%;
      flex-grow: 0;
      flex-shrink: 0;
    }

    > .status,
    .authority {
      flex-basis: 18.35%;
      flex-grow: 0;
      flex-shrink: 0;

      &-use {
        color: $green500;
      }

      &-lock {
        color: $red300;
      }

      &-activate {
        color: $black400;
      }

      &-stop {
        color: $gray600;
      }
    }

    > .date {
      flex-basis: 11.35%;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: right;
      padding-right: $spacing-xxs;
    }
  }
}
</style>
