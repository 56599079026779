<template>
  <LayoutMain @refresh="init">
    <div class="body">
      <MessageBar
        v-if="flashMessages.messages.length > 0"
        :messages="flashMessages.messages"
        :type="flashMessages.type"
        class="messages"
      />
      <MessageBar
        v-if="errorMessages.length > 0"
        :messages="errorMessages"
        type="error"
        class="messages"
      />
      <MessageBar
        v-if="successMessages.length > 0"
        :messages="successMessages"
        type="success"
        class="messages"
      />
      <div class="header">
        <h1 class="title">
          グループ一覧
        </h1>
        <Button
          size="inline"
          @click="onInputFileSelect"
        >
          CSVインポート
          <input
            id="file"
            name="file"
            type="file"
            hidden
            accept=".csv"
            @change="onCSVImport"
          >
        </Button>
        <ButtonMore
          button-text="CSVダウンロード"
          @click="onCSVDownload"
        />
      </div>

      <ListBase
        :items="baseList"
        @click="(id) => $router.push({ name: 'VDX410', params: { id } })"
      />
    </div>
  </LayoutMain>
</template>

<script>
import Button from '@/components/button/Button';
import ButtonMore from '@/components/button/ButtonMore';
import MessageBar from '@/components/common/MessageBar';
import LayoutMain from '@/components/layout/LayoutMain.vue';
import ListBase from '@/components/base/ListBase';
import { getBasesApi, getCSVDownloadApi , postCSVImportApi } from '@/utils/ApiHelper';
import { saveAs } from 'file-saver';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Button,
    ButtonMore,
    MessageBar,
    LayoutMain,
    ListBase,
  },
  data() {
    return {

      /**
       * グループ一覧
       */
      baseList: [],

      /**
       * グループ一覧画面に表示するフラッシュメッセージ
       */
      flashMessages: {
        type: '',
        messages: [],
      },

      /**
       * 記録詳細画面に表示する成功メッセージ
       *
       * 文字起こしテキスト編集APIの成功メッセージで使用
       */
      successMessages: [],

      /**
       * 記録詳細画面に表示するエラーメッセージ
       */
      errorMessages: [],
    };
  },
  computed: {
    ...mapGetters(['storeMessages']),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'clearStoreMessages']),

    /**
     * 初期表示処理
     */
    async init() {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.errorMessages = [];
      this.successMessages = [];

      // グループ一覧取得
      await this.fetchData();

      // グループ編集画面で格納したストアメッセージがあれば表示する
      this.flashMessages = this.storeMessages;
      this.clearStoreMessages();

      this.stopLoading();
    },

    /**
     * グループ一覧取得
     */
    async fetchData() {
      const { data } = await getBasesApi();
      this.baseList = data.data;
    },

    /**
     * 「CSVインポート」ボタンのクリックイベント
     */
    onInputFileSelect() {
      document.getElementById('file').click();
    },
    async onCSVImport(e) {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.errorMessages = [];
      this.successMessages = [];
      
      // CSVインポートAPIの呼び出し
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      const { status, data } = await postCSVImportApi(formData);

      // input['file']の初期化
      document.getElementById('file').value = '';

      if (status === 200) {
        this.successMessages = data.detail.map(item => item.msg);
      } else if (status === 400 || status === 422)  {
        this.errorMessages = data.detail.map(item => item.msg);
        this.stopLoading();
        return;
      }

      // グループ一覧取得
      await this.fetchData();

      this.stopLoading();
    },

    /**
     * 「CSVダウンロード」ボタンのクリックイベント
     */
    async onCSVDownload() {
      this.startLoading();

      // メッセージクリア
      this.flashMessages.type = '';
      this.flashMessages.messages = [];
      this.errorMessages = [];
      this.successMessages = [];

      // CSVダウンロードAPIの呼び出し
      const { data, headers } = await getCSVDownloadApi();

      const content = headers['content-disposition'];
      const regex = content.match(/filename=(.+)/);
      const filename = regex[1];
      saveAs(data, filename);
      this.stopLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-main {
  :deep(.main) {
    display: flex;
    flex-direction: column;
  }

  .messages {
    margin-bottom: 0;
  }
}

.body {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-xs $spacing-xs 0;

  > .message-bar {
    margin-bottom: $spacing-xxs;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 36px;
    > .title {
      font: $sans-snug-20-bold;
      height: 32px;
      color: $black700;
    }
    > .button{
      margin-left: auto;
    }
    > .button-more-area{
      margin-left: 16px;
    }
  }

  > .list-base {
    margin-top: $spacing-sm;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
