<template>
  <IconBase :size="size">
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0682 0H3.06818C2.06818 0 1.25 0.818182 1.25 1.81818V13.6364C1.25 14.1364 1.65909 14.5455 2.15909 14.5455C2.65909 14.5455 3.06818 14.1364 3.06818 13.6364V2.72727C3.06818 2.22727 3.47727 1.81818 3.97727 1.81818H13.0682C13.5682 1.81818 13.9773 1.40909 13.9773 0.909091C13.9773 0.409091 13.5682 0 13.0682 0ZM13.6045 4.17273L17.9955 8.56364C18.3318 8.9 18.5227 9.36364 18.5227 9.84546V18.1818C18.5227 19.1818 17.7045 20 16.7045 20H6.69545C5.69545 20 4.88636 19.1818 4.88636 18.1818L4.89545 5.45455C4.89545 4.45455 5.70455 3.63636 6.70455 3.63636H12.3136C12.7955 3.63636 13.2591 3.82727 13.6045 4.17273ZM13.0682 10H17.1591L12.1591 5V9.09091C12.1591 9.59091 12.5682 10 13.0682 10Z"
        fill="#7E7E84"
      />
    </svg>
  </IconBase>
</template>

<script>
import IconBase from '@/components/icons/IconBase';

export default {
  components: {
    IconBase,
  },
  props: {
    size: {
      type: Number,
      default: null,
    },
  },
};
</script>
